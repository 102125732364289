import { useDispatch, useSelector } from "react-redux";
import { setSelectedNotesTabAction } from "../../../store/user/actions";
import { NotesTabs } from "../../../helpers/constants";
import { ReactComponent as GeneralIcon } from "../../../assets/icons/notes-general.svg";
import { ReactComponent as TemplatesIcon } from "../../../assets/icons/notes-templates.svg";
// import { ReactComponent as FormsIcon } from "../../../assets/icons/notes-forms.svg";
// import { ReactComponent as DotsIcon } from "../../../assets/icons/notes-dot-phrases.svg";

const iconProps = {
  width: "24",
  height: "24",
  className: "flex-none",
};

const NotesSectionTabs = () => {
  const {
    GENERAL,
    TEMPLATES,
    // FORMS, DOT_PHRASES
  } = NotesTabs;
  const { selectedNotesTab } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const getTab = (tab, icon) => {
    const isActive = selectedNotesTab === tab;

    return (
      <button
        type="button"
        onClick={() => dispatch(setSelectedNotesTabAction(tab))}
        className={`flex items-center py-3 font-medium text-base
          ${isActive ? "text-primary" : "text-tertiary"}`}
      >
        <div
          className={`hidden mr-3 xl:flex flex-none w-2.5 h-2.5 rounded-full border-2
            ${isActive ? "bg-primary-blue border-white" : "bg-transparent border-transparent"}`}
        />
        {icon}
        <p className="ml-3 whitespace-nowrap">{tab}</p>
      </button>
    );
  };

  return (
    <div className="relative mt-3 px-4 xl:px-0">
      <div
        className="flex justify-around space-x-4 xl:space-x-0 xl:flex-col xl:py-4 px-3 h-fit w-full
          rounded-lg bg-white border border-gray-foreground overflow-x-auto scrollbar"
      >
        {getTab(
          GENERAL,
          <GeneralIcon
            stroke={selectedNotesTab === GENERAL ? "#2970FF" : "#667085"}
            {...iconProps}
          />,
        )}
        {getTab(
          TEMPLATES,
          <TemplatesIcon
            stroke={selectedNotesTab === TEMPLATES ? "#2970FF" : "#667085"}
            {...iconProps}
          />,
        )}
        {/*{getTab(*/}
        {/*  FORMS,*/}
        {/*  <FormsIcon*/}
        {/*    stroke={selectedNotesTab === FORMS ? "#2970FF" : "#667085"}*/}
        {/*    {...iconProps}*/}
        {/*  />,*/}
        {/*)}*/}
        {/*{getTab(*/}
        {/*  DOT_PHRASES,*/}
        {/*  <DotsIcon*/}
        {/*    stroke={selectedNotesTab === DOT_PHRASES ? "#2970FF" : "#667085"}*/}
        {/*    {...iconProps}*/}
        {/*  />,*/}
        {/*)}*/}
      </div>
    </div>
  );
};

export default NotesSectionTabs;
