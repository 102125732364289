import "./RecordingIndicator.css";

const RecordingIndicator = ({
  isRecording,
  innerColor = "#FF1744",
  outerColor = "#FF1744",
  size = 30,
  className = "",
}) => {
  const outerCircleClass = isRecording ? "animate-pulses" : "";
  const fillColor = isRecording ? innerColor : "grey";
  const strokeColor = isRecording ? outerColor : "grey";
  const strokeRadius = isRecording ? 4 : 5;

  return (
    <div className={className}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="8" cy="8" r="3" fill={fillColor} />
        <circle
          cx="8"
          cy="8"
          r={strokeRadius}
          fill={strokeColor}
          opacity="0.1"
          className={outerCircleClass}
        />
      </svg>
    </div>
  );
};

export default RecordingIndicator;
