import {
  SET_CALL_EVENT,
  SET_DEVICE_STATUS,
  SET_OPEN_CALL_MODAL,
  SET_OPEN_CALL_OPTIONS_MODAL,
  SET_SCRIBE_CALLS,
  SET_TWILIO_TOKEN,
} from "../types";

export function setTwilioTokenAction(twilioToken) {
  return {
    type: SET_TWILIO_TOKEN,
    payload: {
      twilioToken: twilioToken,
    },
  };
}

export function setDeviceStatusAction(deviceStatus) {
  return {
    type: SET_DEVICE_STATUS,
    payload: {
      deviceStatus: deviceStatus,
    },
  };
}

export function setOpenCallOptionsModalAction(openCallOptionsModal) {
  return {
    type: SET_OPEN_CALL_OPTIONS_MODAL,
    payload: {
      openCallOptionsModal: openCallOptionsModal,
    },
  };
}

export function setOpenCallModalAction(openCallModal) {
  return {
    type: SET_OPEN_CALL_MODAL,
    payload: {
      openCallModal: openCallModal,
    },
  };
}

export function setCallEventAction(callEvent) {
  return {
    type: SET_CALL_EVENT,
    payload: {
      callEvent: callEvent,
    },
  };
}

export function setScribeCallsAction(scribeCalls) {
  return {
    type: SET_SCRIBE_CALLS,
    payload: {
      scribeCalls: scribeCalls,
    },
  };
}
