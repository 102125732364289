import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as ArrowRight } from "../../../../assets/icons/arrow-right.svg";
import { store } from "../../../../store";
import { completeSignUp } from "../../../../store/auth/thunks";
import Button from "../../../Basic/Button";
import Checkbox from "../../../Basic/Checkbox";

const checkboxes = [
  {
    id: "isProffesionalHealthProvider",
    label: "I’m a professional health provider",
  },
  {
    id: "agreeToTosAndBaa",
    label: (
      <div>
        I agree to the&nbsp;
        <Link
          to="https://quadrant.health/terms-of-use"
          target="_blank"
          className="text-primary-blue hover:text-primary-blue-hover font-semibold"
        >
          Terms of service
        </Link>
        &nbsp;and&nbsp;
        <Link
          to="https://docsend.com/view/hcq47mcgnf4fa7fs"
          target="_blank"
          className="text-primary-blue hover:text-primary-blue-hover font-semibold"
        >
          BAA
        </Link>
      </div>
    ),
  },
];

const SecondStepForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    control,
    formState: { isSubmitted },
    handleSubmit,
  } = useForm({
    defaultValues: {
      isProffesionalHealthProvider: false,
      agreeToTosAndBaa: false,
    },
    mode: "onChange",
  });

  const onSubmit = () => {
    const { signUp, onboarding } = store.getState().auth;

    dispatch(
      completeSignUp(
        onboarding.practiceInfo.specialty,
        onboarding.practiceInfo.ehr,
        signUp.registeredEmailAddress,
        signUp.verificationCode,
        navigate,
      ),
    );
  };

  return (
    <form
      className="bg-white py-8 md:py-10 px-6 md:px-10 rounded-[16px] shadow-[0px_1px_3px_0px_#0000000D]"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="font-semibold text-xl text-tertiary mb-5 md:mb-8 text-center">
        Data & Privacy
      </div>
      <div className="font-medium text-lg mb-4 md:mb-10">
        We take privacy very seriously and are HIPAA compliant. Read more about
        how we protect data on our&nbsp;
        <Link
          to="https://quadrant.health/privacy-notice"
          target="_blank"
          className="underline underline-offset-4 hover:opacity-80"
        >
          privacy policy
        </Link>
      </div>
      <div className="flex flex-col gap-4">
        {checkboxes.map(({ id, label }) => (
          <Controller
            key={id}
            control={control}
            name={id}
            render={({ field: { value, onChange, onBlur, ref } }) => {
              const isError = typeof value === "boolean" && !value;

              return (
                <Checkbox
                  onBlur={onBlur}
                  onChange={onChange}
                  ref={ref}
                  checked={value}
                  name={id}
                  className="text-tertiary last-of-type:mb-16 md:last-of-type:mb-10"
                  checkboxClassName="min-w-[20px] min-h-[20px]"
                  variant="primary-filled"
                  label={label}
                  labelClassName="ml-[14px]"
                  error={isSubmitted && isError}
                  defaultChecked={value}
                  required
                />
              );
            }}
            rules={{ required: true }}
          />
        ))}
      </div>
      <Button
        type="submit"
        className="flex gap-2 items-center w-full text-base font-semibold mt-[76px]
          justify-center transition-all duration-300 hover:bg-primary-blue-hover"
      >
        Start Your Encounter <ArrowRight />
      </Button>
    </form>
  );
};

export default SecondStepForm;
