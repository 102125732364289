import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import { setIsModalOpen } from "../../store/ui/actions";

const Modal = ({
  onClickAway = () => {},
  onEnterPressed = () => {},
  children,
  className = "p-6 rounded-2xl",
  bg = "white",
  ...props
}: {
  onClickAway?: () => void;
  onEnterPressed?: () => void;
  children: React.ReactNode;
  className?: string;
  bg?: string;
}) => {
  const { isModalOpen } = useAppSelector((state) => state.ui);
  const dispatch = useAppDispatch();
  const modalRef = useRef(null);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    if (isModalOpen) {
      return;
    }

    dispatch(setIsModalOpen(true));

    if (modalRef.current) {
      modalRef.current.focus();
    }

    return () => {
      document.body.style.overflow = "unset";
      dispatch(setIsModalOpen(false));
    };
  }, []);

  return createPortal(
    <div
      ref={modalRef}
      tabIndex={-1}
      onKeyUp={(e) => {
        if (e.key === "Escape") {
          return onClickAway();
        }

        if (e.key === "Enter") {
          return onEnterPressed();
        }
      }}
      className="fixed h-screen-dynamic w-screen inset-0 bg-black/50 flex items-center justify-center z-50 cursor-default"
      {...props}
    >
      <div
        className={`bg-${bg} max-w-[90vw] max-h-90vh-dynamic flex overflow-hidden ${className}`}
      >
        {children}
      </div>
    </div>,
    document.querySelector("#wrapper"),
  );
};

export default Modal;
