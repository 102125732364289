import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { debounce } from "@mui/material/utils";
import Skeleton from "@mui/material/Skeleton";
import {
  setCurrPageAction,
  setSearchAction,
  setSelectedDoctorPatientsAction,
} from "../../store/patient/actions";
import { getNumOfPatients, searchPatients } from "../../store/patient/thunks";
import { selectInputText } from "../../helpers/helpers";
import Button from "../Basic/Button";
import DoctorButton from "../Basic/DoctorButton";
import ActionsButton from "../Basic/ActionsButton";
import VerticalDivider from "../Basic/VerticalDivider";
import FilterButton from "./FilterButton";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as ImportIcon } from "../../assets/icons/arrow-up-tray.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus-small.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ReactComponent as ArrowIcon } from "../../assets/icons/chevron-down.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/trash-redesign.svg";
import { ReactComponent as PatientIcon } from "../../assets/icons/patients-redesign.svg";
import { ReactComponent as DotsIcon } from "../../assets/icons/ellipsis-horizontal.svg";

const TableHeader = ({
  setCancelSelection,
  onImportPatients,
  onAddPatient,
  onDelete,
}) => {
  const {
    numOfPatients,
    numOfFilteredPatients,
    searchValue,
    rowsPerPage,
    currPage,
    selectedDoctorPatients,
    numOfPatientsByDoctorId,
    selectedPatients,
    selectedPatientCampaigns,
    selectedPatientStatuses,
    selectedPatientInsurances,
    loader,
  } = useSelector((state) => state.patient);
  const { doctorOptions } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isHeightMd = useMediaQuery({ minHeight: 768 });

  const onSelectDoctor = (doctor) => {
    dispatch(setSelectedDoctorPatientsAction(doctor));

    dispatch(
      searchPatients(
        searchValue,
        1,
        rowsPerPage * currPage,
        doctor,
        false,
        () => {},
        selectedPatientCampaigns,
        selectedPatientStatuses,
        selectedPatientInsurances,
      ),
    );
    dispatch(getNumOfPatients(doctor));
  };

  const handleSearchInput = (e) => {
    dispatch(setCurrPageAction(1));
    dispatch(setSearchAction(e.target.value));
    debouncedSearch(
      e.target.value,
      selectedDoctorPatients,
      selectedPatientCampaigns,
      selectedPatientStatuses,
      selectedPatientInsurances,
    );
  };

  const debouncedSearch = useMemo(
    () =>
      debounce(
        (
          searchValue,
          selectedDoctorPatients,
          selectedPatientCampaigns,
          selectedPatientStatuses,
          selectedPatientInsurances,
        ) => {
          dispatch(
            searchPatients(
              searchValue,
              1,
              rowsPerPage * currPage,
              selectedDoctorPatients,
              false,
              () => {},
              selectedPatientCampaigns,
              selectedPatientStatuses,
              selectedPatientInsurances,
            ),
          );
        },
        300,
      ),
    [dispatch, currPage, rowsPerPage],
  );

  const handleDelete = () => {
    onDelete();
  };

  return (
    <div
      className={`flex flex-col md:flex-row md:items-center justify-between space-y-3 md:space-y-0 md:space-x-3
        ${isDesktop && isHeightMd ? "pb-5" : "pb-4 md:pb-2"}`}
    >
      <div className="flex flex-col md:flex-row md:items-center gap-3 md:h-10">
        <div className="flex items-center justify-between md:justify-items-start space-x-3 md:h-10">
          <DoctorButton
            doctorOptions={doctorOptions.filter(
              (doctor) => !!doctor.show_patients,
            )}
            selectedDoctor={selectedDoctorPatients}
            onSelectDoctor={onSelectDoctor}
            className="md:max-w-[30vw]"
            getCountComponent={(doctorId) => (
              <span>
                {doctorId
                  ? numOfPatientsByDoctorId[doctorId] || 0
                  : numOfPatientsByDoctorId.all}
              </span>
            )}
            listClassName="whitespace-nowrap min-w-fit md:w-64"
            unassignedOption
            isPatients
            setCancelSelection={setCancelSelection}
          />

          {isDesktop && (loader || numOfPatients > 0) && (
            <VerticalDivider className="border-gray-foreground mx-3 h-6" />
          )}
          {loader ? (
            <Skeleton
              animation="wave"
              variant="rounded"
              width={45}
              height={23}
              sx={{ bgcolor: "#EAEDF3", borderRadius: "23px" }}
            />
          ) : numOfPatients > 0 ? (
            <div
              className="text-xs font-semibold text-tertiary px-2 py-1 bg-slate-100 rounded-full
              flex items-center space-x-1 border border-gray-foreground"
            >
              <PatientIcon width="16" height="16" stroke="#667085" />
              <p>{numOfFilteredPatients}</p>
            </div>
          ) : (
            !isDesktop && (
              <Button
                className="space-x-2 font-semibold w-10 md:w-fit"
                variant="gray-light"
                onClick={onImportPatients}
              >
                <ImportIcon width="20" height="20" className="flex-none" />
                {isDesktop && <p>Import</p>}
              </Button>
            )
          )}
        </div>

        {selectedPatients.length > 0 && isDesktop && (
          <>
            <VerticalDivider className="border-gray-foreground h-6" />
            <div
              className="w-fit text-xs font-bold text-primary-blue px-2 py-1 bg-slate-100
                border border-gray-foreground rounded-full flex items-center space-x-1"
            >
              <p>{selectedPatients.length} Selected</p>
              <button type="button" onClick={() => setCancelSelection(true)}>
                <CloseIcon stroke="#667085" width="14" height="14" />
              </button>
            </div>
          </>
        )}
      </div>

      <div className="flex items-center md:space-x-3 text-sm">
        {numOfPatients > 0 && (
          <div className="relative w-full md:max-w-lg">
            <input
              value={searchValue}
              onClick={selectInputText}
              onChange={handleSearchInput}
              className="rounded-lg h-10 text-sm outline-none w-full pl-11 placeholder:text-gray-400"
              placeholder="Search patient..."
            />
            <SearchIcon
              className="absolute top-2.5 left-4"
              width="20"
              height="20"
            />
          </div>
        )}

        <div className="ml-auto flex items-center space-x-3">
          {numOfPatients > 0 && <FilterButton />}

          {selectedPatients.length > 0 ? (
            <ActionsButton
              popMargin="8px 0 0 0"
              buttonClassName={`bg-white rounded-lg border border-gray-foreground h-10
                ${isDesktop ? "w-fit px-4" : "w-10"}`}
              label={
                isDesktop ? (
                  <div className="whitespace-nowrap flex items-center space-x-2">
                    <p className="text-tertiary text-sm font-semibold">
                      Select Action
                    </p>
                    <ArrowIcon width="18" height="18" />
                  </div>
                ) : (
                  <DotsIcon width="20" height="20" />
                )
              }
              actions={[
                {
                  icon: (
                    <DeleteIcon
                      width="20"
                      height="20"
                      stroke="#667085"
                      className="flex-none"
                    />
                  ),
                  label: "Delete",
                  onClick: handleDelete,
                },
              ]}
            />
          ) : (
            <>
              {(isDesktop || numOfPatients > 0) && (
                <Button
                  className="space-x-2 font-semibold w-10 md:w-fit"
                  variant="gray-light"
                  onClick={onImportPatients}
                >
                  <ImportIcon width="20" height="20" className="flex-none" />
                  {isDesktop && <p>Import</p>}
                </Button>
              )}
              {numOfPatients > 0 && (
                <Button
                  variant="blue-light"
                  className="space-x-2 font-semibold whitespace-nowrap w-10 md:w-fit"
                  onClick={onAddPatient}
                >
                  <PlusIcon width="12" height="12" className="flex-none" />
                  {isDesktop && <p>Add patient</p>}
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TableHeader;
