import {
  SET_PATIENT_NOTES_LOADING,
  SET_PROVIDER_NOTES_LOADING,
  type ScribeAction,
} from "./types";

export function setProviderNotesLoadingAction(payload: boolean): ScribeAction {
  return {
    type: SET_PROVIDER_NOTES_LOADING,
    payload,
  };
}

export function setPatientNotesLoadingAction(payload: boolean): ScribeAction {
  return {
    type: SET_PATIENT_NOTES_LOADING,
    payload,
  };
}
