import { useSelector } from "react-redux";
import ProfilePicture from "../../../Basic/ProfilePicture";
import ActionsButton from "../../../Basic/ActionsButton";
import Tag from "../../../Basic/Tag";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/trash-redesign.svg";
import { ReactComponent as EmailIcon } from "../../../../assets/icons/letter.svg";
import { ReactComponent as ResendIcon } from "../../../../assets/icons/letter-opened.svg";
import { ReactComponent as PasswordIcon } from "../../../../assets/icons/password.svg";

const UserListItem = ({
  userItem,
  onClickActions,
  onDelete,
  onResendEmail,
  onChangeEmail,
  onResetPassword,
  onClick,
}) => {
  const { user, roles } = useSelector((state) => state.user);

  return (
    <div className="bg-white p-4 rounded-xl space-y-4" onClick={onClick}>
      <div className="flex items-center justify-between space-x-2">
        <div className="flex items-center space-x-2 truncate">
          <ProfilePicture
            firstName={userItem.first_name}
            lastName={userItem.last_name}
            src={userItem.profile_picture}
            flagPadding={false}
          />
          <p className="text-base font-semibold truncate">
            {userItem.display_name.trim() || "-"}
          </p>
        </div>

        <div className="flex items-center space-x-2">
          {userItem.status === "Active" ? (
            <Tag text="Active" variant="emerald" />
          ) : userItem.status === "Pending" ? (
            <Tag text="Invited" variant="amber" />
          ) : (
            <></>
          )}

          <ActionsButton
            onClick={onClickActions}
            actions={[
              user.role_id === 1 && {
                icon: (
                  <EmailIcon width="20" height="20" className="flex-none" />
                ),
                label: "Change Email",
                onClick: onChangeEmail,
              },
              userItem.status === "Pending" && {
                icon: (
                  <ResendIcon width="20" height="20" className="flex-none" />
                ),
                label: "Resend Email",
                onClick: onResendEmail,
              },
              user.role_id === 1 && {
                icon: (
                  <PasswordIcon width="20" height="20" className="flex-none" />
                ),
                label: "Reset Password",
                onClick: onResetPassword,
              },
              {
                icon: (
                  <DeleteIcon width="20" height="20" className="flex-none" />
                ),
                label: "Delete",
                onClick: onDelete,
              },
            ]}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 divide-x border-gray-foreground">
        <div className="flex flex-col space-y-1 font-medium pr-3">
          <p className="text-xs text-tertiary">Role</p>
          <p className="text-sm">{roles[userItem.role_id]}</p>
        </div>
        <div className="flex flex-col space-y-1 font-medium truncate pl-3">
          <p className="text-xs text-tertiary">Position</p>
          <p className="text-sm truncate">
            {userItem.occupation?.trim() || "-"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default UserListItem;
