import { useCallback } from "react";
import type { AppDispatch } from "../../../store";
import { setNotificationAction } from "../../../store/user/actions";
import { sendPauseRecording } from "../../../store/voiceRecorder/thunks";
import type { Scribe } from "../store/interfaces";
import {
  setScribePaused,
  setScribeSelectedAudioId,
} from "../store/scribeSlice";

const useRecordingControls = (
  mediaRecorderRef: React.RefObject<MediaRecorder | null>,
  dispatch: AppDispatch,
  selectedScribe: Scribe,
  onSave: () => void,
  requestWakeLock: () => void,
  releaseWakeLock: () => void,
) => {
  const startRecording = useCallback(() => {
    const mediaRecorder = mediaRecorderRef.current;
    if (!mediaRecorder) {
      console.error("MediaRecorder is not available");
      return;
    }

    requestWakeLock();

    if (mediaRecorder.state === "recording") {
      return;
    }

    if (mediaRecorder.state === "paused") {
      mediaRecorder.resume();
      return;
    }

    mediaRecorder.start(2000); // Timeslice of 2000ms
  }, [mediaRecorderRef, requestWakeLock]);

  const togglePause = useCallback(async () => {
    const mediaRecorder = mediaRecorderRef.current;
    if (!mediaRecorder) {
      console.error("MediaRecorder is not available");

      return;
    }

    if (mediaRecorder.state === "recording") {
      const accumulated_duration = Math.floor(
        (Date.now() -
          new Date(selectedScribe?.startedAt).getTime() +
          selectedScribe?.accumulatedDuration) /
          1000,
      );

      dispatch(sendPauseRecording(accumulated_duration));
      mediaRecorder.pause();
    } else if (mediaRecorder.state === "paused") {
      mediaRecorder.resume();
    } else if (mediaRecorder.state === "inactive") {
      startRecording();
    }
  }, [
    mediaRecorderRef,
    selectedScribe?.startedAt,
    selectedScribe?.accumulatedDuration,
    dispatch,
    startRecording,
  ]);

  const cancelRecording = useCallback(() => {
    const mediaRecorder = mediaRecorderRef.current;
    if (
      mediaRecorder &&
      (mediaRecorder.state === "recording" || mediaRecorder.state === "paused")
    ) {
      dispatch(setScribePaused());
      mediaRecorder.stop();
    }

    dispatch(setScribeSelectedAudioId({ audioId: null }));
  }, [mediaRecorderRef, dispatch]);

  const saveRecording = useCallback(() => {
    const mediaRecorder = mediaRecorderRef.current;
    if (
      mediaRecorder &&
      (mediaRecorder.state === "recording" || mediaRecorder.state === "paused")
    ) {
      mediaRecorder.stop();
    }

    releaseWakeLock();
    onSave();
  }, [mediaRecorderRef, releaseWakeLock, onSave]);

  return { startRecording, togglePause, cancelRecording, saveRecording };
};

export default useRecordingControls;
