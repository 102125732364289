import { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { Controller } from "react-hook-form";
import ErrorRoundExclamation from "../ErrorRoundExclamation";
import "react-phone-input-2/lib/style.css";
import "./CountryPhoneInput.css";

const CountryPhoneInput = ({
  name,
  control,
  defaultCountry = "us",
  countries = ["us"],
  preferredCountries,
  placeholder = "Enter phone number",
  className,
  label,
  required,
  withoutAsterisk,
  error,
  resizeDeps = [],
  ...props
}) => {
  const [width, setWidth] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      handleResize();
      window.addEventListener("resize", handleResize);
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [containerRef, ...resizeDeps]);

  const handleResize = () => {
    setWidth(containerRef.current.clientWidth);
  };

  return (
    <div className={`flex flex-col text-sm ${className}`} ref={containerRef}>
      {label && (
        <p className="font-semibold mb-1.5">
          {label}
          {required && !withoutAsterisk && (
            <span className="text-red-600">*</span>
          )}
        </p>
      )}
      <div className="relative">
        <Controller
          name={name}
          control={control}
          rules={{ required: required }}
          render={({ field: { name, value, onChange, ref, onBlur } }) => (
            <PhoneInput
              placeholder={placeholder}
              country={defaultCountry}
              onlyCountries={countries}
              preferredCountries={preferredCountries}
              preserveOrder={["onlyCountries", "preferredCountries"]}
              countryCodeEditable={false}
              inputStyle={{
                borderColor: error ? "#f87171" : "#d1d5db",
              }}
              dropdownClass="scrollbar"
              dropdownStyle={{ width: width }}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              inputProps={{
                autoFocus: false,
                name,
                ref,
              }}
              {...props}
            />
          )}
        />
        {error && (
          <ErrorRoundExclamation className="absolute top-1/2 -translate-y-1/2 right-4" />
        )}
      </div>
      {error?.message && (
        <p className="text-red-400 font-normal mt-1.5">{error.message}</p>
      )}
    </div>
  );
};

export default CountryPhoneInput;
