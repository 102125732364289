import ProfilePicture from "../ProfilePicture";

const TeamOption = ({ option, ...props }) => {
  return (
    <div
      {...props}
      style={{
        height: "50px",
        borderRadius: "8px",
        padding: "8px",
      }}
    >
      <div className="grid grid-cols-conv-layout items-center justify-between gap-2.5 w-full min-w-0">
        <div className="flex items-center space-x-2.5 min-w-0">
          <ProfilePicture
            firstName={option.leader.first_name}
            lastName={option.leader.last_name}
            src={option.profile_picture}
            flagPadding={false}
          />
          <div className="truncate">
            <p className="text-sm font-semibold truncate">
              {option.leader.display_name}
            </p>
            <p className="text-xs font-medium text-tertiary truncate">
              Team: {option.name}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamOption;
