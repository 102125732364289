import {
  type PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { v7 as uuid } from "uuid";
import type { Pages } from "../../../helpers/constants";
import { parseDateString } from "../../../helpers/helpers";
import LocalStorageService from "../../../services/LocalStorageService";
import type { RootState } from "../../../store";
import type { Patient } from "../../../store/patient/interfaces";
import { initialTitle } from "../utils";
import type { Scribe, ScribeState } from "./interfaces";
import type { SetInterruptedRecordingActionType } from "./types";
import { getRecordingScribeTemplate } from "./utils";

const storageService = new LocalStorageService();

export type FixLater = any;

const mapServerNoteToState = (note: FixLater): Scribe => {
  return {
    ...getRecordingScribeTemplate({
      overrides: {
        audioId: note.audio_id,
        noteId: note.note_id,
        note: note.note || "",
        title: note.title || initialTitle(parseDateString(note.started_at)),
        patient: note.patient,
        createdAt: note.started_at,
        startedAt: note.started_at,
        finishedAt: note.finished_at,
        accumulatedDuration: Math.max(
          0,
          note.duration * 1000 ||
            new Date(note.finished_at).getTime() -
              new Date(note.started_at).getTime(),
        ),
        freeText: note.free_text,
        isPaused: note.audio_status === "paused",
        isGenerating: note.audio_status === "generating",
        isGenerationDelayed:
          note.audio_status === "failed_too_long" ||
          (note.finished_at &&
            new Date(parseDateString(note.finished_at)).getTime() <
              new Date(Date.now() - 1000 * 60 * 3).getTime()),
        isVisible: true,
        isPersisted: true,
        type: note.type || "scribe",
      },
    }),
  };
};

const mapServerNotesToState = (scribes: FixLater[]): Scribe[] => {
  return scribes.map((note) => mapServerNoteToState(note));
};

export const scribesAdapter = createEntityAdapter({
  selectId: (scribe: Scribe) => scribe.audioId,
  sortComparer: (scribe1, scribe2) => {
    return (
      new Date(scribe2.createdAt).getTime() -
      new Date(scribe1.createdAt).getTime()
    );
  },
});

const initialState = scribesAdapter.setAll(
  scribesAdapter.getInitialState({
    selectedAudioId: null,
    interruptedRecordingAction: null,
    isLoading: false,
  }),
  storageService.getItem<Scribe[]>("scribes") ?? [],
);

const scribeSelectors = scribesAdapter.getSelectors();

export const globalScribeSelectors = scribesAdapter.getSelectors(
  (state: RootState) => state.scribe,
);

export const scribeSlice = createSlice({
  name: "scribe",
  initialState,
  reducers: {
    setScribeRecording(
      state,
      action: PayloadAction<{ patient: Patient | null }>,
    ) {
      const newScribe: Scribe = {
        ...getRecordingScribeTemplate({
          overrides: {
            audioId: uuid(),
            isVisible: true,
            isRecording: true,
            createdAt: new Date().toISOString(),
            startedAt: new Date().toISOString(),
            patient: action.payload.patient,
          },
        }),
      };

      scribesAdapter.addOne(state, newScribe);
      state.selectedAudioId = newScribe.audioId;
    },

    setScribeRecordingGenerating(state) {
      const selectedAudioId = state.selectedAudioId;
      if (!selectedAudioId) {
        return;
      }

      const scribe = scribeSelectors.selectById(state, selectedAudioId);
      if (scribe) {
        const now = new Date();
        const accumulatedDuration = scribe.isRecording
          ? Math.max(
              0,
              now.getTime() -
                new Date(scribe.startedAt).getTime() +
                scribe.accumulatedDuration,
            )
          : scribe.accumulatedDuration;

        scribesAdapter.updateOne(state, {
          id: selectedAudioId,
          changes: {
            finishedAt: now.toISOString(),
            isRecording: false,
            isGenerating: true,
            isPaused: false,
            accumulatedDuration,
          },
        });
      }
    },

    setScribePaused(state) {
      const scribes = scribeSelectors.selectAll(state);
      scribes.forEach((scribe) => {
        if (scribe && (scribe.isRecording || scribe.isGenerating)) {
          const accumulatedDuration = scribe.isRecording
            ? Math.max(
                0,
                new Date().getTime() -
                  new Date(scribe.startedAt).getTime() +
                  scribe.accumulatedDuration,
              )
            : scribe.accumulatedDuration;

          scribesAdapter.updateOne(state, {
            id: scribe.audioId,
            changes: {
              isRecording: false,
              isPaused: true,
              isGenerating: false,
              accumulatedDuration,
            },
          });
        }
      });
    },

    setScribeTogglePause(state) {
      const selectedAudioId = state.selectedAudioId;
      if (!selectedAudioId) {
        return;
      }

      const scribe = scribeSelectors.selectById(state, selectedAudioId);
      if (scribe) {
        const accumulatedDuration = scribe.isRecording
          ? Math.max(
              0,
              new Date().getTime() -
                new Date(scribe.startedAt).getTime() +
                scribe.accumulatedDuration,
            )
          : scribe.accumulatedDuration;

        const isRecording = !scribe.isRecording;
        scribesAdapter.updateOne(state, {
          id: selectedAudioId,
          changes: {
            isRecording,
            isPaused: !isRecording,
            isGenerating: false,
            startedAt: isRecording
              ? new Date().toISOString()
              : scribe.startedAt,
            accumulatedDuration,
          },
        });
      }
    },

    setScribeDeleted(state, action: PayloadAction<{ audioId: string }>) {
      scribesAdapter.removeOne(state, action.payload.audioId);
    },

    setScribes(state, action: PayloadAction<{ scribes: FixLater[] }>) {
      const scribesFromServer = mapServerNotesToState(action.payload.scribes);

      // Merge with existing scribes, preserving local changes
      scribesFromServer.forEach((scribe) => {
        const existingScribe = scribeSelectors.selectById(
          state,
          scribe.audioId,
        );
        if (!existingScribe) {
          scribesAdapter.addOne(state, scribe);
          return;
        }

        if (scribe.note === "") {
          delete scribe.note;
        }

        scribesAdapter.updateOne(state, {
          id: scribe.audioId,
          changes: scribe,
        });
      });
    },

    setScribe(
      state,
      action: PayloadAction<{ audioId: string; scribe: Partial<Scribe> }>,
    ) {
      const { audioId, scribe } = action.payload;
      const currentScribeData = scribeSelectors.selectById(state, audioId);
      if (currentScribeData) {
        const updates: Partial<Scribe> = { ...scribe };

        if (updates.isEditing === false) {
          delete updates.isEditing;
          delete updates.originalTitle;
        }

        scribesAdapter.updateOne(state, {
          id: audioId,
          changes: updates,
        });
      }
    },

    setScribeNoteContent(
      state,
      action: PayloadAction<{ audioId: string; noteContent: string }>,
    ) {
      const scribe = scribeSelectors.selectById(state, action.payload.audioId);
      if (scribe) {
        scribesAdapter.updateOne(state, {
          id: action.payload.audioId,
          changes: { note: action.payload.noteContent },
        });
      }
    },

    setScribeSelectedAudioId(
      state,
      action: PayloadAction<{ audioId: string }>,
    ) {
      state.selectedAudioId = action.payload.audioId;
    },

    setInterruptedRecordingAction(
      state,
      action: PayloadAction<{
        type: SetInterruptedRecordingActionType;
        value?: Pages | string;
      }>,
    ) {
      state.interruptedRecordingAction = action.payload;
    },

    setScribeLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setScribeRecording,
  setScribeRecordingGenerating,
  setScribePaused,
  setScribeTogglePause,
  setScribeDeleted,
  setScribes,
  setScribe,
  setScribeNoteContent,
  setScribeSelectedAudioId,
  setInterruptedRecordingAction,
} = scribeSlice.actions;

export default scribeSlice.reducer;
