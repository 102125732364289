import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { updateOrganization } from "../../../store/user/thunks";
import Button from "../../Basic/Button";
import Input from "../../Basic/Input";
import CountryPhoneInput from "../../Basic/PhoneInput/CountryPhoneInput";
import TextareaAutosizeForm from "../../Basic/TextareaAutosizeForm";
import { countryCodes } from "../../../helpers/constants";

const EditOrganizationForm = ({ user, className = "", onClose }) => {
  const dispatch = useDispatch();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: user.customer.name,
      display_name: user.customer.display_name,
      legacy_phone_number: user.customer.legacy_phone_number,
      clinic_address: user.customer.clinic_address,
    },
  });

  const onSubmit = (data) => {
    dispatch(
      updateOrganization(
        data.name,
        data.display_name,
        data.legacy_phone_number,
        data.clinic_address,
        onClose,
      ),
    );
  };

  return (
    <div
      className={`w-full grid grid-rows-note-layout overflow-hidden ${className}`}
    >
      <div className="min-h-0 flex flex-col overflow-y-auto scrollbar">
        <form
          id="edit-organization"
          onSubmit={handleSubmit(onSubmit)}
          className="px-5 space-y-4"
        >
          <Input
            label="Clinic Name"
            register={register}
            name="name"
            placeholder="Type clinic name"
            required="Clinic name can't be empty"
            withoutAsterisk
            validate={(value) => {
              if (value.trim()) {
                return true;
              }
              return "Clinic name can't be empty";
            }}
            error={errors.name}
          />
          <Input
            label="Clinic Display Name"
            register={register}
            name="display_name"
            placeholder="Type clinic display name"
          />

          <div className="text-sm space-y-2">
            <p className="font-semibold">Clinic Phone Number</p>
            <p className="text-tertiary font-medium">
              {user.customer.phone_number}
            </p>
          </div>

          <CountryPhoneInput
            name="legacy_phone_number"
            label="Legacy Phone Number"
            countries={countryCodes}
            control={control}
            error={errors.legacy_phone_number}
          />
          <TextareaAutosizeForm
            label="Clinic Address"
            name="clinic_address"
            register={register}
            placeholder="Type clinic address"
            minRows={3}
          />
        </form>
      </div>

      <div className="flex justify-end gap-4 font-semibold text-sm bg-gray-background py-4 px-5 mt-5">
        <Button className="w-full md:w-fit" variant="gray" onClick={onClose}>
          Cancel
        </Button>
        <Button
          className="w-full md:w-fit"
          form="edit-organization"
          type="submit"
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default EditOrganizationForm;
