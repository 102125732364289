import type { Scribe } from "./store/interfaces";
import type { ScribeGroupItem } from "./store/selectors";

export const initialTitle = (date: Date) =>
  `Encounter - ${date.toLocaleDateString(undefined, {
    month: "numeric",
    day: "numeric",
  })}`;

export const isGeneratedNote = (note: ScribeGroupItem | Scribe) => {
  return (
    note?.note && !note?.isPaused && !note?.isGenerating && !note?.isRecording
  );
};
