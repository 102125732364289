import { ReactComponent as DeleteIcon } from "../../../assets/icons/trash-redesign.svg";
import { ReactComponent as UserRoundedIcon } from "../../../assets/icons/user-rounded.svg";
import { useAppDispatch, useAppSelector } from "../../../store";
import ActionsButton from "../../Basic/ActionsButton";
import Loader from "../../Basic/Loader";
import RecordingIndicator from "../RecordingIndicator";
import { MOCK_PATIENT_FIRST_NAME } from "../consts";
import type { ScribeGroupItem } from "../store/selectors";

const ScribeListItem = ({
  scribe,
  onClick,
  onDelete,
}: {
  scribe: ScribeGroupItem;
  onClick: () => void;
  onDelete: () => void;
}) => {
  const { onlyScribeFeature } = useAppSelector((state) => state.user);
  const { selectedAudioId } = useAppSelector((state) => state.scribe);

  const isSelected = selectedAudioId === scribe.audioId;

  const showPatient =
    scribe.patient &&
    // Hack for unassigned patient
    scribe.patient.first_name !== null &&
    scribe.patient.first_name !== MOCK_PATIENT_FIRST_NAME;
  // End hack

  return (
    <>
      <div
        onClick={onClick}
        className={`flex justify-between p-3 py-2 cursor-pointer rounded-lg border border-transparent hover:bg-white group
        ${isSelected ? "md:bg-[#d5dce3] md:hover:bg-[#d5dce3]" : "hover:border-gray-foreground"}`}
      >
        <div className="flex flex-col w-full">
          <div className="flex flex-row justify-between">
            <p
              className={`w-50 h-5 text-sm font-semibold truncate text-primary
              ${isSelected ? "" : "group-hover:text-primary-blue"}`}
            >
              {scribe.title}
            </p>
            <div className="min-w-10 flex justify-end">
              {!scribe.isGenerating && !scribe.isRecording && (
                <>
                  {!scribe.isPaused && (
                    <ActionsButton
                      buttonClassName="w-5 h-5 rounded-md"
                      className="md:hidden"
                      actions={[
                        {
                          icon: (
                            <DeleteIcon
                              width="20"
                              height="20"
                              className="flex-none"
                            />
                          ),
                          label: "Delete",
                          onClick: onDelete,
                        },
                      ]}
                    />
                  )}

                  <button
                    type="button"
                    className="hidden md:flex flex-none opacity-0 md:group-hover:opacity-100 md:justify-end"
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete();
                    }}
                  >
                    <DeleteIcon width="16" height="16" />
                  </button>
                </>
              )}
              {scribe.isGenerating && (
                <Loader
                  size={4}
                  borderWidth={2}
                  borderColor="border-zinc-450"
                />
              )}
              {((!scribe.isGenerating && scribe.isRecording) ||
                scribe.isPaused) && (
                <RecordingIndicator
                  isRecording={!scribe.isPaused}
                  size={20}
                  className={scribe.isPaused ? "md:group-hover:hidden" : ""}
                />
              )}
            </div>
          </div>
          <div className="flex flex-row justify-between text-xs text-[#404654]">
            {showPatient ? (
              <div className="flex space-x-3">
                <p>
                  {[
                    `${scribe.patient.first_name} ${scribe.patient.last_name}`.trim(),
                    scribe.duration,
                  ]
                    .filter((field) => !!field)
                    .join(" ・ ")}
                </p>
              </div>
            ) : (
              <p className="flex">
                {!onlyScribeFeature && (
                  <UserRoundedIcon width="16" height="16" stroke="#667085" />
                )}
                {scribe.duration !== null
                  ? `${onlyScribeFeature ? "" : " ・ "}${scribe.duration}`
                  : ""}
              </p>
            )}
            <p className="text-xs text-[#A0A6B2]">{scribe.time}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScribeListItem;
