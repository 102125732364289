const ErrorRoundExclamation = ({ className }) => {
  return (
    <div
      className={`w-4 h-4 rounded-full bg-red-400 flex items-center justify-center text-white text-[9px] font-bold ${className}`}
    >
      !
    </div>
  );
};

export default ErrorRoundExclamation;
