import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "@mui/material/utils";
import { setFacilitySearchValueAction } from "../../store/facility/actions";
import { getFacilities } from "../../store/facility/thunks";
import { selectInputText } from "../../helpers/helpers";
import FacilitiesTableSkeleton from "../../components/Skeletons/FacilitiesTableSkeleton";
import SettingsContainer from "../../components/Settings/SettingsContainer";
import UserTable from "../../components/Settings/Organization/UserTable";
import FacilityListItem from "../../components/Settings/FacilityListItem";
import AvatarStack from "../../components/Basic/AvatarStack";
import { ReactComponent as PatientIcon } from "../../assets/icons/patients-redesign.svg";
import { ReactComponent as PatientHeartIcon } from "../../assets/icons/user-heart-rounded.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import emptyTeamsIllustrationSrc from "../../assets/empty-teams.svg";

const FacilitiesSettings = () => {
  const { facilities, facilitySearchValue, facilitiesLoading } = useSelector(
    (state) => state.facility,
  );
  const dispatch = useDispatch();

  const columns = [
    { label: "Clinic Name", accessor: "name" },
    { label: "Number of providers", accessor: "providers" },
    { label: "Number of teams", accessor: "total_teams" },
    { label: "Number of patients", accessor: "total_patients" },
    { label: "Phone Number", accessor: "phone_number" },
    // { label: "", accessor: "actions" },
  ];

  const getTableData = () => {
    const tableData = [];

    if (facilities?.length > 0) {
      facilities.forEach((facility) =>
        tableData.push({
          name: (
            <p className="font-bold">
              {facility.display_name || facility.name}
            </p>
          ),
          providers: (
            <AvatarStack
              users={facility.providers}
              length={4}
              totalNumber={facility.providers.length}
            />
          ),
          total_teams: (
            <div
              className="text-xs font-bold text-tertiary px-2 py-1 bg-slate-100 rounded-full
                w-20 flex items-center justify-center space-x-1 border border-gray-foreground"
            >
              <PatientIcon width="16" height="16" stroke="#667085" />
              <p>{facility.total_teams}</p>
            </div>
          ),
          total_patients: (
            <div
              className="text-xs font-bold text-tertiary px-2 py-1 bg-slate-100 rounded-full
                w-20 flex items-center justify-center space-x-1 border border-gray-foreground"
            >
              <PatientHeartIcon width="16" height="16" stroke="#667085" />
              <p>{facility.total_patients}</p>
            </div>
          ),
          phone_number: <p>{facility.phone_number || "-"}</p>,
        }),
      );
    }
    return tableData;
  };

  const handleSearchInput = (e) => {
    dispatch(setFacilitySearchValueAction(e.target.value));
    debouncedSearch(e.target.value);
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((inputValue) => {
        dispatch(getFacilities(inputValue));
      }, 300),
    [],
  );

  return (
    <SettingsContainer>
      <div className="space-y-4 h-full md:flex md:flex-col md:overflow-hidden w-full">
        <div className="flex items-center justify-between gap-4">
          <p className="text-xl font-semibold hidden md:flex">Facilities</p>

          <div className="relative w-full md:max-w-md">
            <input
              value={facilitySearchValue}
              onClick={selectInputText}
              onChange={handleSearchInput}
              className="rounded-lg h-10 text-sm outline-none w-full pl-11 placeholder:text-gray-400"
              placeholder="Search organizations..."
            />
            <SearchIcon
              className="absolute top-2.5 left-4"
              width="20"
              height="20"
            />
          </div>
        </div>

        {facilitiesLoading || facilities === null ? (
          <FacilitiesTableSkeleton />
        ) : facilities.length > 0 ? (
          <>
            <UserTable
              columns={columns}
              data={getTableData()}
              className="hidden md:block"
            />
            <div className="space-y-3 md:hidden">
              {facilities.map((facility) => (
                <FacilityListItem
                  key={facility.customer_id}
                  facility={facility}
                />
              ))}
            </div>
          </>
        ) : (
          <div className="w-full flex flex-col items-center justify-center py-10 md:py-16 space-y-7">
            <img
              src={emptyTeamsIllustrationSrc}
              alt="Empty Facilities"
              width="459"
              height="165"
            />
            <p className="text-xl font-bold">No Facilities Found</p>
          </div>
        )}
      </div>
    </SettingsContainer>
  );
};

export default FacilitiesSettings;
