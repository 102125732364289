const Loader = ({
  className = "",
  size = 8,
  borderWidth = 4,
  borderColor = "border-primary-blue",
}) => {
  return (
    <div
      className={`inline-block animate-spin rounded-full border-solid ${borderColor} border-r-transparent
        align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] border-${borderWidth} h-${size} w-${size}
        ${className}`}
      role="status"
    />
  );
};

export default Loader;
