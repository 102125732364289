import { useEffect, useRef } from "react";

type EventHandler<K extends keyof WindowEventMap> = (
  event: WindowEventMap[K],
) => void;
type DocumentEventHandler<K extends keyof DocumentEventMap> = (
  event: DocumentEventMap[K],
) => void;
type ElementEventHandler<K extends keyof HTMLElementEventMap> = (
  event: HTMLElementEventMap[K],
) => void;

function useEventListener<K extends keyof WindowEventMap>(
  eventName: K,
  handler: EventHandler<K>,
  element?: Window,
): void;
function useEventListener<K extends keyof DocumentEventMap>(
  eventName: K,
  handler: DocumentEventHandler<K>,
  element?: Document,
): void;
function useEventListener<K extends keyof HTMLElementEventMap>(
  eventName: K,
  handler: ElementEventHandler<K>,
  element?: HTMLElement,
): void;
function useEventListener(
  eventName: string,
  handler: (event: Event) => void,
  element?: EventTarget,
): void {
  const savedHandler = useRef<(event: Event) => void>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const targetElement: EventTarget = element ?? window;

    if (!targetElement?.addEventListener) {
      return;
    }

    const eventListener = (event: Event) => {
      savedHandler.current?.(event);
    };

    targetElement.addEventListener(eventName, eventListener);

    return () => {
      targetElement.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
}

export default useEventListener;
