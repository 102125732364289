import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit-pen.svg";
import {
  addAssistant,
  removeAssistant,
  updateUserTeam,
} from "../../../../store/user/thunks";
import AutocompleteVirtualized from "../../../Basic/Autocomplete/AutocompleteVirtualized";
import Button from "../../../Basic/Button";
import Input from "../../../Basic/Input";
import ProfilePicture from "../../../Basic/ProfilePicture";

const Member = ({ member, edit, lead, onRemove }) => {
  return (
    member && (
      <div className="grid items-center grid-cols-conv-layout">
        <div className="flex items-center space-x-2 py-2 truncate">
          <ProfilePicture
            firstName={member.first_name}
            lastName={member.last_name}
            src={member.profile_picture}
          />
          <div className="truncate">
            <p className="text-sm font-bold truncate">
              {member.display_name
                ? member.display_name
                : `${member.first_name} ${member.last_name}`}
            </p>
            <p className="text-xs font-medium text-tertiary truncate">
              {member.occupation}
            </p>
          </div>
        </div>
        <div className="flex items-center">
          {lead && (
            <div
              className="rounded-full border border-gray-foreground bg-gray-background px-3 py-1 text-xs font-medium
                whitespace-nowrap"
            >
              Clinical lead
            </div>
          )}
          {edit && !lead && (
            <button type="button" onClick={() => onRemove(member.doctor_id)}>
              <CloseIcon stroke="#667085" width="16" height="16" />
            </button>
          )}
        </div>
      </div>
    )
  );
};
const EditTeamForm = ({
  className,
  onClose,
  onCancel,
  selectedTeam,
  newTeam,
  onSave = () => {},
}) => {
  const { teams, users, teamSearchValue } = useSelector((state) => state.user);
  const [memberOptions, setMemberOptions] = useState([]);
  const [editName, setEditName] = useState(false);
  const [teamName, setTeamName] = useState(
    selectedTeam.team?.name || selectedTeam.display_name,
  );
  const [team, setTeam] = useState(selectedTeam);
  const [editMembers, setEditMembers] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setMemberOptions(
      users.filter((user) => {
        return (
          !team.assistants.some(
            (assistant) => assistant.doctor_id === user.doctor_id,
          ) && user.doctor_id !== team.doctor_id
        );
      }),
    );
  }, [users, team]);

  useEffect(() => {
    setTeam(teams.find((team) => team.doctor_id === selectedTeam.doctor_id));
  }, [teams]);

  const handleUpdateName = () => {
    dispatch(
      updateUserTeam(
        team.team.doctor_team_id,
        teamName,
        team.team.show_inbox,
        !newTeam,
        () => {
          onSave();
          onClose();
        },
        teamSearchValue,
      ),
    );
  };

  const handleRemoveAssistant = (assistantId) => {
    dispatch(removeAssistant(assistantId, team.doctor_id, teamSearchValue));
  };

  const handleSelectAssistant = (assistant) => {
    dispatch(
      addAssistant(assistant.doctor_id, team.doctor_id, teamSearchValue),
    );
  };

  return (
    <div
      className={`w-full h-full grid grid-rows-note-layout overflow-hidden ${className}`}
    >
      <div className="h-full grid grid-rows-chat-layout overflow-hidden pl-5 pr-2">
        <div>
          <p className="text-sm font-medium text-tertiary mb-1 pr-2">
            Team Name
          </p>
          <div className="mb-4 pr-2 truncate">
            {editName ? (
              <Input
                value={teamName}
                onChange={(e) => setTeamName(e.target.value)}
                placeholder="Enter team name"
                className="w-full text-xl font-bold"
              />
            ) : (
              <button
                type="button"
                className="w-full h-10 border border-transparent rounded-lg px-3 grid grid-cols-2-right gap-3
                items-center transition-all group hover:bg-gray-foreground truncate"
                onClick={() => setEditName(true)}
              >
                <p className="text-xl font-bold truncate">{teamName}</p>
                <EditIcon
                  width="18"
                  height="18"
                  className="transition-all opacity-0 group-hover:opacity-50"
                />
              </button>
            )}
          </div>

          <div className="flex items-center justify-between mb-3 font-bold pr-2">
            <p className="text-sm">
              Members ({(team.assistants ? team.assistants.length : 0) + 1})
            </p>
            {team.assistants?.length > 0 && (
              <button
                type="button"
                className="text-primary-blue text-xs"
                onClick={() => setEditMembers(!editMembers)}
              >
                Edit
              </button>
            )}
          </div>
        </div>

        <div className="space-y-1 mb-2 pr-2 overflow-y-auto scrollbar min-h-0">
          <Member member={team} edit={editMembers} lead />
          {team.assistants?.length > 0 &&
            team.assistants.map((assistant) => (
              <Member
                key={assistant.doctor_id}
                member={assistant}
                edit={editMembers}
                onRemove={handleRemoveAssistant}
              />
            ))}
        </div>

        <AutocompleteVirtualized
          options={memberOptions}
          onSelect={handleSelectAssistant}
          placeholder="Add new member"
          className="w-full bg-white rounded-lg pr-2"
          isDoctors
        />
      </div>

      <div className="flex justify-end gap-4 font-semibold text-sm bg-gray-background py-4 px-5 mt-5">
        <Button className="w-full md:w-fit" variant="gray" onClick={onCancel}>
          Cancel
        </Button>
        <Button className="w-full md:w-fit" onClick={handleUpdateName}>
          {newTeam ? "Save New Team" : "Save"}
        </Button>
      </div>
    </div>
  );
};

export default EditTeamForm;
