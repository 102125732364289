import { ReactComponent as FileCheckIcon } from "../../../assets/icons/file-check.svg";
import { ReactComponent as PauseCircleIcon } from "../../../assets/icons/pause-circle.svg";
import { ReactComponent as PauseIcon } from "../../../assets/icons/pause-filled.svg";
import { ReactComponent as PlayCircleIcon } from "../../../assets/icons/play-circule.svg";
import { ReactComponent as PlayIcon } from "../../../assets/icons/play.svg";
import { ReactComponent as CancelIcon } from "../../../assets/icons/x-mark.svg";
import { useAppDispatch, useAppSelector } from "../../../store";
import RecordingSavingFooter from "../RecordingSavingFooter";
import {
  setInterruptedRecordingAction,
  setScribeTogglePause,
} from "../store/scribeSlice";
import { getRecordingScribe, getSelectedScribe } from "../store/selectors";
import { SetInterruptedRecordingActionType } from "../store/types";

const ScribeRecordingFooterButtons = ({
  cancelRecording,
  saveRecording,
  togglePause,
}: {
  cancelRecording: () => void;
  saveRecording: () => void;
  togglePause: () => void;
}) => {
  const recordingScribe = useAppSelector(getRecordingScribe);
  const selectedScribe = useAppSelector(getSelectedScribe);
  const dispatch = useAppDispatch();

  const canStartRecording =
    !recordingScribe || recordingScribe.audioId === selectedScribe?.audioId;

  const canGenerateNote = !selectedScribe?.hasError;

  const buttonClasses =
    "w-full h-12 py-3 text-base font-semibold items-center justify-center gap-3";

  const handleTogglePause = () => {
    if (!canStartRecording) {
      dispatch(
        setInterruptedRecordingAction({
          type: SetInterruptedRecordingActionType.RESUME_ANOTHER_ENCOUNTER,
          value: selectedScribe?.audioId,
        }),
      );
      return;
    }

    togglePause();
    dispatch(setScribeTogglePause());
  };

  return (
    <div className="flex mt-16 md:mt-0 lg:space-x-4 relative w-full md:min-w-[265px] lg:w-[440px]">
      <button
        type="button"
        disabled={selectedScribe?.hasError || selectedScribe?.isGenerating}
        className={`${buttonClasses} hidden md:flex flex-auto w-1/2 text-primary bg-gray-foreground text-center
          font-semibold rounded-lg border-l-2 border-white`}
        onClick={handleTogglePause}
      >
        {selectedScribe?.isPaused ? (
          <PlayCircleIcon
            width="20"
            height="20"
            className="flex-none"
            stroke="#121212"
          />
        ) : (
          <PauseCircleIcon
            width="20"
            height="20"
            className="flex-none"
            stroke="#121212"
          />
        )}
        <p>{selectedScribe?.isPaused ? "Resume" : "Pause"}</p>
      </button>
      <button
        type="button"
        disabled={selectedScribe?.hasError}
        className={`${buttonClasses} flex md:hidden flex-auto w-1/2 text-tertiary bg-gray-110 text-center font-semibold`}
        onClick={cancelRecording}
      >
        <CancelIcon width="21" height="20" className="flex-none" />
        <p>Cancel</p>
      </button>
      {selectedScribe?.isGenerating && !selectedScribe?.hasError ? (
        <div
          className={`md:ml-2 ${buttonClasses} flex flex-auto w-1/2 text-primary text-center font-semibold bg-primary-blue-light
            md:bg-gray-foreground md:rounded-lg border-l-2 border-white`}
        >
          <RecordingSavingFooter />
        </div>
      ) : (
        <button
          type="button"
          disabled={!canGenerateNote}
          className={`md:ml-2 ${buttonClasses} flex flex-auto w-1/2 text-primary-blue text-center font-semibold
            bg-primary-blue-light md:rounded-lg border-l-2 border-white`}
          onClick={saveRecording}
        >
          <FileCheckIcon
            width="20"
            height="20"
            stroke="#2970FF"
            fill="#2970FF"
            className="flex-none"
          />
          <p className="hidden lg:block whitespace-nowrap">Generate note</p>
          <p className="block lg:hidden">Generate</p>
        </button>
      )}
      <button
        type="button"
        disabled={selectedScribe?.hasError || selectedScribe?.isGenerating}
        className="absolute z-10 -top-10 left-1/2 -translate-x-1/2 rounded-full bg-primary-blue h-14 w-14
          flex items-center justify-center border-[2.8px] border-white md:hidden box-content"
        onClick={handleTogglePause}
      >
        {selectedScribe?.isPaused ? (
          <PlayIcon width="17.84" height="19.19" fill="white" />
        ) : (
          <PauseIcon width="28" height="28" />
        )}
      </button>
    </div>
  );
};

export default ScribeRecordingFooterButtons;
