import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendar.svg";
import { setIsDatePickerOpen } from "../../../store/ui/actions";
import CustomDateShortcuts from "./CustomDateShortcuts";
import { getSlotProps, getStyles } from "./propsHelpers";

const DateTimeShortcutsInput = ({
  className,
  label,
  name,
  required,
  validateFunc = () => true,
  withTime,
  withoutAsterisk,
  withShortcuts,
  control,
  error,
  minDateTime,
  maxDateTime,
  onSelectDate = () => {},
  disabled,
}) => {
  const dispatch = useDispatch();
  const [borderColor, setBorderColor] = useState("rgb(209 213 219)");
  const desktopModeMediaQuery = "@media (min-width: 0px)";

  const OpenCalendarIcon = () => {
    return (
      <CalendarIcon
        stroke={error ? "#eb4647" : "black"}
        width="16"
        height="16"
      />
    );
  };

  const handleOpen = () => {
    dispatch(setIsDatePickerOpen(true));
  };

  const handleClose = () => {
    dispatch(setIsDatePickerOpen(false));
  };

  useEffect(() => {
    if (error) {
      setBorderColor("rgb(248 113 113)");
    } else {
      setBorderColor("rgb(209 213 219)");
    }
  }, [error]);

  return (
    <div className={`flex flex-col ${className}`}>
      {label && (
        <p className="text-sm font-semibold mb-1.5">
          {label}
          {required && !withoutAsterisk && (
            <span className="text-red-600">*</span>
          )}
        </p>
      )}
      <Controller
        name={name}
        control={control}
        rules={{ required: required, validate: { validDate: validateFunc } }}
        render={({ field: { value, onChange, ...props } }) => {
          return withTime ? (
            <DateTimePicker
              views={["year", "month", "day", "hours", "minutes"]}
              value={value}
              disabled={disabled}
              onChange={(e) => {
                onChange(e);
                onSelectDate(e);
              }}
              onOpen={handleOpen}
              onClose={handleClose}
              {...props}
              slots={{
                openPickerIcon: OpenCalendarIcon,
                shortcuts: CustomDateShortcuts,
              }}
              slotProps={getSlotProps(withShortcuts)}
              sx={getStyles(borderColor)}
              desktopModeMediaQuery={desktopModeMediaQuery}
              minDateTime={minDateTime}
              timeSteps={{ minutes: 1 }}
              closeOnSelect={false}
            />
          ) : (
            <DatePicker
              views={["year", "month", "day"]}
              value={value}
              disabled={disabled}
              onChange={(e) => onChange(e)}
              onOpen={handleOpen}
              onClose={handleClose}
              {...props}
              slots={{
                openPickerIcon: OpenCalendarIcon,
              }}
              slotProps={getSlotProps(withShortcuts)}
              sx={getStyles(borderColor)}
              desktopModeMediaQuery={desktopModeMediaQuery}
              maxDate={maxDateTime}
              closeOnSelect={false}
            />
          );
        }}
      />
      {error?.message && (
        <p className="text-sm text-red-400 font-normal mt-1.5">
          {error.message}
        </p>
      )}
    </div>
  );
};

export default DateTimeShortcutsInput;
