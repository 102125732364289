import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import { debounce } from "@mui/material/utils";
import { useEffect, useMemo, useState } from "react";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import Loader from "../Loader";
import UserOption from "./UserOption";

const AutocompleteRequests = ({
  label,
  placeholder,
  options,
  disabledIds,
  onSelect,
  className,
  isDoctors,
  loadOptions,
  hasMore,
  loading,
  inputClassName,
  pathBeforeProfile,
}) => {
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [currPage, setCurrPage] = useState(1);

  const debouncedLoadOptions = useMemo(
    () =>
      debounce((inputValue, currPage) => {
        loadOptions(inputValue, currPage);
      }, 300),
    [],
  );

  useEffect(() => {
    debouncedLoadOptions(inputValue, 1);
  }, [inputValue, debouncedLoadOptions]);

  const PaperComponent = useMemo(
    () =>
      ({ children, ...props }) => {
        return (
          <Paper
            {...props}
            sx={{
              margin: 0,
              padding: 0,
              borderRadius: "12px",
            }}
          >
            <div className="p-2 pr-1.5" style={{ paddingBottom: 0 }}>
              {children}
            </div>
          </Paper>
        );
      },
    [],
  );

  return (
    <div className={className}>
      {label && <p className="text-sm font-semibold mb-1.5">{label}</p>}
      <Autocomplete
        autoComplete={false}
        autoHighlight
        disableListWrap
        options={options}
        filterOptions={(x) => x}
        getOptionDisabled={(option) =>
          disabledIds?.includes(option.patient_id) && !!option.phone_number
        }
        getOptionLabel={(option) =>
          `${isDoctors ? "Dr. " : ""}${option.preferred_name ? option.preferred_name : option.first_name} ${option.last_name}`
        }
        renderOption={(props, option, state) => (
          <UserOption
            isDoctors={isDoctors}
            option={option}
            pathBeforeProfile={pathBeforeProfile}
            {...props}
            key={`${isDoctors ? option.doctor_id : option.patient_id}_${state.index}`}
          />
        )}
        value={autocompleteValue}
        onChange={(_e, value) => {
          onSelect(value);
          setAutocompleteValue(null);
          setInputValue("");
        }}
        inputValue={inputValue}
        onInputChange={(_e, inputValue) => {
          setInputValue(inputValue);
        }}
        ListboxProps={{
          className:
            "scrollbar font-Mulish text-sm font-semibold text-zinc-700",
          onScroll: ({ currentTarget }) => {
            const scrollPosition =
              currentTarget.scrollTop + currentTarget.clientHeight;
            if (currentTarget.scrollHeight - scrollPosition <= 1 && hasMore) {
              loadOptions(inputValue, currPage + 1);
              setCurrPage(currPage + 1);
            }
          },
        }}
        PaperComponent={PaperComponent}
        renderInput={(params) => (
          <div ref={params.InputProps.ref} className="relative">
            <input
              name="patient-search"
              type="text"
              placeholder={placeholder}
              {...params.inputProps}
              aria-autocomplete="none"
              autoComplete="patient-search"
              className={`py-2.5 pl-9 pr-3 rounded-lg border border-gray-300 w-full outline-none text-sm text-zinc-700 ${inputClassName}`}
            />
            <div className="absolute left-2.5 top-1/2 -translate-y-1/2">
              <SearchIcon width="18" height="18" stroke="#3C3C47" />
            </div>
            {loading && (
              <div className="absolute right-2.5 pt-1 top-1/2 -translate-y-1/2">
                <Loader size={5} borderWidth={2} />
              </div>
            )}
          </div>
        )}
      />
    </div>
  );
};

export default AutocompleteRequests;
