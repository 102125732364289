import { useEffect, useRef } from "react";
import type { Scribe } from "../components/IndependentScribe/store/interfaces";
import { setScribe } from "../components/IndependentScribe/store/scribeSlice";
import { getAllScribes } from "../components/IndependentScribe/store/selectors";
import { initialTitle } from "../components/IndependentScribe/utils";
import { parseDateString } from "../helpers/helpers";
import { useAppDispatch, useAppSelector } from "../store";
import { saveEncounterTitle } from "../store/conversation/thunks";
import { setPatientRecordingScribeTitleAction } from "../store/patient/actions";

export const useScribeTitleChange = (selectedScribe: Scribe) => {
  const scribes = useAppSelector(getAllScribes);
  const dispatch = useAppDispatch();

  const scribesValidTitles = useRef<Scribe[]>([]);

  const changeScribeTitle = (title: string) => {
    dispatch(
      setScribe({
        audioId: selectedScribe.audioId,
        scribe: {
          title,
        },
      }),
    );
  };

  const onUpdateTitle = (title: string) => {
    const defaultTitle = initialTitle(
      parseDateString(selectedScribe?.startedAt) || new Date(),
    );

    if (selectedScribe?.isRecording || selectedScribe?.isPaused) {
      dispatch(setPatientRecordingScribeTitleAction(title));
    }

    if (selectedScribe?.audioId) {
      const onSuccess = () => {
        scribesValidTitles.current = scribes.map((scribe) => {
          if (scribe.audioId === selectedScribe.audioId) {
            return {
              ...scribe,
              title,
            };
          }

          return scribe;
        });
      };

      const onError = () => {
        const scribeWithValidTitle = scribesValidTitles.current.find(
          (scribe) => scribe.audioId === selectedScribe.audioId,
        );
        const defaultTitle = initialTitle(
          scribeWithValidTitle?.startedAt
            ? parseDateString(scribeWithValidTitle.startedAt) || new Date()
            : new Date(),
        );

        changeScribeTitle(scribeWithValidTitle?.title || defaultTitle);
      };

      changeScribeTitle(title);
      dispatch(
        saveEncounterTitle(
          selectedScribe.audioId,
          title || defaultTitle,
          onSuccess,
          onError,
        ),
      );
    }
  };

  useEffect(() => {
    if (scribes.length && !scribesValidTitles.current.length) {
      scribesValidTitles.current = scribes.map((scribe) => scribe);
    }
  }, [scribes]);

  return { onUpdateTitle };
};
