import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/chevron-down.svg";
import { ReactComponent as CrossIcon } from "../../../assets/icons/x-mark.svg";

const filter = createFilterOptions();

const AutocompleteMultiple = ({
  label,
  placeholder,
  options,
  className,
  value,
  setValue = () => {},
}) => {
  const PaperComponent = ({ children, ...props }) => {
    return (
      <Paper
        {...props}
        sx={{
          margin: "4px 0 0 0",
          padding: 0,
          borderRadius: "12px",
          "& > div > div > div": {
            padding: "0 !important",
          },
        }}
      >
        <div className="p-2 pr-1.5">{children}</div>
      </Paper>
    );
  };

  return (
    <div className={className}>
      {label && <p className="text-sm font-semibold mb-1.5">{label}</p>}

      <Autocomplete
        value={value}
        onChange={(_event, newValue) => {
          setValue(newValue);
        }}
        multiple
        freeSolo
        autoHighlight
        disableListWrap
        options={options}
        getOptionLabel={(option) => option.title}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          const isExisting = options.some(
            (option) => inputValue === option.title,
          );
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              title: inputValue,
            });
          }
          return filtered;
        }}
        PaperComponent={PaperComponent}
        ListboxProps={{
          className:
            "scrollbar font-Mulish text-sm font-semibold text-zinc-700",
        }}
        disableClearable
        popupIcon={<ArrowIcon width="16" height="16" stroke="#121212" />}
        sx={{
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: "1px solid #D1D5DB",
            },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            sx={{
              "& .MuiInputBase-root.MuiOutlinedInput-root": {
                borderRadius: "8px",
                fontWeight: "500",
                fontFamily: "Mulish, sans-serif",
                padding: "5px 38px 5px 8px",
                "&::placeholder": {
                  color: "#9ca3af",
                  opacity: 100,
                },
                "& fieldset": {
                  borderColor: "#D1D5DB",
                  "&:hover": {
                    borderColor: "#D1D5DB",
                  },
                },
              },
            }}
          />
        )}
        ChipProps={{
          sx: {
            borderRadius: "4px",
            fontSize: "14px",
            fontWeight: "500",
            fontFamily: "Mulish, sans-serif",
            color: "#667085",
            backgroundColor: "#EAECF0",
          },
          deleteIcon: <CrossIcon width="16" height="16" stroke="#A0A6B2" />,
        }}
      />
    </div>
  );
};

export default AutocompleteMultiple;
