import { Pages } from "../../../helpers/constants";
import { useAppDispatch } from "../../../store";
import { setInterruptedRecordingAction } from "../store/scribeSlice";
import { SetInterruptedRecordingActionType } from "../store/types";
import useEventListener from "./useEventListener";

const useNavigationGuard = (recordingStarted: boolean) => {
  const dispatch = useAppDispatch();

  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    if (!recordingStarted) {
      return;
    }

    event.preventDefault();
    dispatch(
      setInterruptedRecordingAction({
        type: SetInterruptedRecordingActionType.NAVIGATION,
        value: Pages.SCRIBE,
      }),
    );
  };

  const handlePopState = () => {
    if (!recordingStarted) {
      return;
    }

    dispatch(
      setInterruptedRecordingAction({
        type: SetInterruptedRecordingActionType.NAVIGATION,
        value: Pages.SCRIBE,
      }),
    );
  };

  useEventListener("popstate", handlePopState);
  useEventListener("beforeunload", handleBeforeUnload);
};

export default useNavigationGuard;
