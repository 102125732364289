import Tooltip from "@mui/material/Tooltip";

const TooltipMui = ({
  children,
  title,
  placement = "top",
  arrow,
  textColor = "#ffffff",
  bgColor = "#121212",
  ...props
}) => {
  return (
    <Tooltip
      title={title}
      placement={placement}
      arrow={arrow}
      {...props}
      componentsProps={{
        tooltip: {
          sx: {
            borderRadius: "8px",
            maxWidth: "212px",
            padding: "12px",
            color: textColor,
            backgroundColor: bgColor,
            fontFamily: "Mulish, sans-serif",
            fontSize: "12px",
            fontWeight: 600,
            "& .MuiTooltip-arrow": {
              color: bgColor,
            },
            "&.MuiTooltip-tooltipArrow": {
              marginBottom: "8px !important",
            },
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

export default TooltipMui;
