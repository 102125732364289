import { ReactComponent as PlusSmallIcon } from "../../../assets/icons/plus-small.svg";

const ScribeListFooter = ({
  setIsNewScribe,
}: {
  setIsNewScribe: () => void;
}) => {
  return (
    <footer
      className="fixed z-[2] bottom-0 left-0 md:left-[unset] flex px-0 w-full md:w-[268px] justify-center py-4
        bg-gray-background shadow-scribe"
    >
      <button
        type="button"
        onClick={setIsNewScribe}
        className="bg-primary-blue flex justify-center p-3 md:p-2 gap-3 mx-5 w-full text-white rounded-md font-semibold items-center"
      >
        <PlusSmallIcon width="12" height="12" stroke="#FFF" />
        New Encounter
      </button>
    </footer>
  );
};

export default ScribeListFooter;
