import Bugsnag from "@bugsnag/js";
import { useEffect } from "react";
import { useAppDispatch } from "../../../store";
import type { Scribe } from "../store/interfaces";
import { setScribePaused } from "../store/scribeSlice";

const useRecordingStatusWatcher = (
  recordingScribe: Scribe | null,
  mediaRecorderRef: React.RefObject<MediaRecorder | null>,
) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      if (recordingScribe && mediaRecorderRef.current?.state !== "recording") {
        console.warn("Media recorder out of sync, pausing");
        Bugsnag.notify(new Error("Media recorder out of sync, pausing"));
        dispatch(setScribePaused());
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [recordingScribe, mediaRecorderRef.current?.state, dispatch]);
};

export default useRecordingStatusWatcher;
