import { useTimerDisplay } from "../hooks/useTimerDisplay";

const TimerDisplay = ({ isRecording, startedAt, offset, className }) => {
  const { formattedTime } = useTimerDisplay(isRecording, startedAt, offset);

  return (
    <p className={`text-center md:m-1.5 font-semibold ${className}`}>
      {formattedTime}
    </p>
  );
};

export default TimerDisplay;
