import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { availableTags, frequency } from "../../helpers/constants";
import { getTimeAndUnit, insertTag } from "../../helpers/helpers";
import {
  getCampaign,
  updateCampaignControls,
} from "../../store/campaign/thunks";
import { setSettings } from "../../store/user/thunks";
import Button from "../Basic/Button";
import Input from "../Basic/Input";
import LoaderDots from "../Basic/LoaderDots";
import Select from "../Basic/Select";
import Switch from "../Basic/Switch";
import TextareaTags from "../Basic/TextareaTags";

const startDateOptions = [
  { text: "Now", value: "now" },
  { text: "Tomorrow", value: "tomorrow" },
  { text: "Next Week", value: "next_week" },
  { text: "Next Month", value: "next_month" },
];

const reminderTimesOptions = [
  { text: 1, value: 1 },
  { text: 2, value: 2 },
];

const reminderTimeUnits = [
  { text: "Minutes", value: "minutes" },
  { text: "Hours", value: "hours" },
  { text: "Days", value: "days" },
];

const timeUnits = [
  { value: "days", text: "Days" },
  { value: "hours", text: "Hours" },
  { value: "minutes", text: "Minutes" },
];

const ControlsTab = () => {
  const { selectedEnrollCampaign, loader } = useSelector(
    (state) => state.campaign,
  );
  const { user } = useSelector((state) => state.user);
  const { settings } = user;
  const { time_to_move_review, time_to_move_complete } = settings;

  const [reminderActive, setReminderActive] = useState(
    selectedEnrollCampaign?.reminder_active === 1,
  );
  const [selectedTimes, setSelectedTimes] = useState(
    selectedEnrollCampaign?.reminder_times,
  );
  const [disabled, setDisabled] = useState(true);
  const [reminderMessage, setReminderMessage] = useState(
    selectedEnrollCampaign?.reminder_message || "",
  );
  const reminderMessageRef = useRef(null);
  const dispatch = useDispatch();

  const getAbandonedTimeAndUnit = () => {
    if (!time_to_move_review) {
      return { value: 0, unit: "days" };
    }
    return getTimeAndUnit(time_to_move_review);
  };

  const getNoResponseTimeAndUnit = () => {
    if (!time_to_move_complete) {
      return { value: 0, unit: "days" };
    }
    return getTimeAndUnit(time_to_move_complete);
  };

  const defaultValues = {
    minMaxQuestions: [
      selectedEnrollCampaign?.min_questions,
      selectedEnrollCampaign?.max_questions,
    ],
    frequency: selectedEnrollCampaign?.default_frequency,
    startDate: selectedEnrollCampaign?.default_time_to_send,
    reminderTimeBetween: selectedEnrollCampaign
      ? getTimeAndUnit(selectedEnrollCampaign.first_followup).value
      : 0,
    reminderTimeUnit: selectedEnrollCampaign
      ? getTimeAndUnit(selectedEnrollCampaign.first_followup).unit
      : "minutes",
    abandonedTime: getAbandonedTimeAndUnit().value,
    abandonedTimeUnit: getAbandonedTimeAndUnit().unit,
    noResponseTime: getNoResponseTimeAndUnit().value,
    noResponseTimeUnit: getNoResponseTimeAndUnit().unit,
  };

  const {
    handleSubmit,
    register,
    reset,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
    setReminderActive(selectedEnrollCampaign?.reminder_active === 1);
    setSelectedTimes(selectedEnrollCampaign?.reminder_times);
    setReminderMessage(selectedEnrollCampaign?.reminder_message);
    setDisabled(true);
  }, [selectedEnrollCampaign]);

  const onSubmit = (data) => {
    let reminderMinutes = -data.reminderTimeBetween;
    if (data.reminderTimeUnit === "hours") {
      reminderMinutes = -data.reminderTimeBetween * 60;
    } else if (data.reminderTimeUnit === "days") {
      reminderMinutes = -data.reminderTimeBetween * 60 * 24;
    }

    let plainReminderMessage = reminderMessage;
    if (reminderMessageRef.current) {
      const content = reminderMessageRef.current.innerHTML;
      plainReminderMessage = content
        .replace(/<span[^>]*>([^<]+)<\/span>/g, (_, text) => {
          const tag = availableTags.find((tag) => tag.name === text);
          if (tag) {
            return tag.value;
          }
          return `{{${text.toUpperCase()}}}`;
        })
        .replace(/<\/div>\s*<div>/g, "\n")
        .replace(/<\/div>([^<]+)/g, "</div>\n$1")
        .replace(/([^<]+)<div>/g, "$1\n<div>")
        .replace(/<\/?[^>]+>/g, "");
    }

    setDisabled(true);

    dispatch(
      updateCampaignControls(
        selectedEnrollCampaign.campaign_id,
        data.minMaxQuestions[0],
        data.minMaxQuestions[1],
        data.startDate,
        data.frequency,
        reminderActive,
        plainReminderMessage,
        selectedTimes,
        reminderMinutes,
        () => dispatch(getCampaign(selectedEnrollCampaign.campaign_id)),
      ),
    );

    // messaging general settings
    let abandonedMinutes = data.abandonedTime;
    if (data.abandonedTimeUnit === "hours") {
      abandonedMinutes = data.abandonedTime * 60;
    } else if (data.abandonedTimeUnit === "days") {
      abandonedMinutes = data.abandonedTime * 60 * 24;
    }

    let noResponseMinutes = data.noResponseTime;
    if (data.noResponseTimeUnit === "hours") {
      noResponseMinutes = data.noResponseTime * 60;
    } else if (data.noResponseTimeUnit === "days") {
      noResponseMinutes = data.noResponseTime * 60 * 24;
    }

    dispatch(
      setSettings(
        {
          time_to_move_review: abandonedMinutes,
          time_to_move_complete: noResponseMinutes,
        },
        false,
      ),
    );
  };

  const onClickTag = (tag) => {
    if (reminderMessageRef.current) {
      setDisabled(false);
      const selection = window.getSelection();
      insertTag(selection, tag);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="space-y-6 md:max-w-xl text-sm border-gray-300"
    >
      <Select
        register={register}
        label="Default time to send"
        name="startDate"
        placeholder="Select default start date"
        options={startDateOptions}
      />
      <Select
        register={register}
        label="Default frequency"
        name="frequency"
        placeholder="Select default frequency"
        options={frequency}
      />

      {/* messaging general settings */}
      <div className="space-y-1.5">
        <p className="text-sm font-semibold">
          Move unfinished conversations to “Review” after
        </p>
        <div className="flex items-start space-x-6">
          <Input
            className="w-full"
            register={register}
            name="abandonedTime"
            placeholder="Number"
            type="number"
            step="1"
            min={1}
            error={errors.abandonedTime}
          />
          <Select
            className="w-full"
            placeholder="Select time unit"
            options={timeUnits}
            register={register}
            name="abandonedTimeUnit"
          />
        </div>
      </div>
      <div className="space-y-1.5">
        <p className="text-sm font-semibold">
          Move conversations with no response to “Complete” after
        </p>
        <div className="flex items-start space-x-6">
          <Input
            className="w-full"
            register={register}
            name="noResponseTime"
            placeholder="Number"
            type="number"
            step="1"
            min={1}
            error={errors.noResponseTime}
          />
          <Select
            className="w-full"
            placeholder="Select time unit"
            options={timeUnits}
            register={register}
            name="noResponseTimeUnit"
          />
        </div>
      </div>

      <div className="py-4">
        <hr />
      </div>

      <div className="flex items-center font-semibold justify-between gap-3">
        <p>Reminder message</p>
        <Switch
          size="medium"
          enabled={reminderActive}
          onChange={() => {
            setReminderActive(!reminderActive);
            setDisabled(false);
          }}
          disabled={!selectedEnrollCampaign}
        />
      </div>

      {reminderActive && (
        <>
          <div className="space-y-1.5 flex flex-col w-fit">
            <p className="font-semibold whitespace-nowrap">Available tags</p>
            <div className="flex flex-wrap gap-1.5">
              {availableTags.map((tag, index) => (
                <button
                  key={index}
                  type="button"
                  className="py-1 px-2 rounded-md text-xs w-fit bg-gray-140 hover:bg-gray-foreground
                    disabled:hover:bg-gray-200 disabled:bg-gray-200 whitespace-nowrap"
                  onClick={(e) => {
                    e.preventDefault();
                    onClickTag(tag);
                  }}
                >
                  {tag.name}
                </button>
              ))}
            </div>
          </div>

          <TextareaTags
            label="Message text"
            contentRef={reminderMessageRef}
            value={reminderMessage}
            onChange={() => setDisabled(false)}
          />

          <div className="flex gap-3">
            <p className="font-semibold">Times</p>
            {reminderTimesOptions.map((item) => (
              <label
                key={item.value}
                className="flex items-center cursor-pointer px-2.5"
                onClick={() => {
                  setSelectedTimes(item.value);
                  setDisabled(false);
                }}
              >
                <input type="radio" value={item.value} className="hidden" />
                <div
                  className={`w-4 h-4 rounded-full border flex flex-none items-center justify-center bg-white
                    ${selectedTimes === item.value ? "border-primary-blue" : "border-gray-300"}`}
                >
                  {selectedTimes === item.value && (
                    <span className="w-1.5 h-1.5 bg-primary-blue rounded-full" />
                  )}
                </div>
                {item.text && <p className="ml-2 truncate">{item.text}</p>}
              </label>
            ))}
          </div>

          <div className="space-y-1.5">
            <p className="font-semibold">Time in between reminder</p>
            <div className="flex items-start space-x-4">
              <Input
                className="w-full"
                register={register}
                name="reminderTimeBetween"
                placeholder="Enter minutes between reminders"
                type="number"
                step="1"
                min={{ value: 1, message: "Must be at least 1 minute" }}
                error={errors.reminderTimeBetween}
              />
              <Select
                className="w-full"
                register={register}
                name="reminderTimeUnit"
                placeholder="Select time unit"
                options={reminderTimeUnits}
              />
            </div>
          </div>
        </>
      )}

      <Button
        type="submit"
        variant="blue-light"
        className="w-36 text-base font-semibold ml-auto"
        disabled={
          loader || !selectedEnrollCampaign || disabled ? !isDirty : disabled
        }
      >
        {loader ? <LoaderDots /> : "Update"}
      </Button>
    </form>
  );
};

export default ControlsTab;
