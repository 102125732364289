import Bugsnag from "@bugsnag/js";
import axios, { type AxiosError } from "axios";
import type { z } from "zod";
import { BASE_URL, fetchWithAuth } from "../../../helpers/config";
import { sectionDetailLevels, sectionStyles } from "../../../helpers/constants";
import { setNotificationAction } from "../../../store/user/actions";
import { getUser, updateStoredUser } from "../../../store/user/thunks";
import { setProviderNotesLoadingAction } from "./actions";
import {
  setDefaultNoteTemplate,
  setNoteTemplates,
  setSelectedNoteTemplate,
} from "./noteTemplateSlice";
import { type FixLater, setScribeNoteContent, setScribes } from "./scribeSlice";
import {
  describedNoteTemplateSchema,
  noteContentSchema,
  noteTemplatesSchema,
  providerNotesSchema,
} from "./validationSchemas";

const formatZodValidationErrors = (validationErrors: z.ZodError["errors"]) => {
  return validationErrors.map((err) => ({
    path: err.path.join("."),
    message: err.message,
  }));
};

export const getProviderNotes = (providerId) => async (dispatch) => {
  try {
    dispatch(setProviderNotesLoadingAction(true));

    const data = await fetchWithAuth(
      `${BASE_URL}/v2/provider/${providerId}/notes/`,
    );

    const scribes = data.data.notes;

    const validationResult = providerNotesSchema.safeParse(scribes);
    if (!validationResult.success) {
      console.error(
        "Validation failed (getProviderNotes):",
        validationResult.error.errors,
      );

      const zodErrors = formatZodValidationErrors(
        validationResult.error.errors,
      );
      Bugsnag.notify(
        new Error("Zod validation error in getProviderNotes"),
        (event) => {
          event.addMetadata("validation", {
            errors: zodErrors,
          });
        },
      );
    }

    await dispatch(setScribes({ scribes }));
  } catch (error) {
    if (error instanceof TypeError) {
      console.error("Network error or JSON parsing failed:", error);
    } else {
      console.error("Failed to fetch provider notes:", error);
    }

    console.error("Failed to fetch provider notes:", error);
    dispatch(
      setNotificationAction({
        status: "error",
        title: "Something went wrong",
        desc: "Failed to get provider notes",
      }),
    );
    Bugsnag.notify(error);
  } finally {
    dispatch(setProviderNotesLoadingAction(false));
  }
};

export const getProviderNote = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const selectedAudioId = state.scribe.selectedAudioId;
    const data = await fetchWithAuth(`${BASE_URL}/note/${selectedAudioId}`);

    const noteContent = data.data.note;
    const validationResult = noteContentSchema.safeParse(noteContent);
    if (!validationResult.success) {
      console.error(
        "Validation failed (getProviderNote):",
        validationResult.error.errors,
      );

      const zodErrors = formatZodValidationErrors(
        validationResult.error.errors,
      );
      Bugsnag.notify(
        new Error("Zod validation error in getProviderNote"),
        (event) => {
          event.addMetadata("validation", {
            errors: zodErrors,
          });
        },
      );
    }

    await dispatch(
      setScribeNoteContent({
        audioId: selectedAudioId,
        noteContent: noteContent,
      }),
    );
  } catch (error) {
    console.error("Failed to fetch provider note:", error);
    dispatch(
      setNotificationAction({
        status: "error",
        title: "Something went wrong",
        desc: "Failed to load note content",
      }),
    );
    Bugsnag.notify(error);
  } finally {
    dispatch(setProviderNotesLoadingAction(false));
  }
};

export const getNoteTemplates = () => (dispatch) => {
  axios
    .get(`${BASE_URL}/note-template/list?show_hidden=1`)
    .then((response) => {
      if (response.data.error) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to load note templates",
          }),
        );
        console.error(response.data.error);
        Bugsnag.notify(response.data.error);
      } else {
        const noteTemplates = response.data;
        const validationResult = noteTemplatesSchema.safeParse(noteTemplates);
        if (!validationResult.success) {
          console.error(
            "Validation failed (getNoteTemplates):",
            validationResult.error.errors,
          );

          const zodErrors = formatZodValidationErrors(
            validationResult.error.errors,
          );
          Bugsnag.notify(
            new Error("Zod validation error in getNoteTemplates"),
            (event) => {
              event.addMetadata("validation", {
                errors: zodErrors,
              });
            },
          );
        }

        dispatch(setNoteTemplates(noteTemplates));
      }
    })
    .catch((error: Error | AxiosError) => {
      console.error(error);
      Bugsnag.notify(error);
    });
};

export const describeNoteTemplate =
  (noteTemplateId, onSuccess = (_data: FixLater) => {}) =>
  (dispatch) => {
    axios
      .get(`${BASE_URL}/note-template/describe/${noteTemplateId}`)
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to describe note template",
            }),
          );
          console.error(response.data.error);
          Bugsnag.notify(response.data.error);
        } else {
          const describedNoteTemplate = response.data;
          const validationResult = describedNoteTemplateSchema.safeParse(
            describedNoteTemplate,
          );
          if (!validationResult.success) {
            console.error(
              "Validation failed (describeNoteTemplate):",
              validationResult.error.errors,
            );

            const zodErrors = formatZodValidationErrors(
              validationResult.error.errors,
            );
            Bugsnag.notify(
              new Error("Zod validation error in describeNoteTemplate"),
              (event) => {
                event.addMetadata("validation", {
                  errors: zodErrors,
                });
              },
            );
          }

          onSuccess(describedNoteTemplate);
        }
      })
      .catch((error: Error | AxiosError) => {
        console.error(error);
        Bugsnag.notify(error);
      });
  };

export const selectDefaultNoteTemplate = (template) => (dispatch) => {
  axios
    .post(`${BASE_URL}/note-template/select/${template.note_template_id}`)
    .then((response) => {
      if (response.data.error) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to select note template",
          }),
        );
        console.error(response.data.error);
        Bugsnag.notify(response.data.error);
      } else {
        dispatch(
          describeNoteTemplate(template.note_template_id, (data) =>
            dispatch(setDefaultNoteTemplate(data)),
          ),
        );
        getUser().then((user) => dispatch(updateStoredUser(user)));
      }
    })
    .catch((error: Error | AxiosError) => {
      console.error(error);
      Bugsnag.notify(error);
    });
};

export const updateNoteTemplate =
  (
    noteTemplateId,
    newName,
    language,
    instructions,
    showIcd10,
    isHidden,
    onSuccess = () => {},
  ) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/note-template/update/${noteTemplateId}`, {
        name: newName, // text
        note_language: language, // text
        custom_instructions: instructions, // text
        show_icd10_codes_suggestions: showIcd10 ? 1 : 0, // boolean
        is_hidden: isHidden ? 1 : 0, // boolean
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to update note template",
            }),
          );
          console.error(response.data.error);
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(getNoteTemplates());
          onSuccess();
        }
      })
      .catch((error: Error | AxiosError) => {
        console.error(error);
        Bugsnag.notify(error);
      });
  };

export const updateTemplateSectionsOrder =
  (noteTemplateId, sections) => (dispatch) => {
    axios
      .post(
        `${BASE_URL}/note-template/update-sections-order/${noteTemplateId}`,
        sections, // [{note_template_section_id: number, order: number}]
      )
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to update template sections order",
            }),
          );
          console.error(response.data.error);
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(
            describeNoteTemplate(noteTemplateId, (data) =>
              dispatch(setSelectedNoteTemplate(data)),
            ),
          );
        }
      })
      .catch((error: Error | AxiosError) => {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to update template sections order",
          }),
        );
        console.error(error);
        Bugsnag.notify(error);
      });
  };

export const updateTemplateSection =
  (
    noteTemplateId,
    noteTemplateSectionId,
    newName,
    style,
    hideByDefault,
    subsections,
    detailLevel,
    splitByProblem,
    includeDifferential,
  ) =>
  (dispatch) => {
    axios
      .post(
        `${BASE_URL}/note-template/section/update/${noteTemplateSectionId}`,
        {
          name: newName, // text,
          section_style:
            style === "Bullet points" ? sectionStyles.BULLET : style, // enum('Auto','Bullet','Paragraph'),
          hide_by_default: hideByDefault ? 1 : 0, // boolean,
          subsections: subsections, // array,
          detail_level: detailLevel, // enum('High','Normal')
          split_by_problem: splitByProblem ? 1 : 0, // boolean,
          include_differential: includeDifferential ? 1 : 0, // boolean
        },
      )
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to update template section",
            }),
          );
          console.error(response.data.error);
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(
            describeNoteTemplate(noteTemplateId, (data) =>
              dispatch(setSelectedNoteTemplate(data)),
            ),
          );
        }
      })
      .catch((error: Error | AxiosError) => {
        console.error(error);
        Bugsnag.notify(error);
      });
  };

export const deleteTemplateSection =
  (noteTemplateId, noteTemplateSectionId) => (dispatch) => {
    axios
      .post(`${BASE_URL}/note-template/delete-section/${noteTemplateSectionId}`)
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to delete template section",
            }),
          );
          console.error(response.data.error);
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(
            describeNoteTemplate(noteTemplateId, (data) =>
              dispatch(setSelectedNoteTemplate(data)),
            ),
          );
        }
      })
      .catch((error: Error | AxiosError) => {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to delete template section",
          }),
        );
        console.error(error);
        Bugsnag.notify(error);
      });
  };

export const createTemplateSection =
  (noteTemplateId, sectionOrder) => (dispatch) => {
    axios
      .post(`${BASE_URL}/note-template/create-section/${noteTemplateId}`, {
        name: "New section",
        section_style: sectionStyles.AUTO,
        hide_by_default: 0,
        subsections: [],
        detail_level: sectionDetailLevels.NORMAL,
        split_by_problem: 0,
        include_differential: 0,
        order: sectionOrder,
        has_subsections: 0,
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to create template section",
            }),
          );
          console.error(response.data.error);
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(
            describeNoteTemplate(noteTemplateId, (data) =>
              dispatch(setSelectedNoteTemplate(data)),
            ),
          );
        }
      })
      .catch((error: Error | AxiosError) => {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to create template section",
          }),
        );
        console.error(error);
        Bugsnag.notify(error);
      });
  };

export const createTemplate =
  (
    templateName,
    noteLanguage,
    customInstructions,
    sections,
    onSuccess = () => {},
  ) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/note-template/create`, {
        template: {
          name: templateName,
          note_language: noteLanguage,
          custom_instructions: customInstructions,
        },
        sections: sections,
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to create note template",
            }),
          );
          console.error(response.data.error);
          Bugsnag.notify(response.data.error);
        } else {
          onSuccess();
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Success",
              desc: "Note template created successfully",
            }),
          );
          const createdNoteTemplate = response.data.data;
          const validationResult =
            describedNoteTemplateSchema.safeParse(createdNoteTemplate);
          if (!validationResult.success) {
            console.error(
              "Validation failed (createTemplate):",
              validationResult.error.errors,
            );

            const zodErrors = formatZodValidationErrors(
              validationResult.error.errors,
            );
            Bugsnag.notify(
              new Error("Zod validation error in createTemplate"),
              (event) => {
                event.addMetadata("validation", {
                  errors: zodErrors,
                });
              },
            );
          }

          dispatch(getNoteTemplates());
          dispatch(setSelectedNoteTemplate(createdNoteTemplate));
        }
      })
      .catch((error: Error | AxiosError) => {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to create note template",
          }),
        );
        console.error(error);
        Bugsnag.notify(error);
      });
  };

export const deleteTemplate =
  (noteTemplateId, onSuccess = () => {}) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/note-template/delete/${noteTemplateId}`)
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to delete note template",
            }),
          );
          console.error(response.data.error);
          Bugsnag.notify(response.data.error);
        } else {
          onSuccess();
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Success",
              desc: "Note template deleted successfully",
            }),
          );
          dispatch(getNoteTemplates());
        }
      })
      .catch((error: Error | AxiosError) => {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to delete note template",
          }),
        );
        console.error(error);
        Bugsnag.notify(error);
      });
  };
