import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { ReactComponent as ArrowDownIcon } from "../../assets/icons/arrow-down-thin.svg";
import { ReactComponent as ArrowUpIcon } from "../../assets/icons/arrow-small-up.svg";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow-triangle.svg";
import { ReactComponent as NoteIcon } from "../../assets/icons/billing-note.svg";
import { ReactComponent as ClockIcon } from "../../assets/icons/clock.svg";
import { ReactComponent as ExclamationIcon } from "../../assets/icons/exclamation-circle.svg";
import { ReactComponent as PenIcon } from "../../assets/icons/pen.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { reasonsToAddTime } from "../../helpers/constants";
import {
  getFormattedTimeColon,
  getFormattedTimeHMS,
} from "../../helpers/helpers";
import Button from "../Basic/Button";
import Loader from "../Basic/Loader";
import LoaderDots from "../Basic/LoaderDots";
import Select from "../Basic/Select";
import TextareaAutosizeForm from "../Basic/TextareaAutosizeForm";
import { TimeCptItem } from "./TimeCptItem";

const TimeEditing = ({
  billingType,
  billingPatient,
  combinedStaffData,
  additionalTime,
  setAdditionalTime,
  breakdownData,
  setBreakdownData,
  staffAdjusted,
  setStaffAdjusted,
  editableAdjustments,
  adjustmentsToRemove,
  setAdjustmentsToRemove,
  className,
  editMode = true,
  onEditTime = () => {},
  onClickNote = () => {},
}) => {
  const { user } = useSelector((state) => state.user);
  const [openBreakdown, setOpenBreakdown] = useState(false);
  const [openAdditionalTime, setOpenAdditionalTime] = useState(false);
  const [errorMoreTime, setErrorMoreTime] = useState(null);
  const [reason, setReason] = useState(reasonsToAddTime[0].value);
  const [reasonOther, setReasonOther] = useState("");
  const [time, setTime] = useState("00:00");
  const [timeValue, setTimeValue] = useState(dayjs().startOf("day"));
  const [groupedAdditionalTime, setGroupedAdditionalTime] = useState(null);
  const [openedDoctors, setOpenedDoctors] = useState([]);

  useEffect(() => {
    setAdditionalTime(0);
    setTime("00:00");
    setTimeValue(dayjs().startOf("day"));
    setReasonOther("");
    setReason(reasonsToAddTime[0].value);
  }, [editMode]);

  useEffect(() => {
    const [minutes, seconds] = time.split(":").map(Number);
    const sec = minutes * 60 + seconds;
    setAdditionalTime(sec);
  }, [time]);

  useEffect(() => {
    if (billingPatient?.staff) {
      const grouped = {};

      billingPatient.staff.forEach((item) => {
        const { doctor_id, first_name, last_name, seconds } = item;
        if (item.reason) {
          if (!grouped[doctor_id]) {
            grouped[doctor_id] = {
              doctor_id: doctor_id,
              first_name: first_name,
              last_name: last_name,
              items: [],
              total: 0,
            };
          }
          grouped[doctor_id].items.push(item);
          grouped[doctor_id].total += seconds;
        }
      });

      staffAdjusted.forEach((item) => {
        const {
          doctorId,
          secondsAdjusted,
          reason,
          monthly_bill_staff_adjustment_id,
        } = item;
        if (!grouped[doctorId]) {
          grouped[doctorId] = {
            doctor_id: doctorId,
            first_name: user.first_name,
            last_name: user.last_name,
            items: [],
            total: 0,
          };
        }
        grouped[doctorId].items.push({
          reason,
          seconds: secondsAdjusted,
          monthly_bill_staff_adjustment_id: monthly_bill_staff_adjustment_id,
          doctor_id: doctorId,
        });
        grouped[doctorId].total += secondsAdjusted;
      });

      setGroupedAdditionalTime(Object.values(grouped));
    }
  }, [billingPatient?.staff, editMode]);

  const getTotalWithAdditionalSeconds = () => {
    if (!breakdownData || !groupedAdditionalTime) {
      return 0;
    }
    return (
      breakdownData.reduce((acc, curr) => acc + curr.seconds, 0) +
      groupedAdditionalTime.reduce((acc, curr) => acc + curr.total, 0)
    );
  };

  const handleIncrementSeconds = (doctorId) => {
    setBreakdownData((prevBreakdownData) => {
      return prevBreakdownData.map((doctor) => {
        if (doctor.doctor_id === doctorId) {
          return { ...doctor, seconds: doctor.seconds + 1 };
        }
        return doctor;
      });
    });
  };

  const handleDecrementSeconds = (doctorId) => {
    setErrorMoreTime(null);
    setBreakdownData((prevBreakdownData) => {
      return prevBreakdownData.map((doctor) => {
        if (doctor.doctor_id === doctorId && doctor.seconds > 0) {
          return { ...doctor, seconds: doctor.seconds - 1 };
        }
        return doctor;
      });
    });
  };

  const isLessThanInitialTime = (doctor) => {
    if (combinedStaffData) {
      const initial = combinedStaffData.find((doc) => doc.name === doctor.name);
      return doctor.seconds < initial.seconds;
    }
  };

  const onAddAdditionalTime = () => {
    const newId = uuid();
    setStaffAdjusted([
      ...staffAdjusted,
      {
        monthly_bill_staff_adjustment_id: newId,
        doctorId: user.doctor_id,
        secondsAdjusted: Number(additionalTime),
        reason:
          reason === "Other"
            ? reasonOther.trim()
              ? reasonOther.trim()
              : "Other"
            : reason,
      },
    ]);
    setGroupedAdditionalTime((prevGrouped) => {
      const doctor = prevGrouped.find(
        (staff) => staff.doctor_id === user.doctor_id,
      );
      if (doctor) {
        return prevGrouped.map((staff) => {
          if (staff.doctor_id === user.doctor_id) {
            return {
              ...staff,
              items: [
                ...staff.items,
                {
                  reason:
                    reason === "Other"
                      ? reasonOther.trim()
                        ? reasonOther.trim()
                        : "Other"
                      : reason,
                  seconds: additionalTime,
                  monthly_bill_staff_adjustment_id: newId,
                  doctor_id: user.doctor_id,
                },
              ],
              total: staff.total + additionalTime,
            };
          }
          return staff;
        });
      }
      return [
        ...prevGrouped,
        {
          doctor_id: user.doctor_id,
          first_name: user.first_name,
          last_name: user.last_name,
          items: [
            {
              reason:
                reason === "Other"
                  ? reasonOther.trim()
                    ? reasonOther.trim()
                    : "Other"
                  : reason,
              seconds: additionalTime,
              monthly_bill_staff_adjustment_id: newId,
              doctor_id: user.doctor_id,
            },
          ],
          total: additionalTime,
        },
      ];
    });
    setAdditionalTime(0);
    setTime("00:00");
    setTimeValue(dayjs().startOf("day"));
    setReasonOther("");
    setReason(reasonsToAddTime[0].value);
  };

  const onRemoveAdditionalTime = (removed) => {
    if (
      staffAdjusted.find(
        (staff) =>
          staff.monthly_bill_staff_adjustment_id ===
          removed.monthly_bill_staff_adjustment_id,
      )
    ) {
      setStaffAdjusted(
        staffAdjusted.filter(
          (staff) =>
            staff.monthly_bill_staff_adjustment_id !==
            removed.monthly_bill_staff_adjustment_id,
        ),
      );
    } else {
      setAdjustmentsToRemove([
        ...adjustmentsToRemove,
        {
          monthlyBillStaffAdjustmentId:
            removed.monthly_bill_staff_adjustment_id,
          totalSecondsAdjusted: removed.seconds,
        },
      ]);
    }

    const doctor = groupedAdditionalTime.find(
      (staff) => staff.doctor_id === removed.doctor_id,
    );
    const filteredItems = doctor.items.filter(
      (item) =>
        item.monthly_bill_staff_adjustment_id !==
        removed.monthly_bill_staff_adjustment_id,
    );

    setGroupedAdditionalTime(
      groupedAdditionalTime.map((staff) => {
        if (staff.doctor_id === removed.doctor_id) {
          return {
            ...staff,
            items: filteredItems,
            total: staff.total - removed.seconds,
          };
        }
        return staff;
      }),
    );
  };

  const openCloseDoctor = (doctorId) => {
    if (openedDoctors.includes(doctorId)) {
      setOpenedDoctors(openedDoctors.filter((id) => id !== doctorId));
    } else {
      setOpenedDoctors([...openedDoctors, doctorId]);
    }
  };

  return (
    <div className={`grid h-full w-full ${className}`}>
      {billingPatient !== null ? (
        <div>
          <div className="space-y-3 rounded-lg bg-gray-110 p-4 border border-gray-foreground mb-4 text-sm">
            <div className="flex items-center justify-between">
              <p className="text-base font-semibold">Total Time</p>
              <div
                className="text-sm font-semibold h-7 w-fit px-2.5 flex items-center justify-center
                        space-x-1.5 bg-white rounded-full"
              >
                <ClockIcon width="16" height="16" stroke="#121212" />
                <p>{getFormattedTimeColon(getTotalWithAdditionalSeconds())}</p>
              </div>
            </div>

            {billingType === "CCM" && (
              <div className="space-y-3">
                <TimeCptItem
                  cptMinutes={20}
                  totalSeconds={getTotalWithAdditionalSeconds()}
                  campaignType={billingType}
                />
                <TimeCptItem
                  cptMinutes={40}
                  totalSeconds={getTotalWithAdditionalSeconds()}
                  campaignType={billingType}
                />
                <TimeCptItem
                  cptMinutes={60}
                  totalSeconds={getTotalWithAdditionalSeconds()}
                  campaignType={billingType}
                />
              </div>
            )}
            {billingType === "PCM" && (
              <div className="space-y-3">
                <TimeCptItem
                  cptMinutes={30}
                  totalSeconds={getTotalWithAdditionalSeconds()}
                  campaignType={billingType}
                />
                <TimeCptItem
                  cptMinutes={60}
                  totalSeconds={getTotalWithAdditionalSeconds()}
                  campaignType={billingType}
                />
                <TimeCptItem
                  cptMinutes={90}
                  totalSeconds={getTotalWithAdditionalSeconds()}
                  campaignType={billingType}
                />
              </div>
            )}
            {billingType === "BHI" && (
              <TimeCptItem
                cptMinutes={20}
                totalSeconds={getTotalWithAdditionalSeconds()}
                campaignType={billingType}
              />
            )}
          </div>

          <div className="rounded-t-lg bg-gray-110 p-4 border border-b-0 border-gray-foreground text-sm">
            <div className="flex items-center justify-between font-semibold">
              <button
                type="button"
                className="flex items-center space-x-2 text-base"
                onClick={() => setOpenBreakdown(!openBreakdown)}
              >
                <p>Time Logged</p>
                <ArrowDownIcon
                  width="12"
                  height="8"
                  className={`transition-all
                    ${openBreakdown ? "-rotate-180" : ""}`}
                />
              </button>

              <p className="text-sm">
                {breakdownData
                  ? getFormattedTimeHMS(
                      breakdownData.reduce(
                        (acc, curr) => acc + curr.seconds,
                        0,
                      ),
                    )
                  : "0s"}
              </p>
            </div>
            {openBreakdown && (
              <div
                className={`space-y-2
                  ${breakdownData.length > 0 ? "mt-3" : ""} `}
              >
                {breakdownData ? (
                  breakdownData.map((doctor, index) => (
                    <div key={index} className="font-semibold text-sm">
                      <div className="flex justify-between">
                        <p>
                          {doctor.first_name} {doctor.last_name}
                        </p>
                        <div className="flex items-center space-x-2 text-tertiary">
                          <p>{getFormattedTimeColon(doctor.seconds)}</p>
                          {editMode && (
                            <div className="flex flex-col">
                              <button
                                type="button"
                                className="w-2.5 h-2.5 flex items-center justify-center relative"
                                onClick={() => {
                                  if (isLessThanInitialTime(doctor)) {
                                    handleIncrementSeconds(doctor.doctor_id);
                                  } else {
                                    setErrorMoreTime(doctor.doctor_id);
                                    setTimeout(() => {
                                      setErrorMoreTime(null);
                                    }, 4000);
                                  }
                                }}
                              >
                                <ArrowIcon
                                  width="8"
                                  height="6"
                                  fill={
                                    isLessThanInitialTime(doctor)
                                      ? "#2970FF"
                                      : "#AAAAAA"
                                  }
                                />
                              </button>
                              <button
                                type="button"
                                disabled={doctor.seconds <= 0}
                                className="w-2.5 h-2.5 flex items-center justify-center"
                                onClick={() =>
                                  handleDecrementSeconds(doctor.doctor_id)
                                }
                              >
                                <ArrowIcon
                                  width="8"
                                  height="6"
                                  fill={
                                    doctor.seconds <= 0 ? "#AAAAAA" : "#2970FF"
                                  }
                                  className="rotate-180"
                                />
                              </button>
                            </div>
                          )}
                        </div>
                      </div>

                      {/*{errorMoreTime === doctor.doctor_id && (*/}
                      {/*  <p className="text-rose-550 text-xs font-medium pt-0.5">*/}
                      {/*    This was the maximum time our system recorded, you*/}
                      {/*    cannot add any more time.*/}
                      {/*  </p>*/}
                      {/*)}*/}
                    </div>
                  ))
                ) : (
                  <div className="w-full flex justify-center">
                    <LoaderDots />
                  </div>
                )}
              </div>
            )}
          </div>

          {errorMoreTime && (
            <p className="w-full text-rose-550 bg-red-50 text-xs font-medium py-1.5 px-4 border border-t-0 border-gray-foreground">
              This was the maximum time our system recorded, you cannot add any
              more time.
            </p>
          )}

          <div className="pt-4 rounded-b-lg border border-t-0 border-gray-foreground text-sm">
            <div className="px-4 flex items-start justify-between space-x-4 whitespace-normal font-semibold">
              <button
                type="button"
                className="flex items-start space-x-2 text-base"
                onClick={() => setOpenAdditionalTime(!openAdditionalTime)}
              >
                <p className="text-left">Additional Outside Time</p>
                <ArrowDownIcon
                  width="12"
                  height="8"
                  className={`transition-all mt-2 flex-none
                   ${openAdditionalTime ? "-rotate-180" : ""}`}
                />
              </button>

              <p className="text-sm">
                {groupedAdditionalTime
                  ? getFormattedTimeHMS(
                      groupedAdditionalTime.reduce(
                        (acc, curr) => acc + curr.total,
                        0,
                      ),
                    )
                  : "0s"}
              </p>
            </div>

            {openAdditionalTime && (
              <div
                className={`space-y-2 px-4 w-full overflow-x-hidden
                  ${groupedAdditionalTime?.length > 0 ? "mt-3" : ""}`}
              >
                {groupedAdditionalTime?.length > 0 && (
                  <div className="space-y-2">
                    {groupedAdditionalTime.map((doctor) => (
                      <div key={doctor.doctor_id} className="space-y-2">
                        <div className="flex items-start justify-between space-x-4 whitespace-normal font-semibold">
                          <button
                            type="button"
                            className="flex items-center space-x-2"
                            onClick={() => openCloseDoctor(doctor.doctor_id)}
                          >
                            <p>
                              {doctor.first_name} {doctor.last_name}
                            </p>
                            <ArrowDownIcon
                              width="10"
                              height="7"
                              className={`transition-all
                                ${openedDoctors.includes(doctor.doctor_id) ? "-rotate-180" : ""}`}
                            />
                          </button>
                          <p>{getFormattedTimeColon(doctor.total)}</p>
                        </div>
                        {openedDoctors.includes(doctor.doctor_id) && (
                          <div className="space-y-2 pl-2">
                            {doctor.items.map((staff, index) => (
                              <div
                                key={index}
                                className="grid grid-cols-conv-layout items-center gap-2"
                              >
                                <p className="whitespace-pre-wrap truncate">
                                  {staff.reason}
                                </p>
                                <div className="flex items-center justify-end space-x-2">
                                  <p>{getFormattedTimeColon(staff.seconds)}</p>
                                  {editableAdjustments && editMode && (
                                    <button
                                      type="button"
                                      onClick={() =>
                                        onRemoveAdditionalTime(staff)
                                      }
                                    >
                                      <TrashIcon
                                        width="17"
                                        height="17"
                                        className="flex-none"
                                      />
                                    </button>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}

            {editMode ? (
              <div className="px-4 pb-4 flex flex-col lg:flex-row items-start gap-2 text-sm font-semibold mt-3">
                <div className="w-full space-y-2 lg:space-y-3">
                  <Select
                    className="w-full"
                    selectClassName="h-12 w-full"
                    options={reasonsToAddTime}
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  />
                  {reason === "Other" && (
                    <TextareaAutosizeForm
                      placeholder="Describe activity"
                      value={reasonOther}
                      onChange={(e) => setReasonOther(e.target.value)}
                      minRows={2}
                    />
                  )}
                </div>

                <div className="ml-auto relative">
                  <TimePicker
                    views={["minutes", "seconds"]}
                    format="mm:ss"
                    value={timeValue}
                    onChange={(newValue) => {
                      setTimeValue(newValue);
                      setTime(newValue.format("mm:ss"));
                    }}
                    sx={{
                      ".MuiInputAdornment-root": {
                        display: "none",
                      },
                      "& .MuiOutlinedInput-root": {
                        height: "48px",
                        width: "124px",
                        paddingRight: "48px",
                        input: {
                          padding: "0 0 0 14px",
                          fontSize: "14px",
                          lineHeight: "14px",
                          fontWeight: "500",
                          fontFamily: "Mulish, sans-serif",
                          "&::placeholder": {
                            color: "#9ca3af",
                            opacity: 100,
                          },
                        },
                        fieldset: {
                          borderRadius: "8px",
                          borderColor: "rgb(209 213 219)",
                        },
                        "&:hover fieldset": {
                          borderColor: "rgb(209 213 219)",
                        },
                        "&.Mui-focused": {
                          fieldset: {
                            borderColor: "rgb(209 213 219)",
                            borderWidth: "1px",
                          },
                        },
                        "&.Mui-error": {
                          fieldset: {
                            borderColor: "rgb(248 113 113)",
                          },
                        },
                        "&.Mui-disabled": {
                          backgroundColor: "#EDEDED",
                          fieldset: {
                            borderColor: "rgb(209 213 219)",
                          },
                        },
                      },
                      input: {
                        "&.Mui-disabled": {
                          WebkitTextFillColor: "#121212",
                          opacity: 1,
                        },
                      },
                    }}
                  />
                  <Button
                    className="absolute right-1 top-1 h-10 w-10"
                    size="unset"
                    disabled={additionalTime <= 0}
                    onClick={(e) => {
                      e.preventDefault();
                      onAddAdditionalTime();
                    }}
                  >
                    <ArrowUpIcon
                      width="20"
                      height="20"
                      fill={additionalTime <= 0 ? "#91969D" : "white"}
                    />
                  </Button>
                </div>
              </div>
            ) : (
              <div className="mt-4 px-4 py-3 bg-gray-110 flex items-center justify-between">
                {billingPatient.noteStatus === "generating" ||
                billingPatient.noteStatus === "not_enough" ||
                billingPatient.noteStatus === "generated" ? (
                  <Button
                    variant="gray"
                    size="unset"
                    onClick={onClickNote}
                    className="space-x-2 text-sm font-semibold h-8 px-3"
                  >
                    <p>Care Summary</p>

                    {billingPatient.noteStatus === "generating" ? (
                      <Loader size={4} borderWidth={2} />
                    ) : billingPatient.note &&
                      billingPatient.noteStatus === "not_enough" ? (
                      <ExclamationIcon
                        width="20"
                        height="20"
                        className="flex-none"
                      />
                    ) : (
                      billingPatient.note && (
                        <NoteIcon
                          width="20"
                          height="20"
                          className="flex-none"
                        />
                      )
                    )}
                  </Button>
                ) : (
                  <p className="text-base font-semibold">Add or Modify Time</p>
                )}
                <button
                  type="button"
                  onClick={onEditTime}
                  className="w-8 h-8 rounded-lg border border-gray-foreground bg-white flex items-center justify-center"
                >
                  <PenIcon width="16" height="16" />
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="w-full flex items-center justify-center">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default TimeEditing;
