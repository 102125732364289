import { useEffect, useRef } from "react";

const useMediaRecorderData = (
  mediaRecorderRef: React.RefObject<MediaRecorder | null>,
  sendAudioPart: (blob: Blob, chunkPosition: number) => void,
) => {
  const chunkPositionRef = useRef(0);
  const emptyChunksCountRef = useRef(0);

  useEffect(() => {
    const mediaRecorder = mediaRecorderRef.current;
    if (!mediaRecorder) {
      return;
    }

    chunkPositionRef.current = 0;
    emptyChunksCountRef.current = 0;

    const handleDataAvailable = (event: BlobEvent) => {
      chunkPositionRef.current++;

      if (event.data.size === 0) {
        emptyChunksCountRef.current++;
        console.error(
          `Received empty chunk ${chunkPositionRef.current}. Total empty chunks: ${emptyChunksCountRef.current}`,
        );

        return;
      }

      // const reader = new FileReader();
      // reader.onloadend = () => {
      //   const buffer = reader.result;
      //   const bytes = new Uint8Array(buffer as any);
      //   const hexBytes = Array.from(bytes.slice(0, 4))
      //     .map((b) => b.toString(16).padStart(2, "0"))
      //     .join(" ");
      //   console.log("First 4 bytes:", hexBytes); // Should be "1a 45 df a3"
      // };
      // reader.readAsArrayBuffer(event.data);

      sendAudioPart(event.data, chunkPositionRef.current);
    };

    const resetChunkPosition = () => {
      chunkPositionRef.current = 0;
      emptyChunksCountRef.current = 0;
    };

    // add a listener for pauses / stops to reset the chunk position
    mediaRecorder.addEventListener("pause", resetChunkPosition);
    mediaRecorder.addEventListener("stop", resetChunkPosition);

    mediaRecorder.addEventListener("dataavailable", handleDataAvailable);

    return () => {
      mediaRecorder.removeEventListener("dataavailable", handleDataAvailable);
      mediaRecorder.removeEventListener("pause", resetChunkPosition);
      mediaRecorder.removeEventListener("stop", resetChunkPosition);
    };
  }, [mediaRecorderRef.current, sendAudioPart]);
};

export default useMediaRecorderData;
