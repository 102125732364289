import Button from "../../Basic/Button";
import UserPlusRoundedIcon from "../UserPlusRoundedIcon";

const ScribeUnassignedPatient = ({
  setShowAssignPatientModal,
  className = "",
}: {
  setShowAssignPatientModal: (show: boolean) => void;
  className?: string;
}) => {
  return (
    <Button
      className={`whitespace-nowrap font-semibold text-primary-blue bg-primary-blue-light gap-3 ${className}`}
      variant="unknown"
      size="small2"
      onClick={() => setShowAssignPatientModal(true)}
    >
      <UserPlusRoundedIcon stroke="#2970FF" />
      <p className="text-base">Assign Patient</p>
    </Button>
  );
};

export default ScribeUnassignedPatient;
