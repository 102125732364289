const LoaderDotsSmall = ({
  color = "primary-blue",
  size = "1.5",
  className = "",
}) => {
  const dotStyle = `h-${size} w-${size} rounded-full bg-${color} scale-50 animate-scaling`;

  return (
    <div className={`space-x-1 flex items-center ${className}`}>
      <div className={dotStyle} />
      <div className={dotStyle} style={{ animationDelay: "200ms" }} />
      <div className={dotStyle} style={{ animationDelay: "400ms" }} />
    </div>
  );
};

export default LoaderDotsSmall;
