import { useMediaQuery } from "react-responsive";
import ModalMobile from "../../../Basic/ModalMobile";
import Modal from "../../../Basic/Modal";
import EditUserForm from "./EditUserForm";

const EditUserModal = ({ open, onClose, user }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return isDesktop ? (
    open && user && (
      <Modal className="pt-5 rounded-2xl">
        <div className="flex flex-col grow md:grow-0 sm:w-114 overflow-hidden">
          <p className="text-base md:text-xl font-semibold mb-5 px-5">
            Edit User
          </p>
          <EditUserForm onClose={onClose} selectedUser={user} />
        </div>
      </Modal>
    )
  ) : (
    <ModalMobile
      open={open}
      header="Edit User"
      childHeight={470}
      onClickAway={onClose}
      className=""
    >
      {user && open && (
        <EditUserForm onClose={onClose} selectedUser={user} className="pt-2" />
      )}
    </ModalMobile>
  );
};

export default EditUserModal;
