import { useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import Chat from "./Chat";
import InputArea from "./InputArea";
import TopBar from "./TopBar";

const Conversation = ({ messages, setMessages, scrolledUp, setScrolledUp }) => {
  const [showResponse, setShowResponse] = useState(false);
  const { currentConversation, currentConversationId } = useSelector(
    (state) => state.conversation,
  );
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isHeightSm = useMediaQuery({ minHeight: 640 });

  return (
    <div className="h-full w-full bg-gray-background grid grid-rows-chat-layout">
      <div
        className={`${isDesktop && isHeightSm ? "pt-4 px-4" : "md:pl-4 md:pt-2 md:pr-2"}`}
      >
        <TopBar />
      </div>
      <Chat
        scrolledUp={scrolledUp}
        setScrolledUp={setScrolledUp}
        showResponse={showResponse}
        currentConversation={currentConversation}
        currentConversationId={currentConversationId}
      />
      <InputArea
        setScrolledUp={setScrolledUp}
        messages={messages}
        setMessages={setMessages}
        showResponse={showResponse}
        setShowResponse={setShowResponse}
        currentConversation={currentConversation}
        currentConversationId={currentConversationId}
        className={isDesktop && !isHeightSm ? "ml-4 mr-2 mb-2 border" : ""}
        completeClassName="bg-white md:bg-transparent"
      />
    </div>
  );
};

export default Conversation;
