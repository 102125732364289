export const Languages = [
  { value: "en", text: "English" },
  //  { value: "fr", text: "French" },
  //  { value: "es", text: "Spanish" },
  //  { value: "zh", text: "Chinese" },
  //  { value: "auto_detect", text: "Auto-Detect" },
];

export const SpeakingLanguages = [
  { value: "auto_detect", text: "Auto-Detect" },
];
