import {
  LOGOUT,
  SET_USER,
  SET_USERS,
  SET_ROLES,
  SET_ERROR,
  SET_NOTIFICATION,
  OPEN_SUCCESS_MODAL,
  SET_ASSISTANTS,
  SET_SIGNUP_INFO,
  SET_DOCTOR_OPTIONS,
  SET_SELECTED_CARE_MANAGEMENT_TAB,
  SET_SELECTED_SETTINGS_TAB,
  SET_SELECTED_ORGANIZATION_TAB,
  SET_USERS_LOADING,
  SET_USER_SEARCH_VALUE,
  SET_USERS_WITHOUT_TEAM,
  SET_TEAMS,
  SET_TEAM_SEARCH_VALUE,
  SET_TEAMS_LOADING,
  SET_ROLE_OPTIONS,
  SET_ONLY_SCRIBE_FEATURE,
  SET_SELECTED_NOTES_TAB,
} from "../types";

export function logoutAction() {
  return {
    type: LOGOUT,
  };
}

export function setUserAction(user) {
  return {
    type: SET_USER,
    payload: {
      user: user,
    },
  };
}

export function setUsersAction(users) {
  return {
    type: SET_USERS,
    payload: {
      users: users,
    },
  };
}

export function setRolesAction(roles) {
  return {
    type: SET_ROLES,
    payload: {
      roles: roles,
    },
  };
}

export function setRoleOptionsAction(roleOptions) {
  return {
    type: SET_ROLE_OPTIONS,
    payload: {
      roleOptions: roleOptions,
    },
  };
}

export function setErrorAction(errorMessage) {
  return {
    type: SET_ERROR,
    payload: {
      errorMessage: errorMessage,
    },
  };
}

export function setNotificationAction(notification) {
  return {
    type: SET_NOTIFICATION,
    payload: {
      notification: notification,
    },
  };
}

export function setOpenSuccessModalAction(successModal) {
  return {
    type: OPEN_SUCCESS_MODAL,
    payload: {
      successModal: successModal,
    },
  };
}

export function setAssistantsAction(assistants) {
  return {
    type: SET_ASSISTANTS,
    payload: {
      assistants: assistants,
    },
  };
}

export function setSignupInfoAction(signupInfo) {
  return {
    type: SET_SIGNUP_INFO,
    payload: {
      signupInfo: signupInfo,
    },
  };
}

export function setDoctorOptionsAction(doctorOptions) {
  return {
    type: SET_DOCTOR_OPTIONS,
    payload: {
      doctorOptions: doctorOptions,
    },
  };
}

export function setSelectedCareManagementTabAction(selectedCareManagementTab) {
  return {
    type: SET_SELECTED_CARE_MANAGEMENT_TAB,
    payload: {
      selectedCareManagementTab: selectedCareManagementTab,
    },
  };
}

export function setSelectedSettingsTabAction(selectedSettingsTab) {
  return {
    type: SET_SELECTED_SETTINGS_TAB,
    payload: {
      selectedSettingsTab: selectedSettingsTab,
    },
  };
}

export function setSelectedOrganizationTabAction(selectedOrganizationTab) {
  return {
    type: SET_SELECTED_ORGANIZATION_TAB,
    payload: {
      selectedOrganizationTab: selectedOrganizationTab,
    },
  };
}

export function setSelectedNotesTabAction(selectedNotesTab) {
  return {
    type: SET_SELECTED_NOTES_TAB,
    payload: {
      selectedNotesTab: selectedNotesTab,
    },
  };
}

export function setUsersLoadingAction(loading) {
  return {
    type: SET_USERS_LOADING,
    payload: {
      loading: loading,
    },
  };
}

export function setTeamsLoadingAction(loading) {
  return {
    type: SET_TEAMS_LOADING,
    payload: {
      loading: loading,
    },
  };
}

export function setUserSearchValueAction(userSearchValue) {
  return {
    type: SET_USER_SEARCH_VALUE,
    payload: {
      userSearchValue: userSearchValue,
    },
  };
}

export function setTeamSearchValueAction(teamSearchValue) {
  return {
    type: SET_TEAM_SEARCH_VALUE,
    payload: {
      teamSearchValue: teamSearchValue,
    },
  };
}

export function setUsersWithoutTeamAction(usersWithoutTeam) {
  return {
    type: SET_USERS_WITHOUT_TEAM,
    payload: {
      usersWithoutTeam: usersWithoutTeam,
    },
  };
}

export function setTeamsAction(teams) {
  return {
    type: SET_TEAMS,
    payload: {
      teams: teams,
    },
  };
}

export function setOnlyScribeFeatureAction(onlyScribeFeature) {
  return {
    type: SET_ONLY_SCRIBE_FEATURE,
    payload: {
      onlyScribeFeature: onlyScribeFeature,
    },
  };
}
