import { useEffect, useState } from "react";
import dayjs from "dayjs";
import CustomAccordion from "../../Basic/CustomAccordion";
import TabsBg from "../../Basic/TabsBg";
import ItemCard from "./ItemCard";

export const DiagnosisTabs = {
  PRIMARY: "Primary",
  SECONDARY: "Secondary",
};

const containerClasses = "bg-white p-4 border rounded-lg";
const blockClasses = "space-y-3";

const EmptyData = ({ title, desc }) => (
  <div className="w-full flex flex-col items-center space-y-1 py-9">
    <p className="text-xl font-semibold">{title}</p>
    <p className="text-sm font-medium text-tertiary">{desc}</p>
  </div>
);

const HistoryTab = ({ patientInfo }) => {
  const { PRIMARY } = DiagnosisTabs;
  const [selectedDiagnosisTab, setSelectedDiagnosisTab] = useState(PRIMARY);
  const [primaryDiagnosis, setPrimaryDiagnosis] = useState([]);
  const [secondaryDiagnosis, setSecondaryDiagnosis] = useState([]);
  const [displayedDiagnosis, setDisplayedDiagnosis] = useState([]);

  useEffect(() => {
    if (patientInfo.diagnosis?.length > 0) {
      const primary = patientInfo.diagnosis.filter((diagn) => diagn.is_primary);
      const secondary = patientInfo.diagnosis.filter(
        (diagn) => !diagn.is_primary,
      );

      setPrimaryDiagnosis(primary);
      setSecondaryDiagnosis(secondary);
    }
  }, [patientInfo.diagnosis, setPrimaryDiagnosis, setSecondaryDiagnosis]);

  useEffect(() => {
    if (selectedDiagnosisTab === PRIMARY) {
      setDisplayedDiagnosis(primaryDiagnosis);
    } else {
      setDisplayedDiagnosis(secondaryDiagnosis);
    }
  }, [selectedDiagnosisTab, primaryDiagnosis, secondaryDiagnosis]);

  const formatDate = (date) => {
    return dayjs(`${date.replace(/-/g, "/")} GMT+0`).format("MM-DD-YYYY");
  };

  return (
    <div className="space-y-3 pb-24 md:pb-0">
      <CustomAccordion
        title={`Diagnosis ${patientInfo.diagnosis?.length > 0 ? `(${patientInfo.diagnosis.length})` : ""}`}
        className={containerClasses}
        openByDefault={patientInfo.diagnosis?.length > 0}
      >
        <TabsBg
          tabs={Object.values(DiagnosisTabs)}
          activeTab={selectedDiagnosisTab}
          onChange={(tab) => setSelectedDiagnosisTab(tab)}
          className="w-[184px] bg-gray-150 text-sm mb-3"
          tabClassName="py-1 px-2 text-center"
        />
        <div className={blockClasses}>
          {displayedDiagnosis.length > 0 ? (
            displayedDiagnosis.map((diagn) => {
              const { diagnosis_id, diagnosis, icd10, date_recorded } = diagn;

              const description = [
                icd10 ? `ICD10 - ${icd10}` : "",
                date_recorded ? formatDate(date_recorded) : "",
              ]
                .filter(Boolean)
                .join(" ・ ");

              return (
                <ItemCard
                  key={diagnosis_id}
                  title={diagnosis}
                  desc={description}
                />
              );
            })
          ) : (
            <EmptyData
              title="No Diagnoses"
              desc="Diagnoses data has not been added"
            />
          )}
        </div>
      </CustomAccordion>

      <CustomAccordion
        title={`Medications ${patientInfo.medication?.length > 0 ? `(${patientInfo.medication.length})` : ""}`}
        className={containerClasses}
        openByDefault={patientInfo.medication?.length > 0}
      >
        <div className={blockClasses}>
          {patientInfo.medication?.length > 0 ? (
            patientInfo.medication.map((med) => {
              const {
                medication_id,
                medication,
                drug_code,
                code_system_id,
                date,
              } = med;

              const description = [
                drug_code,
                drug_code && code_system_id ? " - " : "",
                code_system_id,
                (drug_code || code_system_id) && date ? " ・ " : "",
                date ? formatDate(date) : "",
              ].join("");

              return (
                <ItemCard
                  key={medication_id}
                  title={medication}
                  desc={description}
                />
              );
            })
          ) : (
            <EmptyData
              title="No Medications"
              desc="Medications data has not been added"
            />
          )}
        </div>
      </CustomAccordion>

      <CustomAccordion
        title={`Allergies ${patientInfo.allergy?.length > 0 ? `(${patientInfo.allergy.length})` : ""}`}
        className={containerClasses}
        openByDefault={patientInfo.allergy?.length > 0}
      >
        <div className={blockClasses}>
          {patientInfo.allergy?.length > 0 ? (
            patientInfo.allergy.map((allergy) => {
              const {
                allergy_id,
                allergen,
                reaction,
                severity,
                allergy_code,
                date,
              } = allergy;

              const title = [allergen, reaction, severity]
                .filter(Boolean)
                .join(", ");

              const description = [allergy_code, date ? formatDate(date) : ""]
                .filter(Boolean)
                .join(" ・ ");

              return (
                <ItemCard key={allergy_id} title={title} desc={description} />
              );
            })
          ) : (
            <EmptyData
              title="No Allergies"
              desc="Allergies data has not been added"
            />
          )}
        </div>
      </CustomAccordion>

      <CustomAccordion
        title={`Immunizations ${patientInfo.immunization?.length > 0 ? `(${patientInfo.immunization.length})` : ""}`}
        className={containerClasses}
        openByDefault={patientInfo.immunization?.length > 0}
      >
        <div className={blockClasses}>
          {patientInfo.immunization?.length > 0 ? (
            patientInfo.immunization.map((immunization) => {
              const { immunization_id, vaccine, cvx_code, date_administered } =
                immunization;

              const description = [
                cvx_code ? `Cvx - ${cvx_code}` : "",
                date_administered ? formatDate(date_administered) : "",
              ]
                .filter(Boolean)
                .join(" ・ ");

              return (
                <ItemCard
                  key={immunization_id}
                  title={vaccine}
                  desc={description}
                />
              );
            })
          ) : (
            <EmptyData
              title="No Immunizations"
              desc="Immunizations data has not been added"
            />
          )}
        </div>
      </CustomAccordion>
    </div>
  );
};

export default HistoryTab;
