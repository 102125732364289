import type { AudioNodes } from "../../../types/audio";

export async function retryOperation<T>(
  operation: () => Promise<T>,
  maxRetries: number,
  delayMs: number,
): Promise<T> {
  let lastError: Error | undefined;

  for (let i = 0; i < maxRetries; i++) {
    try {
      return await operation();
    } catch (error) {
      lastError = error as Error;
      if (i < maxRetries - 1) {
        await new Promise((resolve) => setTimeout(resolve, delayMs));
      }
    }
  }

  throw lastError;
}

export function connectAudioNodes(
  source: MediaStreamAudioSourceNode,
  nodes: AudioNodes,
  destination: MediaStreamAudioDestinationNode,
) {
  const connections = [
    [source, nodes.filter],
    [nodes.filter, nodes.gainNode],
    [nodes.gainNode, nodes.compressor],
    [nodes.compressor, destination],
  ];

  connections.forEach(([from, to]) => {
    try {
      (from as AudioNode).connect(to as AudioNode);
    } catch (error) {
      throw new Error(`Failed to connect audio nodes: ${error.message}`);
    }
  });
}

export async function determineMediaRecorderOptions(): Promise<MediaRecorderOptions> {
  const mimeTypes = [
    "audio/webm;codecs=opus",
    "audio/webm",
    "audio/ogg;codecs=opus",
    "audio/mp4",
    "audio/mpeg",
    "",
  ];

  const options: MediaRecorderOptions = {};

  for (const mimeType of mimeTypes) {
    if (!mimeType || MediaRecorder.isTypeSupported(mimeType)) {
      options.mimeType = mimeType;
      break;
    }
  }

  if (
    options.mimeType &&
    (options.mimeType.includes("webm") || options.mimeType.includes("ogg"))
  ) {
    options.audioBitsPerSecond = 128000;
  }

  return options;
}

export const setupAudioNodes = (audioContext: AudioContext): AudioNodes => {
  const filter = audioContext.createBiquadFilter();
  filter.type = "lowpass";
  filter.frequency.value = 1000;

  const gainNode = audioContext.createGain();
  gainNode.gain.value = 5;

  const compressor = audioContext.createDynamicsCompressor();
  compressor.threshold.value = -40;
  compressor.knee.value = 40;
  compressor.ratio.value = 12;
  compressor.attack.value = 0;
  compressor.release.value = 0.25;

  return { filter, gainNode, compressor };
};
