import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as PhoneFilledIcon } from "../../../../assets/icons/phone-filled.svg";
import { getFormattedPhoneNumber } from "../../../../helpers/helpers";
import { setScribeCallsAction } from "../../../../store/voiceRecorder/actions";
import Switch from "../../../Basic/Switch";

const CallOptions = ({ className, onCall, patient }) => {
  const { scribeCalls } = useSelector((state) => state.voiceRecorder);
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const dispatch = useDispatch();

  return (
    <div className={className}>
      <p className="font-semibold mb-2">Patient Contact</p>
      <div className="flex items-center justify-between gap-3 border border-gray-foreground p-4 rounded-lg bg-white">
        <div className="">
          <p className="text-sm font-medium text-tertiary">Primary Contact</p>
          <div className="text-primary-blue font-semibold">
            <p>{getFormattedPhoneNumber(patient.phone_number)}</p>
          </div>
        </div>
        <button
          type="button"
          className="flex items-center justify-center flex-none w-10 h-10 rounded-full bg-system-light-green disabled:opacity-70"
          onClick={onCall}
        >
          <PhoneFilledIcon width="20" height="20" fill="#03C366" />
        </button>
      </div>

      <p className="font-semibold mt-6 mb-2">Scribe Calls</p>
      <div className="flex items-center justify-between gap-3 border border-gray-foreground p-4 rounded-lg bg-white">
        <p className="text-tertiary font-medium">Scribe your calls?</p>
        <Switch
          size={isDesktop ? "large" : "medium"}
          enabled={scribeCalls}
          onChange={() => dispatch(setScribeCallsAction(!scribeCalls))}
        />
      </div>

      <div className="flex items-center justify-between gap-3 border border-gray-foreground p-4 rounded-lg bg-white mt-2">
        <p className="text-tertiary font-medium">English</p>
        <div className="rounded-full bg-gray-100 px-3 py-1.5 text-xs text-slate-700 font-medium">
          Default language
        </div>
      </div>
    </div>
  );
};

export default CallOptions;
