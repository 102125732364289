import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setSelectedSettingsTabAction,
  setUsersLoadingAction,
} from "../../store/user/actions";
import { getRoles, getUsers } from "../../store/user/thunks";
import { Pages, TabsOrientation, settingsTabs } from "../../helpers/constants";
import TabButtonRound from "../../components/Basic/TabButtonRound";
import TabsBorder from "../../components/Basic/TabsBorder";
import NotesSectionTabs from "../../components/Settings/Notes/NotesSectionTabs";
import ClinicInfo from "../../components/Settings/Organization/ClinicInfo";

const SettingsTabs = ({ children }) => {
  const { selectedSettingsTab, userSearchValue, user, onlyScribeFeature } =
    useSelector((state) => state.user);
  const { facilities } = useSelector((state) => state.facility);
  const [tabs, setTabs] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setUsersLoadingAction(true));
    dispatch(getUsers(userSearchValue));
    dispatch(getRoles());
  }, [dispatch]);

  useEffect(() => {
    if (onlyScribeFeature) {
      const findTab = (link) => settingsTabs.find((tab) => tab.link === link);

      const profileSettingsTab = findTab(Pages.SETTINGS_PROFILE);
      const clinicSettingsTab = findTab(Pages.SETTINGS_ORGANIZATION);
      const notesSettingsTab = findTab(Pages.SETTINGS_NOTES);

      setTabs([profileSettingsTab, clinicSettingsTab, notesSettingsTab]);
      return;
    }

    const path = window.location.pathname;
    if (facilities?.length < 2) {
      setTabs(
        settingsTabs.filter((tab) => tab.link !== Pages.SETTINGS_FACILITIES),
      );
      if (
        selectedSettingsTab === Pages.SETTINGS_FACILITIES ||
        path === Pages.SETTINGS_FACILITIES
      ) {
        dispatch(
          setSelectedSettingsTabAction(
            settingsTabs.find((tab) => tab.link === Pages.SETTINGS_PROFILE)
              .text,
          ),
        );
        navigate(Pages.SETTINGS_PROFILE);
      }
    } else {
      setTabs(settingsTabs);
    }
  }, [
    facilities?.length,
    selectedSettingsTab,
    window.location.pathname,
    dispatch,
    onlyScribeFeature,
  ]);

  const renderAdditionalContent = () => {
    switch (window.location.pathname) {
      case Pages.SETTINGS_ORGANIZATION:
        return <ClinicInfo user={user} editable={user.role_id === 1} />;
      case Pages.SETTINGS_NOTES:
        return <NotesSectionTabs />;
      default:
        return null;
    }
  };

  return (
    <div className="bg-gray-background grid grid-rows-tab-layout xl:grid-rows-1 xl:grid-cols-2-right xl:pl-8 overflow-hidden">
      <div className="w-full overflow-y-auto pr-1 xl:mt-6 xl:pb-6 xl:w-60 scrollbar">
        <div className="hidden xl:flex p-4 h-fit w-full rounded-lg bg-white border border-gray-foreground">
          {tabs && (
            <TabsBorder
              tabs={tabs.map((tab) => tab.text)}
              orientation={TabsOrientation.VERTICAL}
              activeTab={selectedSettingsTab}
              setActiveTab={(tab) => {
                dispatch(setSelectedSettingsTabAction(tab));
                navigate(tabs.find((t) => t.text === tab).link);
              }}
              className="h-fit"
              tabClassName="py-4 px-5 text-base whitespace-nowrap"
              activeTextColor="primary-blue"
            />
          )}
        </div>
        <div className="xl:hidden px-4 overflow-x-hidden">
          <div className="pt-4 pb-1 flex space-x-2 w-full overflow-x-auto scrollbar">
            {tabs?.map((tab) => (
              <TabButtonRound
                key={tab.text}
                label={tab.text}
                active={selectedSettingsTab}
                onClick={() => {
                  dispatch(setSelectedSettingsTabAction(tab.text));
                  navigate(tab.link);
                }}
                className="whitespace-nowrap"
              />
            ))}
          </div>
        </div>

        {renderAdditionalContent()}
      </div>

      {children}
    </div>
  );
};

export default SettingsTabs;
