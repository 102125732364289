const ItemCard = ({ title, desc }) => {
  return (
    <div className="p-4 rounded-2.5 border border-gray-foreground space-y-1">
      <p className="text-base font-semibold text-primary">{title}</p>
      {desc && <p className="text-sm font-medium text-tertiary">{desc}</p>}
    </div>
  );
};

export default ItemCard;
