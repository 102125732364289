import Bugsnag from "@bugsnag/js";
import axios from "axios";
import { BASE_URL } from "../../helpers/config";
import { Pages } from "../../helpers/constants";
import { setNotificationAction } from "../user/actions";
import { setVerificationCode } from "./actions";

export const verifyEmail = (code, providerEmail, navigate) => (dispatch) => {
  axios
    .post(`${BASE_URL}/customer/sign-up/v2/${code}`, { providerEmail })
    .then((response) => {
      if (response.data.error) {
        console.error(response.data.error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to verify email address",
          }),
        );
        Bugsnag.notify(response.data.error);
      } else {
        dispatch(setVerificationCode(code));
        navigate(Pages.ONBOARDING);
        setTimeout(() => {
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Email address successfully verified",
              desc: "Your email address has been successfully verified",
            }),
          );
        }, 300);
      }
    })
    .catch((error) => {
      if (error.response.status === 404) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Invalid code",
            desc: "The code you entered is incorrect. Please try again.",
          }),
        );
      } else {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to verify email address",
          }),
        );
      }

      console.error(error);
      Bugsnag.notify(error);
    });
};

export const resendVerificationCode = (providerEmail) => (dispatch) => {
  axios
    .post(`${BASE_URL}/customer/reset-token/v2`, { providerEmail })
    .then((response) => {
      if (response.data.error) {
        console.error(response.data.error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to resend verification code",
          }),
        );
        Bugsnag.notify(response.data.error);
      } else {
        dispatch(
          setNotificationAction({
            status: "success",
            title: "Verification code successfully resent",
            desc: "The verification code has been successfully resent",
          }),
        );
      }
    })
    .catch((error) => {
      console.error(error);
      if (error?.response.status === 429) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Too many requests",
            desc: "You’ve asked for the verification code too many times. Please wait a minute before trying again.",
          }),
        );
      } else {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to resend verification code",
          }),
        );
      }
      Bugsnag.notify(error);
    });
};

export const completeSignUp =
  (specialty, ehr, providerEmail, code, navigate) => (dispatch) => {
    axios
      .post(`${BASE_URL}/customer/sign-up/v2/${code}/complete`, {
        specialty,
        ehr,
        providerEmail,
      })
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to complete onboarding",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          navigate(Pages.LOGIN);
          setTimeout(() => {
            dispatch(
              setNotificationAction({
                status: "success",
                title: "Onboarding completed",
                desc: "Onboarding has been successfully completed",
              }),
            );
          }, 300);
        }
      })
      .catch((error) => {
        console.error(error);
        Bugsnag.notify(error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to complete onboarding",
          }),
        );
      });
  };
