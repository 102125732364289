import Skeleton from "@mui/material/Skeleton";

const NotesSkeleton = ({
  months = 2,
  notes = 3,
  bgColor = "#EAEDF3",
  animation = "wave",
  className = "",
}: {
  months?: number;
  notes?: number;
  bgColor?: string;
  animation?: "pulse" | "wave" | false;
  className?: string;
}) => {
  return (
    <div className={`flex flex-col space-y-6 max-w-full ${className}`}>
      {[...Array(months)].map((_, index) => (
        <div key={`month-${index}`} className="space-y-4">
          <Skeleton
            animation={animation}
            variant="rounded"
            width={130}
            height={14}
            sx={{ bgcolor: bgColor, borderRadius: "14px" }}
          />
          <div className="border-l ml-2 pl-2 lg:ml-3 lg:pl-4 pt-3 pb-4">
            {[...Array(notes)].map((_, index) => (
              <div
                key={`note-${index}`}
                className="p-2.5 height-sm:p-4 space-y-2"
              >
                <Skeleton
                  animation={animation}
                  variant="rounded"
                  width={150}
                  height={10}
                  sx={{ bgcolor: bgColor, borderRadius: "10px" }}
                />
                <Skeleton
                  animation={animation}
                  variant="rounded"
                  width={180}
                  height={10}
                  sx={{ bgcolor: bgColor, borderRadius: "10px" }}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default NotesSkeleton;
