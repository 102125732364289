import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow-left.svg";
import { ReactComponent as TranscriptIcon } from "../../../assets/icons/chat-round-call.svg";
import { ReactComponent as NoteIcon } from "../../../assets/icons/document-round.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit-pen.svg";
import { NoteTabs } from "../../../helpers/constants";
import { useAppSelector } from "../../../store";
import Input from "../../Basic/Input";
import TabsBg from "../../Basic/TabsBg";
import type { FixLater } from "../../IndependentScribe/store/scribeSlice";
import { getSelectedScribe } from "../../IndependentScribe/store/selectors";
import { initialTitle } from "../../IndependentScribe/utils";

const NoteHeader = ({
  note,
  activeTab,
  setActiveTab,
  onClose,
  editableTitle = false,
  onUpdateTitle = (_) => {},
}: {
  note: FixLater;
  activeTab: string;
  setActiveTab: (tab: string) => void;
  onClose: () => void;
  editableTitle?: boolean;
  onUpdateTitle?: (title: string) => void;
}) => {
  const selectedScribe = useAppSelector(getSelectedScribe);
  const { selectedPatientInfo } = useAppSelector((state) => state.patient);

  const [editTitle, setEditTitle] = useState(false);
  const [newTitle, setNewTitle] = useState(note.title || "");

  const fallbackTitle = initialTitle(new Date(note.startedAt));
  const titleWithFallback = newTitle || fallbackTitle;
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const handleBlur = () => {
    onUpdateTitle(titleWithFallback);
    setNewTitle(titleWithFallback);
    setEditTitle(false);
  };

  const handleBackArrowClick = () => {
    onUpdateTitle(titleWithFallback);
    onClose();
  };

  useEffect(() => {
    if (note.title && note.title !== newTitle && !editTitle) {
      setNewTitle(note?.title || fallbackTitle);
    }
  }, [note]);

  return (
    <div
      className="grid grid-cols-conv-layout items-center gap-3 w-full bg-white px-4 py-3 border-b
        md:rounded-t-xl md:border-b-0 md:border-x md:border-t md:mt-3 md:py-3"
    >
      <div className="flex items-center w-full truncate">
        <button
          type="button"
          onClick={handleBackArrowClick}
          className="w-6 h-8 md:w-8 flex items-center justify-center md:mr-1"
        >
          <ArrowIcon width="14" height="14" stroke="#121212" />
        </button>
        {editTitle && isDesktop && !selectedScribe?.isGenerating ? (
          <Input
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
            autoFocus
            onBlur={handleBlur}
            className="text-base lg:text-xl"
            inputClassName="h-9"
          />
        ) : (
          <div
            className={`group mr-2 md:mr-4 truncate rounded-lg px-3 md:py-1 flex items-center space-x-3
              ${editableTitle && isDesktop ? "cursor-pointer hover:bg-gray-110" : ""}`}
            onClick={(e) => {
              e.preventDefault();
              if (editableTitle && isDesktop && !selectedScribe?.isGenerating) {
                setEditTitle(true);
              }
            }}
          >
            <p className="text-base lg:text-xl font-semibold truncate">
              {isDesktop
                ? newTitle
                : `${
                    selectedPatientInfo?.preferred_name
                      ? selectedPatientInfo?.preferred_name
                      : selectedPatientInfo?.first_name
                  } ${selectedPatientInfo?.last_name}`}
            </p>
            {editableTitle && isDesktop && (
              <EditIcon
                width="20"
                height="20"
                stroke="#667085"
                className="flex-none opacity-0 group-hover:opacity-100"
              />
            )}
          </div>
        )}
      </div>

      {!isDesktop && note.conversation?.messages?.length > 0 && (
        <TabsBg
          tabs={Object.values(NoteTabs)}
          icons={{
            [NoteTabs.NOTE]: (
              <NoteIcon
                width="20"
                height="20"
                className="transition-all duration-300 flex-none"
                stroke={activeTab === NoteTabs.NOTE ? "white" : "#667085"}
              />
            ),
            [NoteTabs.TRANSCRIPT]: (
              <TranscriptIcon
                width="20"
                height="20"
                stroke={activeTab === NoteTabs.TRANSCRIPT ? "white" : "#667085"}
              />
            ),
          }}
          withoutLabel
          activeTab={activeTab}
          onChange={setActiveTab}
          className="w-fit ml-auto bg-gray-150"
          tabClassName="py-1.5 text-center text-xs lg:text-sm"
        />
      )}

      {isDesktop && note.conversation?.messages?.length > 0 && (
        <TabsBg
          tabs={Object.values(NoteTabs)}
          activeTab={activeTab}
          onChange={setActiveTab}
          className="w-full md:w-36 lg:w-52 bg-gray-150"
          tabClassName="py-1.5 text-center text-xs lg:text-sm"
        />
      )}
    </div>
  );
};

export default NoteHeader;
