import { ReactComponent as ConversationImg } from "../../../assets/empty-conversation.svg";

const EmptyConversation = ({ ...props }) => {
  return (
    <div
      className="h-full bg-gray-background w-full flex items-center justify-center"
      {...props}
    >
      <div className="w-full max-w-md flex flex-col items-center space-y-10.5">
        <ConversationImg width="228" height="166" />
        <p className="text-xl font-semibold text-center">
          Please select a message to see the conversation
        </p>
      </div>
    </div>
  );
};

export default EmptyConversation;
