import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getPatientInfo } from "../../store/patient/thunks";
import {
  setOpenEditInfoModalAction,
  setSelectedPatientInfoAction,
  setSelectedPatientPanelTabAction,
  setSelectedPatientProfileTabAction,
} from "../../store/patient/actions";
import {
  Pages,
  patientPanelTabs,
  patientProfileTabs,
} from "../../helpers/constants";
import Button from "./Button";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus-small.svg";
import { setPathBeforeProfile } from "../../store/ui/actions";

const AddPhoneNumber = ({ patientId, call }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="w-full h-full flex flex-col items-center justify-center bg-gray-background">
      <div className="w-12 h-12 rounded-full flex items-center justify-center bg-primary-blue-light mb-5">
        <PlusIcon width="20" height="20" />
      </div>
      <p className="text-xl lg:text-2xl font-bold mb-1">No Phone Number</p>
      <p className="text-sm lg:text-base font-medium text-tertiary mb-6">
        Add phone number to {call ? "call" : "message"} patient
      </p>
      <Button
        className="text-sm font-semibold"
        onClick={() => {
          dispatch(setPathBeforeProfile(Pages.MESSAGING));
          dispatch(
            getPatientInfo(patientId, (patientInfo) => {
              dispatch(setSelectedPatientInfoAction(patientInfo));
              navigate(`${Pages.PATIENTS}/${patientId}`);
            }),
          );
          dispatch(
            setSelectedPatientProfileTabAction(patientProfileTabs.INFORMATION),
          );
          dispatch(
            setSelectedPatientPanelTabAction(
              call ? patientPanelTabs.CALL : patientPanelTabs.MESSAGE,
            ),
          );
          dispatch(setOpenEditInfoModalAction(true));
        }}
      >
        Add Phone Number
      </Button>
    </div>
  );
};

export default AddPhoneNumber;
