import { useCallback, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Connection, Device } from "twilio-client";
import { ReactComponent as ArrowsIcon } from "../assets/icons/double-arrow-left.svg";
import { getSelectedScribe } from "../components/IndependentScribe/store/selectors";
import ChatCallScribePanel from "../components/Layout/ChatCallScribePanel";
import Conversation from "../components/Messaging/Conversation";
import EmptyConversation from "../components/Messaging/Conversation/EmptyConversation";
import ConversationSidebar from "../components/Messaging/ConversationSidebar";
import InsightPanel from "../components/Messaging/InsightPanel";
import { convPerPage, twilioDeviceStatuses } from "../helpers/constants";
import { useAppDispatch, useAppSelector } from "../store";
import {
  collapseSidebarAction,
  setConversationsCompletedTempAction,
  setConversationsUpdatedAction,
  setFiltersUpdatedAction,
  setMessagesAction,
  setSelectedConversationTabAction,
} from "../store/conversation/actions";
import {
  getConversation,
  searchConversationsWithFilters,
} from "../store/conversation/thunks";
import { setSelectedPatientPanelTabAction } from "../store/patient/actions";
import {
  setDeviceStatusAction,
  setOpenCallModalAction,
} from "../store/voiceRecorder/actions";
import { getTwilioToken } from "../store/voiceRecorder/thunks";

const Messaging = () => {
  const { READY, RINGING, IN_PROGRESS, OFFLINE } = twilioDeviceStatuses;

  const {
    currentConversation,
    currentConversationId,
    conversationSearchInput,
    activeInbox,
    activeFilters,
    convCurrPage,
    conversationsUpdated,
    onMessageConversationId,
    selectedDoctorInbox,
    messages,
    selectedConversationTab,
  } = useAppSelector((state) => state.conversation);
  const { selectedPatientInfo, profileConversation, selectedPatientPanelTab } =
    useAppSelector((state) => state.patient);
  const { twilioToken, openCallOptionsModal, callEvent, deviceStatus } =
    useAppSelector((state) => state.voiceRecorder);
  const selectedScribe = useAppSelector(getSelectedScribe);
  const recordingStarted = selectedScribe?.isRecording;

  const isSidebarStatic = useMediaQuery({ minWidth: 1340 });
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isHeightSm = useMediaQuery({ minHeight: 640 });

  const [twilioDevice, setTwilioDevice] = useState(new Device());
  const [call, setCall] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const [showInsightPanel, setShowInsightPanel] = useState(isDesktop);
  const [scrolledUp, setScrolledUp] = useState(false);

  const dispatch = useAppDispatch();

  const updateConversations = () => {
    if (
      currentConversationId &&
      onMessageConversationId &&
      currentConversationId === onMessageConversationId
    ) {
      dispatch(getConversation(currentConversationId));
    }
    dispatch(setConversationsUpdatedAction(true));

    dispatch(
      searchConversationsWithFilters(
        conversationSearchInput,
        activeFilters,
        1,
        convPerPage * convCurrPage,
        activeInbox,
        selectedDoctorInbox,
        () => dispatch(setFiltersUpdatedAction(true)),
      ),
    );
  };

  useEffect(() => {
    dispatch(setConversationsCompletedTempAction([]));

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      dispatch(setMessagesAction({}));
    };
  }, []);

  useEffect(() => {
    dispatch(setConversationsUpdatedAction(false));
  }, [activeFilters]);

  useEffect(() => {
    if (!conversationsUpdated) {
      updateConversations();
    }
  }, [conversationsUpdated]);

  const clickOutsideConversationSidebar = () => {
    if (!isSidebarStatic) {
      dispatch(collapseSidebarAction(true));
    }
  };

  const handleResize = () => {
    setShowInsightPanel(false);
  };

  const selectTab = (tab) => {
    dispatch(setSelectedPatientPanelTabAction(tab));
  };

  useEffect(() => {
    if (twilioToken) {
      twilioDevice.setup(twilioToken, {
        codecPreferences: [Connection.Codec.Opus, Connection.Codec.PCMU],
        fakeLocalDTMF: true,
        enableRingingState: true,
        debug: false,
      });

      twilioDevice.on("ready", (device) => {
        console.log("twilio device ready");
        setTwilioDevice(device);
        dispatch(setDeviceStatusAction(READY));
      });

      twilioDevice.on("error", (error) => {
        dispatch(setDeviceStatusAction(OFFLINE));
        console.error("twilio error", error);
      });

      twilioDevice.on("disconnect", () => {
        console.log("twilio device disconnect");
        dispatch(setDeviceStatusAction(READY));
        dispatch(setOpenCallModalAction(false));
      });

      twilioDevice.on("incoming", (connection) => {
        console.log("twilio device incoming");

        connection.on("reject", () => {
          dispatch(setDeviceStatusAction(READY));
        });
      });

      twilioDevice.on("tokenWillExpire", () => {
        console.log("twilio token will expire");
        dispatch(getTwilioToken());
      });
    }
  }, [twilioToken, dispatch, twilioDevice, OFFLINE, READY]);

  useEffect(() => {
    console.debug("device status", deviceStatus);
    if (deviceStatus === OFFLINE) {
      dispatch(getTwilioToken());
    }
  }, [deviceStatus, OFFLINE, dispatch]);

  const handleDisconnect = useCallback(() => {
    console.log("handle disconnect");
    twilioDevice?.disconnectAll();
    dispatch(setDeviceStatusAction(READY));
  }, [twilioDevice, dispatch, READY]);

  useEffect(() => {
    if (call) {
      call.on("cancel", () => {
        console.log("call canceled");
        handleDisconnect();
      });
      call.on("disconnect", () => {
        console.log("call disconnected");
        handleDisconnect();
      });
      call.on("error", (error) => {
        handleDisconnect();
        console.error("call error", error);
      });
      call.on("reject", () => {
        console.log("call rejected");
        handleDisconnect();
      });
      call.on("reconnecting", (twilioError) => {
        console.log("call reconnecting", twilioError);
      });
    }
    return () => {
      console.debug("call is muted", false);
      setIsMuted(false);
    };
  }, [call, handleDisconnect]);

  useEffect(() => {
    if (call) {
      console.debug("call is muted", call.isMuted());
    } else {
      console.debug("call is muted", false);
    }
  }, [call]);

  useEffect(() => {
    if (callEvent) {
      if (callEvent.status === "ringing") {
        console.log("call ringing");
        dispatch(setDeviceStatusAction(RINGING));
      }
      if (callEvent.status === "in-progress") {
        console.log("call in progress");
        dispatch(setDeviceStatusAction(IN_PROGRESS));
      }
      if (callEvent.status === "completed") {
        console.log("call completed");
        handleDisconnect();
      }
    }
  }, [callEvent, IN_PROGRESS, RINGING, dispatch, handleDisconnect]);

  const handleCall = async (phone_number) => {
    console.log("handle call, device status", deviceStatus);
    if (deviceStatus !== twilioDeviceStatuses.READY || !twilioDevice) {
      dispatch(getTwilioToken());
    } else {
      const call = await twilioDevice?.connect({ To: phone_number });
      setCall(call);
    }
  };

  const handleMute = () => {
    setIsMuted(!isMuted);
    call.mute(!isMuted);
  };

  return (
    <>
      <div
        className={`w-full flex min-h-0 md:border-l ${isDesktop ? "h-screen-dynamic" : ""}`}
      >
        {isDesktop ? (
          <div className="flex w-full">
            <ConversationSidebar messages={messages} />
            {!currentConversation && (
              <EmptyConversation onClick={clickOutsideConversationSidebar} />
            )}
          </div>
        ) : (
          <div className="min-h-0 h-full w-screen relative">
            <div
              className={`absolute top-0 left-0 h-full bg-white transition-all duration-300 overflow-hidden
              ${currentConversation ? "w-0" : "w-full"}`}
            >
              <ConversationSidebar messages={messages} />
            </div>
            {currentConversation && (
              <>
                <Conversation
                  messages={messages}
                  setMessages={(messages) =>
                    dispatch(setMessagesAction(messages))
                  }
                  scrolledUp={scrolledUp}
                  setScrolledUp={setScrolledUp}
                />
                <div
                  className={`absolute z-20 top-0 right-0 h-full bg-white transition-all duration-300 overflow-hidden
                  ${showInsightPanel ? "w-full" : "w-0"}`}
                >
                  <InsightPanel />
                </div>

                <button
                  type="button"
                  onClick={() => setShowInsightPanel(!showInsightPanel)}
                  className={`w-6 h-6 rounded-full flex items-center justify-center shadow-sm shadow-black/10
                  bg-white absolute z-20 top-[20px] transition-all duration-300
                  ${showInsightPanel ? "left-4" : "left-full -translate-x-full"}`}
                  aria-label="Toggle insight panel"
                >
                  <ArrowsIcon
                    className={`transition-all
                    ${showInsightPanel ? "rotate-180" : ""}`}
                    width="16"
                    height="16"
                  />
                </button>
              </>
            )}
          </div>
        )}
      </div>
      <div className="sidebar-static:w-[calc(100%-theme(width.navbar)-theme(width.expandedInboxBar))]">
        {(openCallOptionsModal || recordingStarted) && !isDesktop && (
          <div
            className={`
                  ${
                    selectedPatientInfo && isDesktop
                      ? `h-full w-[42vw] min-w-[400px]
                        ${isHeightSm ? "py-4 pr-4 height-md:py-6 height-md:pr-6" : "py-2 pr-2"}`
                      : ""
                  }
                  ${(openCallOptionsModal || recordingStarted) && !isDesktop ? "absolute top-0 left-0 z-20 h-screen-dynamic w-full" : ""}`}
          >
            <ChatCallScribePanel
              handleCall={handleCall}
              handleDisconnect={handleDisconnect}
              handleMute={handleMute}
              isMuted={isMuted}
              conversation={profileConversation}
              patient={selectedPatientInfo}
              selectedTab={selectedPatientPanelTab}
              setSelectedTab={selectTab}
              messages={messages}
              setMessages={(messages) => dispatch(setMessagesAction(messages))}
              scribeTab={true}
            />
          </div>
        )}
        {isDesktop && currentConversation && (
          <div
            onClick={clickOutsideConversationSidebar}
            className="flex min-h-0 max-h-full h-full w-screen max-w-full p-6 bg-gray-background space-x-6 md:h-[100dvh]"
          >
            <ChatCallScribePanel
              handleCall={handleCall}
              handleDisconnect={handleDisconnect}
              handleMute={handleMute}
              isMuted={isMuted}
              conversation={currentConversation}
              patient={currentConversation.patient}
              selectedTab={selectedConversationTab}
              setSelectedTab={(tab) =>
                dispatch(setSelectedConversationTabAction(tab))
              }
              messages={messages}
              setMessages={(messages) => dispatch(setMessagesAction(messages))}
            />

            <InsightPanel />
          </div>
        )}
      </div>
    </>
  );
};

export default Messaging;
