import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../store";
import type { Patient } from "../../../store/patient/interfaces";
import ScribeNoteSkeleton from "../../Skeletons/ScribeNoteSkeleton";
import AssignPatientModal from "../AssignPatientModal";
import ScribeHeader from "../ScribeHeader";
import ScribeViewDetailsFooter from "../ScribeViewDetailsFooter";
import { getProviderNote } from "../store/thunks";

const ScribeViewDetailsSkeleton = ({
  patient,
  handleAssignPatientId,
  onBack,
}: {
  patient: Patient | null;
  handleAssignPatientId: (patientId: number) => void;
  onBack: () => void;
}) => {
  const dispatch = useAppDispatch();

  const [showAssignPatientModal, setShowAssignPatientModal] = useState(false);

  useEffect(() => {
    dispatch(getProviderNote());
  }, [dispatch]);

  return (
    <>
      <ScribeHeader
        handleAssignPatientId={handleAssignPatientId}
        onBack={onBack}
        patient={patient}
        setShowAssignPatientModal={setShowAssignPatientModal}
      />
      <div className="w-full h-full min-h-0 flex flex-col md:justify-between px-4 md:px-0 pt-5 pb-18">
        <ScribeNoteSkeleton className="h-full overflow-hidden" />
        <ScribeViewDetailsFooter />
      </div>
      {showAssignPatientModal && (
        <AssignPatientModal
          handleClose={() => setShowAssignPatientModal(false)}
          handleAssignPatientId={handleAssignPatientId}
        />
      )}
    </>
  );
};

export default ScribeViewDetailsSkeleton;
