import { useAppSelector } from "../../../store";
import MicrophoneLiveVisualizer from "../../Basic/MicrophoneLiveVisualizer";
import RecordingIndicator from "../RecordingIndicator";
import ScribeRecordingFooterButtons from "../ScribeRecordingFooterButtons";
import TimerDisplay from "../TimerDisplay";
import { getSelectedScribe } from "../store/selectors";

const ScribeRecordingFooter = ({
  mediaRecorder,
  cancelRecording,
  saveRecording,
  togglePause,
}: {
  mediaRecorder: MediaRecorder | null;
  cancelRecording: () => void;
  saveRecording: () => void;
  togglePause: () => void;
}) => {
  const selectedScribe = useAppSelector(getSelectedScribe);

  return (
    <footer
      className="fixed bottom-6 md:bottom-4 flex flex-col md:grid md:grid-cols-conv-layout md:items-center md:justify-between
        w-[calc(100vw-32px)] md:w-[calc(100vw-72px-300px-48px)] overflow-hidden"
    >
      <div className="flex flex-col md:grid md:grid-cols-3-left w-full items-center">
        <div className="overflow-x-hidden w-full mb-12 md:mb-0">
          <MicrophoneLiveVisualizer
            mediaRecorder={mediaRecorder}
            isActive={selectedScribe?.isRecording}
          />
        </div>

        <TimerDisplay
          isRecording={selectedScribe?.isRecording}
          startedAt={selectedScribe?.startedAt}
          offset={selectedScribe?.accumulatedDuration}
          className="text-2xl md:text-base"
        />

        <div className="hidden md:block">
          <RecordingIndicator isRecording={selectedScribe?.isRecording} />
        </div>
        <div className="flex items-center md:hidden bg-[#FFEBEE] rounded-full px-3 py-1 gap-1">
          <RecordingIndicator isRecording={selectedScribe?.isRecording} />
          <p>{selectedScribe?.isRecording ? "Recording" : "Paused"}</p>
        </div>
      </div>

      <ScribeRecordingFooterButtons
        togglePause={togglePause}
        cancelRecording={cancelRecording}
        saveRecording={saveRecording}
      />
    </footer>
  );
};

export default ScribeRecordingFooter;
