import Popover from "@mui/material/Popover";
import { useState } from "react";
import { ReactComponent as CardSendIcon } from "../../../assets/icons/card-send.svg";
// import { ReactComponent as GenerateICD10Icon } from "../../../assets/icons/generate-icd10.svg";
import { ReactComponent as CopyIcon } from "../../../assets/icons/copy-note.svg";
import { ReactComponent as MoreIcon } from "../../../assets/icons/menu-dots.svg";
// import { generateICD10Codes } from "../../../store/patient/thunks";
import { copyText } from "../../../helpers/helpers";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setNotificationAction } from "../../../store/user/actions";
import { getSelectedScribe } from "../store/selectors";

const ScribeViewDetailsFooter = () => {
  const selectedScribe = useAppSelector(getSelectedScribe);

  const [openOptions, setOpenOptions] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useAppDispatch();

  const handleCopyNote = () => {
    copyText(selectedScribe?.note, () =>
      dispatch(
        setNotificationAction({
          status: "success",
          title: "Successfully copied note content",
          desc: "Note content has been successfully copied to the clipboard",
        }),
      ),
    );
  };

  const onCloseOptions = () => {
    setOpenOptions(false);
    setAnchorEl(null);
  };

  const onOpenOptions = (e) => {
    setOpenOptions(true);
    setAnchorEl(e.currentTarget);
  };

  // const handleGenerateICD10 = useCallback(() => {
  //   setOpenOptions(false);
  //   dispatch(generateICD10Codes(scribe.note_id));
  // }, [dispatch, scribe]);

  const buttonClasses =
    "flex items-center gap-3 bg-gray-foreground text-primary whitespace-nowrap h-10 lg:h-11 text-sm font-semibold lg:text-base";

  const optionButtonClasses =
    "px-2.5 py-2 flex items-center space-x-2.5 rounded-lg text-tertiary group enabled:hover:text-primary hover:bg-gray-background disabled:bg-gray-background";

  return (
    <footer
      className="fixed bottom-0 left-0 md:right-6 z-10 md:left-[unset] flex px-0 w-full md:w-[unset]
        justify-center md:px-0 p-4 bg-white sm:bg-[unset]"
    >
      <div className="w-full md:w-[unset] flex px-4 md:px-0 justify-end gap-3 md:gap-0">
        <button
          type="button"
          className={`${buttonClasses} justify-center grow sm:grow-0 rounded-lg sm:rounded-r-none px-4 shadow-scribe`}
          // className={`${buttonClasses} justify-center grow md:grow-0 rounded-lg px-4 shadow-scribe`}
          onClick={handleCopyNote}
        >
          <CopyIcon width="20" height="20" />
          <p>Copy Note</p>
        </button>
        <button
          type="button"
          className={`${buttonClasses} grow-0 rounded-lg sm:rounded-l-none sm:border-l sm:border-dotted border-[#D0D5DD]
            px-3 shadow-scribe`}
          onClick={onOpenOptions}
        >
          <MoreIcon width="20" height="20" />
        </button>

        {openOptions && anchorEl && (
          <Popover
            open={openOptions}
            onClose={onCloseOptions}
            className="drop-shadow-2xl border border-gray-foreground"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: -8,
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            PaperProps={{
              sx: {
                borderRadius: "8px",
                width: "200px",
              },
            }}
          >
            <div className="flex flex-col space-y-1 p-2 text-sm font-medium">
              {/* // Question: Should we change something if the codes have already been generated? */}
              {/*<button*/}
              {/*  className={optionButtonClasses}*/}
              {/*  onClick={handleGenerateICD10}*/}
              {/*>*/}
              {/*  <GenerateICD10Icon*/}
              {/*    width="20"*/}
              {/*    height="20"*/}
              {/*    className="opacity-60 group-hover:opacity-100"*/}
              {/*  />*/}
              {/*  <span>Generate ICD-10 Codes</span>*/}
              {/*</button>*/}

              <button
                type="button"
                className={optionButtonClasses}
                // onClick={handleSendToEhr}
                disabled
              >
                <CardSendIcon
                  width="20"
                  height="20"
                  className="opacity-60 enabled:group-hover:opacity-100"
                />
                <span>Send to EHR</span>
              </button>
            </div>
          </Popover>
        )}
      </div>
    </footer>
  );
};

export default ScribeViewDetailsFooter;
