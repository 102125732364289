import { useCallback, useRef } from "react";
import useEventListener from "./useEventListener";

const useWakeLock = () => {
  const wakeLockRef = useRef<WakeLockSentinel | null>(null);

  const handleWakeLockRelease = useCallback(() => {
    wakeLockRef.current = null;
  }, []);

  const requestWakeLock = useCallback(async () => {
    if ("wakeLock" in navigator) {
      try {
        wakeLockRef.current = await navigator.wakeLock.request("screen");
        wakeLockRef.current.addEventListener("release", handleWakeLockRelease);
      } catch (err) {
        console.error(`${(err as Error).name}, ${(err as Error).message}`);
      }
    } else {
      console.warn("Wake Lock API is not supported in this browser.");
    }
  }, [handleWakeLockRelease]);

  const releaseWakeLock = useCallback(async () => {
    if (wakeLockRef.current) {
      try {
        await wakeLockRef.current.release();
      } catch (err) {
        console.error("Error releasing Wake Lock:", err);
      } finally {
        wakeLockRef.current = null;
      }
    }
  }, []);

  const handleWakeLockAfterTouch = useCallback(() => {
    if (!wakeLockRef.current) {
      requestWakeLock();
    }
  }, [requestWakeLock]);

  useEventListener("click", handleWakeLockAfterTouch, document);

  return { requestWakeLock, releaseWakeLock, wakeLockRef };
};

export default useWakeLock;
