import Bugsnag from "@bugsnag/js";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ReactComponent as HeaderImage } from "../../assets/scribe-header.svg";
import { useAppDispatch, useAppSelector } from "../../store";
import type { Patient } from "../../store/patient/interfaces";
import { getPatientInfo } from "../../store/patient/thunks";
import { setNotificationAction } from "../../store/user/actions";
import Button from "../Basic/Button";
import MicrophoneTestVolumeBars from "../Basic/MicrophoneTestVolumeBars";
import Select from "../Basic/Select";
import AssignPatientModal from "../IndependentScribe/AssignPatientModal";
import ScribeHeader from "../IndependentScribe/ScribeHeader";
import {
  setSelectedScribeNoteTemplate,
  setShowScribeNoteTemplate,
} from "../IndependentScribe/store/noteTemplateSlice";
import { setScribeRecording } from "../IndependentScribe/store/scribeSlice";
import { getRecordingScribe } from "../IndependentScribe/store/selectors";
import {
  describeNoteTemplate,
  getNoteTemplates,
} from "../IndependentScribe/store/thunks";
import Dropdown from "../Settings/Notes/Dropdown/Dropdown";

const encounterTypeOptions = [{ text: "In person", value: "in-person" }];

const defaultUserLang =
  (
    navigator.language ||
    navigator.userLanguage ||
    navigator.languages[0]
  ).split("-")[0] ?? "en";

type FormData = {
  encounterType: "in-person";
  microphone: string;
  inputLanguage: string;
};

const NewScribe = ({
  startRecording,
  microphones,
  microphoneId,
  setMicrophoneId,
  onBack,
  audioVolume,
  noAudioDetected,
  hideHeader = false,
  isReadyToRecord,
  readyToRecordErrors,
  requestMicrophonePermissions,
}: {
  startRecording: () => void;
  microphones: { text: string; value: string }[];
  microphoneId: string | null;
  setMicrophoneId: (id: string) => void;
  onBack: () => void;
  audioVolume: number;
  noAudioDetected: boolean;
  hideHeader?: boolean;
  isReadyToRecord: boolean;
  readyToRecordErrors: string[];
  requestMicrophonePermissions: () => void;
}) => {
  const { user } = useAppSelector((state) => state.user);
  const { noteTemplates, selectedScribeNoteTemplate, defaultNoteTemplate } =
    useAppSelector((state) => state.noteTemplate);
  const recordingScribe = useAppSelector(getRecordingScribe);
  const { selectedPatientInfo } = useAppSelector((state) => state.patient);
  const [showAssignPatientModal, setShowAssignPatientModal] = useState(false);
  const [patient, setPatient] = useState<Patient | null>(null);
  const [isPatientProfile, setIsPatientProfile] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (window.location.pathname.match(/\/patients\/\d+/)) {
      setIsPatientProfile(true);
    } else {
      setIsPatientProfile(false);
    }
  }, [window.location.pathname]);

  const onStart = useCallback(() => {
    if (!recordingScribe) {
      const patientToUse =
        isPatientProfile && selectedPatientInfo
          ? {
              patient_id: selectedPatientInfo.patient_id,
              first_name: selectedPatientInfo.first_name,
              last_name: selectedPatientInfo.last_name,
              preferred_name: selectedPatientInfo.preferred_name,
              phone_number: selectedPatientInfo.phone_number,
            }
          : patient;

      dispatch(setScribeRecording({ patient: patientToUse }));
    }

    startRecording();
  }, [
    dispatch,
    patient,
    selectedPatientInfo,
    isPatientProfile,
    recordingScribe,
    startRecording,
  ]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      encounterType: "in-person",
      microphone: microphoneId,
      inputLanguage: user.settings.scribe_input_language ?? defaultUserLang,
    },
  });

  useEffect(() => {
    if (microphoneId) {
      setValue("microphone", microphoneId);
    }
  }, [microphoneId, setValue]);

  useEffect(() => {
    dispatch(getNoteTemplates());
  }, [dispatch]);

  useEffect(() => {
    // preselect default note template
    if (defaultNoteTemplate) {
      dispatch(setSelectedScribeNoteTemplate(defaultNoteTemplate));
    } else if (user?.note_template_id && noteTemplates.length > 0) {
      dispatch(
        describeNoteTemplate(user.note_template_id, (data) =>
          dispatch(setSelectedScribeNoteTemplate(data)),
        ),
      );
    }
  }, [noteTemplates, user, defaultNoteTemplate, dispatch]);

  const onMicrophoneChange = (value: React.ChangeEvent<HTMLSelectElement>) => {
    setMicrophoneId(value.target.value);
    setValue("microphone", value.target.value);
  };

  const onSubmit = useCallback(() => {
    if (noAudioDetected || !isReadyToRecord) {
      dispatch(
        setNotificationAction({
          status: "error",
          title: "No Voice Detected",
          desc: "Check Microphone Settings",
        }),
      );
      requestMicrophonePermissions();
      return;
    }

    onStart();
    dispatch(setShowScribeNoteTemplate(true));
  }, [onStart, noAudioDetected, isReadyToRecord, dispatch]);

  const handleSelectTemplate = (template) => {
    if (
      template.note_template_id !== selectedScribeNoteTemplate?.note_template_id
    ) {
      dispatch(
        describeNoteTemplate(template.note_template_id, (data) =>
          dispatch(setSelectedScribeNoteTemplate(data)),
        ),
      );
    }
  };

  return (
    <>
      {!hideHeader && (
        <ScribeHeader
          handleAssignPatientId={(patientId: number | null) => {
            if (!patientId) {
              setPatient(null);
              return;
            }

            dispatch(
              getPatientInfo(patientId, (patient) => {
                setPatient(patient);
              }),
            );
          }}
          onBack={onBack}
          patient={
            isPatientProfile && selectedPatientInfo
              ? selectedPatientInfo
              : patient
          }
          setShowAssignPatientModal={setShowAssignPatientModal}
        />
      )}
      <div className="px-8 py-5 h-full w-full min-h-0 overflow-y-auto overflow-x-hidden scrollbar grid">
        <div className="grid h-fit w-full max-w-full min-w-0 md:max-w-[602px] mx-auto my-auto">
          <div className="hidden height-md:flex flex-col mb-10">
            <HeaderImage
              width="200"
              height="100"
              className="flex-none mx-auto"
            />
            <p className="mt-4 text-sm text-tertiary text-center font-medium">
              Click on 'Start Encounter' below to start the transcription.
            </p>
          </div>

          <form
            id="startEncounter"
            className="mt-1 mb-8 grid space-y-4 min-w-0 w-full max-w-full"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Select
              required="Encounter is required"
              label="Encounter"
              placeholder="Choose encounter"
              name="encounterType"
              options={encounterTypeOptions}
              register={register}
              withoutAsterisk={true}
              error={errors.encounterType}
            />

            <div className="space-y-1.5">
              <p className="text-sm font-semibold">Note Template</p>
              <Dropdown
                customItems={noteTemplates.filter(
                  (template) => template.is_custom,
                )}
                preMadeItems={noteTemplates.filter(
                  (template) => !template.is_custom && !template.is_hidden,
                )}
                selectedItem={selectedScribeNoteTemplate}
                onSelectItem={handleSelectTemplate}
                itemName="template"
                defaultSelect
                closeDropdownOnSelect
                withoutSearch={false}
              />
            </div>

            <div className="min-w-0 max-w-full overflow-x-hidden">
              <Select
                label="Microphone"
                placeholder="Choose microphone"
                name="microphone"
                register={register}
                options={microphones}
                withoutAsterisk={true}
                error={errors.microphone}
                onChange={onMicrophoneChange}
                value={microphoneId ?? ""}
                onClick={requestMicrophonePermissions}
              />
            </div>

            <div className="flex items-center justify-between space-x-4">
              <p className="text-sm text-tertiary font-medium">
                Test your microphone
              </p>
              <MicrophoneTestVolumeBars volume={+audioVolume} />
            </div>
          </form>

          <Button
            variant="blue-light"
            type="submit"
            className="w-full justify-center font-semibold py-3"
            form="startEncounter"
            disabled={!isReadyToRecord}
            onPointerDown={() => {
              if (readyToRecordErrors.length > 0) {
                console.error(readyToRecordErrors.join("\n"));
                Bugsnag.notify(new Error(readyToRecordErrors.join("\n")));
              }
            }}
          >
            Start Encounter
          </Button>
        </div>
      </div>

      {showAssignPatientModal && (
        <AssignPatientModal
          handleClose={() => setShowAssignPatientModal(false)}
          handleAssignPatientId={(patientId: number) => {
            dispatch(
              getPatientInfo(patientId, (patient) => {
                setPatient(patient);
              }),
            );
          }}
        />
      )}
    </>
  );
};

export default NewScribe;
