import {
  SET_CAMPAIGN_LOADER,
  SET_CAMPAIGNS,
  SET_CURRENT_VIEW,
  SET_SELECTED_CAMPAIGN,
  SET_SELECTED_START_DATE,
  SET_SELECTED_EVENT,
  SET_SELECTED_TAB,
  SET_SELECTED_UPCOMING_POSITION,
  SET_UPCOMING_MESSAGES,
  SET_SELECTED_CAMPAIGN_IDS,
  SET_ENROLL_FIELDS,
  SET_SELECTED_ENROLL_PATIENTS,
  SET_RERENDER_ENROLL_FIELDS,
  SET_START_DATE_SHORTCUT,
  SET_CAMPAIGN_TAGS,
  SET_MORE_EVENTS_POSITION,
  SET_MORE_EVENTS_DATE,
  SET_SELECTED_ENROLL_CAMPAIGN,
  SET_SMART_ENROLL_GENERATED,
  SET_SMART_ENROLL_LOADING,
} from "../types";

export function setCampaignsAction(campaigns) {
  return {
    type: SET_CAMPAIGNS,
    payload: {
      campaigns: campaigns,
    },
  };
}

export function setCampaignTagsAction(campaignTags) {
  return {
    type: SET_CAMPAIGN_TAGS,
    payload: {
      campaignTags: campaignTags,
    },
  };
}

export function setSelectedEnrollCampaignAction(campaign) {
  return {
    type: SET_SELECTED_ENROLL_CAMPAIGN,
    payload: {
      campaign: campaign,
    },
  };
}
export function setSelectedCampaignAction(campaign) {
  return {
    type: SET_SELECTED_CAMPAIGN,
    payload: {
      campaign: campaign,
    },
  };
}

export function setSelectedCampaignIdsAction(campaignIds) {
  return {
    type: SET_SELECTED_CAMPAIGN_IDS,
    payload: {
      campaignIds: campaignIds,
    },
  };
}

export function setSelectedTabAction(selectedTab) {
  return {
    type: SET_SELECTED_TAB,
    payload: {
      selectedTab: selectedTab,
    },
  };
}

export function setSelectedStartDateAction(selectedStartDate) {
  return {
    type: SET_SELECTED_START_DATE,
    payload: {
      selectedStartDate: selectedStartDate,
    },
  };
}

export function setStartDateShortcutAction(activeStartDateShortcut) {
  return {
    type: SET_START_DATE_SHORTCUT,
    payload: {
      activeStartDateShortcut: activeStartDateShortcut,
    },
  };
}

export function setUpcomingMessagesAction(upcomingMessages) {
  return {
    type: SET_UPCOMING_MESSAGES,
    payload: {
      upcomingMessages: upcomingMessages,
    },
  };
}

export function setSelectedUpcomingPositionAction(position) {
  return {
    type: SET_SELECTED_UPCOMING_POSITION,
    payload: {
      position: position,
    },
  };
}

export function setMoreEventsPositionAction(position) {
  return {
    type: SET_MORE_EVENTS_POSITION,
    payload: {
      position: position,
    },
  };
}

export function setMoreEventsDateAction(date) {
  return {
    type: SET_MORE_EVENTS_DATE,
    payload: {
      date: date,
    },
  };
}

export function setSelectedEventAction(selectedEvent) {
  return {
    type: SET_SELECTED_EVENT,
    payload: {
      selectedEvent: selectedEvent,
    },
  };
}

export function setSelectedEnrollPatientsAction(selectedPatients) {
  return {
    type: SET_SELECTED_ENROLL_PATIENTS,
    payload: {
      selectedPatients: selectedPatients,
    },
  };
}

export function setCurrentViewAction(currentView) {
  return {
    type: SET_CURRENT_VIEW,
    payload: {
      currentView: currentView,
    },
  };
}

export function setRerenderEnrollFieldsAction(rerenderEnrollFields) {
  return {
    type: SET_RERENDER_ENROLL_FIELDS,
    payload: {
      rerenderEnrollFields: rerenderEnrollFields,
    },
  };
}

export function setEnrollFieldsAction(enrollFields) {
  return {
    type: SET_ENROLL_FIELDS,
    payload: {
      enrollFields: enrollFields,
    },
  };
}

export function setCampaignLoaderAction(loader) {
  return {
    type: SET_CAMPAIGN_LOADER,
    payload: {
      loader: loader,
    },
  };
}

export function setSmartEnrollGeneratedAction(smartEnrollGenerated) {
  return {
    type: SET_SMART_ENROLL_GENERATED,
    payload: {
      smartEnrollGenerated: smartEnrollGenerated,
    },
  };
}

export function setSmartEnrollLoadingAction(smartEnrollLoading) {
  return {
    type: SET_SMART_ENROLL_LOADING,
    payload: {
      smartEnrollLoading: smartEnrollLoading,
    },
  };
}
