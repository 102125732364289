import { useEffect, useState } from "react";
import {
  getFormattedTimeWithSeconds,
  parseDateString,
} from "../../../helpers/helpers";
import { useAppSelector } from "../../../store";

export const useTimerDisplay = (isRecording, startedAt, offset) => {
  const currentTime = useAppSelector((state) => state.timer.currentTime);

  const [formattedTime, setFormattedTime] = useState(
    getFormattedTimeWithSeconds(offset / 1000),
  );

  useEffect(() => {
    let seconds = offset / 1000;
    if (!isRecording) {
      setFormattedTime(getFormattedTimeWithSeconds(seconds));
      return;
    }

    if (startedAt) {
      seconds += Math.floor(
        Math.max(
          0,
          (currentTime.getTime() - parseDateString(startedAt).getTime()) / 1000,
        ),
      );
    }

    setFormattedTime(getFormattedTimeWithSeconds(seconds));
  }, [offset, currentTime]);

  return { formattedTime };
};
