import { createContext, useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "../../store";
import { setCurrentTime } from "./store/timerSlice";

export const TimerContext = createContext(new Date());

const TimerProvider = ({ children }) => {
  const [currentTime, setCurrentTimeState] = useState(new Date());
  const dispatch = useAppDispatch();

  const updateCurrentTime = useCallback(() => {
    const newTime = new Date();
    setCurrentTimeState(newTime);
    dispatch(setCurrentTime(newTime));
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(updateCurrentTime, 1000);
    return () => clearInterval(interval);
  }, [updateCurrentTime]);

  return (
    <TimerContext.Provider value={currentTime}>
      {children}
    </TimerContext.Provider>
  );
};

export default TimerProvider;
