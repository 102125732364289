import type { AxiosError } from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CallGrayIcon } from "../../assets/icons/calling-gray.svg";
import { ReactComponent as CallIcon } from "../../assets/icons/calling.svg";
import { ReactComponent as MessageIcon } from "../../assets/icons/chat-round.svg";
import { ReactComponent as ScribeIcon } from "../../assets/icons/microphone-scribe.svg";
import { ReactComponent as MinimizeIcon } from "../../assets/icons/minimize-square.svg";
import {
  patientPanelTabs,
  twilioDeviceStatuses,
} from "../../helpers/constants";
import { getFormattedTimeWithSeconds } from "../../helpers/helpers";
import { useNoSoundNotification } from "../../hooks/useNoSoundNotification";
import { store, useAppDispatch, useAppSelector } from "../../store";
import { setLastSelectedPatientProfileAction } from "../../store/patient/actions";
import type { Patient } from "../../store/patient/interfaces";
import { setSelectedSettingsTabAction } from "../../store/user/actions";
import {
  setOpenCallModalAction,
  setOpenCallOptionsModalAction,
} from "../../store/voiceRecorder/actions";
import {
  sendFinishScribe,
  sendPatientAudioPart,
} from "../../store/voiceRecorder/thunks";
import AddPhoneNumber from "../Basic/AddPhoneNumber";
import TabButton from "../Basic/TabButton";
import RecordingContextStalled from "../Basic/Warning/RecordingContextStalled";
import FinishLaterModal from "../IndependentScribe/FinishLaterModal";
import useRecorder from "../IndependentScribe/Recorder";
import RecordingIndicator from "../IndependentScribe/RecordingIndicator";
import TimerProvider from "../IndependentScribe/TimerProvider";
import {
  ERROR_NOTE_GENERATION_DELAYED,
  ERROR_NOT_ENOUGH_TRANSCRIPT,
} from "../IndependentScribe/consts";
import { useScribeGenerationDelay } from "../IndependentScribe/hooks/useScribeGenerationDelay";
import { useTimerDisplay } from "../IndependentScribe/hooks/useTimerDisplay";
import { setShowScribeNoteTemplate } from "../IndependentScribe/store/noteTemplateSlice";
import {
  type FixLater,
  setInterruptedRecordingAction,
  setScribe,
  setScribePaused,
  setScribeSelectedAudioId,
  setScribeTogglePause,
} from "../IndependentScribe/store/scribeSlice";
import {
  getByPatientId,
  getRecordingScribe,
  getSelectedScribe,
} from "../IndependentScribe/store/selectors";
import { SetInterruptedRecordingActionType } from "../IndependentScribe/store/types";
import { useNewEnrollmentLogic } from "../PatientProfile/CareCoordination/useNewEnrollmentLogic";
import PatientShortInfo from "../PatientProfile/PatientShortInfo";
import Call from "../PatientProfile/RightPanel/Call/Call";
import CallOptions from "../PatientProfile/RightPanel/Call/CallOptions";
import MessageTab from "../PatientProfile/RightPanel/Message/MessageTab";
import PatientScribe from "../PatientProfile/RightPanel/Scribe/PatientScribe";

const ChatCallScribePanel = ({
  handleCall,
  handleDisconnect,
  handleMute,
  isMuted,
  conversation,
  patient,
  selectedTab,
  setSelectedTab,
  messages,
  setMessages,
  scribeTab = false,
}: {
  handleCall: (phoneNumber: string) => void;
  handleDisconnect: () => void;
  handleMute: () => void;
  isMuted: boolean;
  conversation: FixLater;
  patient: Patient;
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
  messages: FixLater;
  setMessages: (messages: any) => void;
  scribeTab?: boolean;
}) => {
  const { openCallModal, deviceStatus } = useAppSelector(
    (state) => state.voiceRecorder,
  );
  const { interruptedRecordingAction } = useAppSelector(
    (state) => state.scribe,
  );
  const { selectedScribeNoteTemplate } = useAppSelector(
    (state) => state.noteTemplate,
  );
  const {
    selectedPatientInfo,
    selectedPatientEnrollments,
    // patientRecordingScribeTitle, // TODO
  } = useAppSelector((state) => state.patient);
  const { pathBeforeProfile } = useAppSelector((state) => state.ui);
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const selectedScribe = useAppSelector(getSelectedScribe);
  const recordingScribe = useAppSelector(getRecordingScribe);
  const scribes = useAppSelector((state) =>
    getByPatientId(state, Number(selectedPatientInfo?.patient_id)),
  );

  const recordingAndNotOnScribeTab =
    selectedScribe?.isRecording && selectedTab !== patientPanelTabs.SCRIBE;

  const callingAndNotOnCallTab =
    deviceStatus === twilioDeviceStatuses.IN_PROGRESS &&
    selectedTab !== patientPanelTabs.CALL;
  const callInterval = useRef(null);

  const { formattedTime } = useTimerDisplay(
    selectedScribe?.isRecording,
    selectedScribe?.startedAt,
    selectedScribe?.accumulatedDuration,
  );
  const [showFinishLaterModal, setShowFinishLaterModal] = useState(false);
  const notEnoughtTranscriptAudioIdRef = useRef<string | null>(null);
  const [notEnoughTranscript, setNotEnoughTranscript] = useState(false);
  const [callSeconds, setCallSeconds] = useState(0);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { handleNewEnrollment } = useNewEnrollmentLogic();
  const {
    showNoteGenerationDelayedWarning,
    hideNoteGenerationDelayedWarning,
    setShowNoteGenerationDelayedWarning,
    setScribeGenerationDelayed,
  } = useScribeGenerationDelay(selectedScribe);

  useEffect(() => {
    if (recordingScribe) {
      setNotEnoughTranscript(false);
    }
  }, [recordingScribe]);

  useEffect(() => {
    if (callInterval.current) {
      clearInterval(callInterval.current);
      callInterval.current = null;
    }

    if (deviceStatus !== twilioDeviceStatuses.IN_PROGRESS) {
      setCallSeconds(0);

      return;
    }

    callInterval.current = setInterval(() => {
      setCallSeconds((callSeconds) => callSeconds + 1);
    }, 1000);

    return () => {
      if (callInterval.current) {
        clearInterval(callInterval.current);
        callInterval.current = null;
      }
    };
  }, [deviceStatus]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (recordingScribe) {
        event.preventDefault();
        event.returnValue = "Are you sure you want to cancel the recording?";
      }
    };

    const handlePopState = (_) => {
      if (recordingScribe) {
        history.pushState(null, null, window.location.href);
        setShowFinishLaterModal(true);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopState);
    history.pushState(null, null, window.location.href);

    return () => {
      if (recordingScribe) {
        onCancel();
        cancelRecording();
      } else {
        setNotEnoughTranscript(false);
        setShowFinishLaterModal(false);
        dispatch(setInterruptedRecordingAction(null));
      }

      if (
        !selectedScribe?.note &&
        (selectedScribe?.isPaused || selectedScribe?.isRecording)
      ) {
        dispatch(setShowScribeNoteTemplate(false));
      }

      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    setShowFinishLaterModal(!!interruptedRecordingAction);
  }, [interruptedRecordingAction]);

  const sendAudioPart = useCallback(
    (data: Blob, chunkPosition: number) => {
      dispatch(
        sendPatientAudioPart(
          patient?.patient_id,
          data,
          () => {},
          () => {},
          selectedScribeNoteTemplate
            ? selectedScribeNoteTemplate.note_template_id
            : null,
          chunkPosition,
        ),
      );
    },
    [
      dispatch,
      patient?.patient_id,
      selectedScribe?.patient,
      selectedScribe?.audioId,
      selectedScribeNoteTemplate,
    ],
  );

  const finishRecording = (withTranscriptAlert = true) => {
    dispatch(
      sendFinishScribe(
        patient?.patient_id,
        () => {},
        (error: AxiosError) => {
          if (
            (error.response.data as FixLater).error ===
            ERROR_NOT_ENOUGH_TRANSCRIPT
          ) {
            dispatch(setScribePaused());

            if (withTranscriptAlert) {
              setNotEnoughTranscript(true);
              notEnoughtTranscriptAudioIdRef.current = selectedScribe?.audioId;
            }
          }

          if (
            (error.response?.data as FixLater)?.error ===
            ERROR_NOTE_GENERATION_DELAYED
          ) {
            setScribeGenerationDelayed(selectedScribe.audioId, true);
          }
        },
      ),
    );
  };

  const {
    mediaRecorder,
    audioVolume,
    noAudioDetected,
    microphones,
    microphoneId,
    setMicrophoneId,
    startRecording,
    togglePause,
    cancelRecording,
    saveRecording,
    isStalled,
    isReadyToRecord,
    readyToRecordErrors,
    requestMicrophonePermissions,
  } = useRecorder(sendAudioPart, finishRecording);

  const handleTogglePause = () => {
    togglePause();
    dispatch(setScribeTogglePause());
  };

  useNoSoundNotification(
    isReadyToRecord,
    isStalled,
    selectedScribe,
    requestMicrophonePermissions,
  );

  const onCancel = useCallback(
    (actionType = "") => {
      if (
        actionType !==
          SetInterruptedRecordingActionType.PATIENT_CHANGE_SELECTED_AUDIO_ID_WHILE_RECORDING &&
        actionType !== SetInterruptedRecordingActionType.PATIENT_CALL &&
        actionType !==
          SetInterruptedRecordingActionType.CLOSE_PANEL_WHILE_RECORDING
      ) {
        dispatch(setScribeSelectedAudioId({ audioId: null }));
        dispatch(setScribePaused());
      }

      setNotEnoughTranscript(false);
      setShowFinishLaterModal(false);
      setShowNoteGenerationDelayedWarning(false);
      dispatch(setInterruptedRecordingAction(null));
    },
    [dispatch],
  );

  const onCall = () => {
    if (recordingScribe) {
      dispatch(
        setInterruptedRecordingAction({
          type: SetInterruptedRecordingActionType.PATIENT_CALL,
        }),
      );
      return;
    }

    dispatch(setOpenCallModalAction(true));
    dispatch(setShowScribeNoteTemplate(false));
    dispatch(setScribeSelectedAudioId({ audioId: null }));
    handleCall(patient?.phone_number);
  };

  const handleCancelRecording = useCallback(() => {
    onCancel(interruptedRecordingAction?.type);

    if (
      interruptedRecordingAction?.type ===
      SetInterruptedRecordingActionType.NAVIGATION
    ) {
      navigate(interruptedRecordingAction.value);
    } else if (
      interruptedRecordingAction?.type ===
      SetInterruptedRecordingActionType.PATIENT_PROFILE_CLOSE
    ) {
      dispatch(
        setLastSelectedPatientProfileAction({
          info: selectedPatientInfo,
          notes: scribes,
          enrollments: selectedPatientEnrollments,
        }),
      );
      navigate(pathBeforeProfile);
    } else if (
      interruptedRecordingAction?.type ===
      SetInterruptedRecordingActionType.NEW_ENROLLMENT
    ) {
      handleNewEnrollment();
    } else if (
      interruptedRecordingAction?.type ===
      SetInterruptedRecordingActionType.SETTINGS_NAVIGATION
    ) {
      const { selectedSettingsTab } = store.getState().user;

      if (!selectedSettingsTab) {
        dispatch(setSelectedSettingsTabAction("Profile"));
      }

      navigate(interruptedRecordingAction.value);
    } else if (
      interruptedRecordingAction?.type ===
        SetInterruptedRecordingActionType.PATIENT_CHANGE_SELECTED_AUDIO_ID_WHILE_RECORDING ||
      interruptedRecordingAction?.type ===
        SetInterruptedRecordingActionType.PATIENT_CALL ||
      interruptedRecordingAction?.type ===
        SetInterruptedRecordingActionType.CLOSE_PANEL_WHILE_RECORDING
    ) {
      togglePause();
      dispatch(setShowScribeNoteTemplate(false));
      dispatch(setScribeSelectedAudioId({ audioId: null }));
      dispatch(setScribePaused());
    }

    if (
      interruptedRecordingAction?.type ===
      SetInterruptedRecordingActionType.PATIENT_CALL
    ) {
      dispatch(setOpenCallModalAction(true));
      handleCall(patient?.phone_number);
    }
  }, [
    dispatch,
    onCancel,
    interruptedRecordingAction,
    selectedPatientInfo,
    scribes,
    selectedPatientEnrollments,
    handleNewEnrollment,
    handleCall,
    patient,
  ]);

  return (
    <>
      <div className="bg-white md:rounded-xl md:border border-gray-200 w-full h-full grid grid-rows-tab-layout overflow-hidden">
        <div>
          <div className="md:hidden grid items-start grid-cols-conv-layout w-full min-w-0 px-4 py-3 border-b truncate">
            <div className="truncate">
              {patient && (
                <>
                  <p className="font-semibold text-base mt-1">
                    {patient.preferred_name
                      ? patient.preferred_name
                      : patient.first_name}{" "}
                    {patient.last_name}
                  </p>
                  <PatientShortInfo
                    patient={patient}
                    className="mt-1 pb-0.5 truncate text-xs text-tertiary"
                  />
                </>
              )}
            </div>
            <button
              type="button"
              className="w-6 h-8 md:w-8 flex items-center justify-center flex-none"
              onClick={() => {
                if (!isDesktop && recordingScribe) {
                  dispatch(
                    setInterruptedRecordingAction({
                      type: SetInterruptedRecordingActionType.CLOSE_PANEL_WHILE_RECORDING,
                    }),
                  );

                  return;
                }

                dispatch(setOpenCallOptionsModalAction(false));
              }}
            >
              <MinimizeIcon width="24" height="24" stroke="#121212" />
            </button>
          </div>

          <div
            className={`grid h-[52px] lg:h-16 p-2 border-b
            ${scribeTab ? "grid-cols-3" : "grid-cols-2"}`}
          >
            <TabButton
              icon={
                <MessageIcon
                  width="20"
                  height="20"
                  stroke={
                    selectedTab === patientPanelTabs.MESSAGE
                      ? "#2970FF"
                      : "#667085"
                  }
                />
              }
              label={patientPanelTabs.MESSAGE}
              active={selectedTab}
              onClick={() => setSelectedTab(patientPanelTabs.MESSAGE)}
            />
            <TabButton
              rightIcon={
                callingAndNotOnCallTab ? (
                  <RecordingIndicator
                    size={20}
                    isRecording={callingAndNotOnCallTab}
                  />
                ) : null
              }
              icon={
                selectedTab === patientPanelTabs.CALL ? (
                  <CallIcon width="20" height="20" />
                ) : (
                  <CallGrayIcon width="20" height="20" />
                )
              }
              label={
                callingAndNotOnCallTab
                  ? getFormattedTimeWithSeconds(callSeconds)
                  : patientPanelTabs.CALL
              }
              active={selectedTab}
              onClick={() => setSelectedTab(patientPanelTabs.CALL)}
            />
            {scribeTab && (
              <TimerProvider>
                <TabButton
                  rightIcon={
                    recordingAndNotOnScribeTab ? (
                      <RecordingIndicator
                        size={20}
                        isRecording={selectedScribe.isRecording}
                      />
                    ) : null
                  }
                  icon={
                    <ScribeIcon
                      width="20"
                      height="20"
                      stroke={
                        selectedTab === patientPanelTabs.SCRIBE
                          ? "#2970FF"
                          : "#667085"
                      }
                    />
                  }
                  label={
                    recordingAndNotOnScribeTab
                      ? formattedTime
                      : patientPanelTabs.SCRIBE
                  }
                  active={selectedTab}
                  onClick={() => setSelectedTab(patientPanelTabs.SCRIBE)}
                />
              </TimerProvider>
            )}
          </div>
        </div>
        <div className="flex overflow-hidden">
          {selectedTab === patientPanelTabs.CALL &&
            (openCallModal ? (
              <div className="overflow-y-auto scrollbar h-full w-full">
                <Call
                  onClose={handleDisconnect}
                  handleMute={handleMute}
                  isMuted={isMuted}
                  patient={patient}
                  callSeconds={callSeconds}
                />
              </div>
            ) : patient.phone_number ? (
              <div className="pl-5 py-3 height-sm:py-5 pr-2 bg-gray-background2 h-full w-full">
                <CallOptions
                  className="h-full min-h-0 overflow-y-auto scrollbar pr-3"
                  onCall={onCall}
                  patient={patient}
                />
              </div>
            ) : (
              <AddPhoneNumber patientId={patient.patient_id} call />
            ))}

          {selectedTab === patientPanelTabs.MESSAGE && (
            <MessageTab
              patientInfo={patient}
              conversation={conversation}
              messages={messages}
              setMessages={setMessages}
            />
          )}

          {selectedTab === patientPanelTabs.SCRIBE && (
            <PatientScribe
              onCancel={onCancel}
              mediaRecorder={mediaRecorder}
              audioVolume={audioVolume}
              noAudioDetected={noAudioDetected}
              togglePause={togglePause}
              cancelRecording={cancelRecording}
              saveRecording={saveRecording}
              startRecording={startRecording}
              microphoneId={microphoneId}
              setMicrophoneId={setMicrophoneId}
              microphones={microphones}
              notEnoughTranscript={notEnoughTranscript}
              setNotEnoughTranscript={setNotEnoughTranscript}
              isReadyToRecord={isReadyToRecord}
              readyToRecordErrors={readyToRecordErrors}
              requestMicrophonePermissions={requestMicrophonePermissions}
              isStalled={isStalled}
              showNoteGenerationDelayedWarning={
                showNoteGenerationDelayedWarning
              }
              hideNoteGenerationDelayedWarning={
                hideNoteGenerationDelayedWarning
              }
            />
          )}
        </div>
      </div>

      {showFinishLaterModal && (
        <FinishLaterModal
          onClose={() => {
            setShowFinishLaterModal(false);
            dispatch(setInterruptedRecordingAction(null));
          }}
          onConfirm={handleCancelRecording}
        />
      )}
      {isStalled &&
        (recordingScribe?.isRecording || !recordingScribe?.isRecording) &&
        !isDesktop && (
          <RecordingContextStalled
            // onCancel={handleTogglePause}
            // onSubmit={() => {
            //   togglePause();
            //   cancelRecording();
            //   onCancel();
            //   dispatch(setShowScribeNoteTemplate(false));
            // }}
            onSubmit={() => window.location.reload()}
          />
        )}
    </>
  );
};

export default ChatCallScribePanel;
