const ClinicInfoCard = ({ icon, title, value }) => {
  return (
    <div className="flex items-start space-x-3 p-4">
      {icon}
      <div>
        <p className="text-base font-bold mb-1.5">{title}</p>
        <p className="text-sm font-medium text-tertiary">{value || "-"}</p>
      </div>
    </div>
  );
};

export default ClinicInfoCard;
