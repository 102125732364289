import { useMemo } from "react";
import { useSelector } from "react-redux";
import { campaignTypes } from "../../helpers/constants";
import Checkbox from "../Basic/Checkbox";

const statuses = ["Enrolled", "Eligible", "No Consent"];

const FilterForm = ({
  className,
  selectedTags,
  setSelectedTags,
  selectedStatuses,
  setSelectedStatuses,
  selectedInsurances,
  setSelectedInsurances,
}) => {
  const { campaigns } = useSelector((state) => state.campaign);

  const campaignTags = useMemo(() => {
    const tags = [];
    if (campaigns?.length > 0) {
      campaigns.forEach((campaign) => {
        const type = campaignTypes.find(
          (type) => type.value === campaign.campaign_type,
        );
        if (type) {
          tags.push(type.text);
        }
      });
    }
    return [...tags, "No Enrollments"];
  }, [campaigns, campaignTypes]);

  return (
    <div className={`text-base font-semibold ${className}`}>
      <p className="mb-2">Care coordination</p>
      {campaignTags?.map((tag) => (
        <Checkbox
          key={tag}
          className="py-1.5 font-medium"
          variant="primary-filled"
          label={tag}
          checked={selectedTags.find((selectedTag) => selectedTag === tag)}
          onChange={() => {
            setSelectedTags((prev) =>
              prev.includes(tag)
                ? prev.filter((selectedTag) => selectedTag !== tag)
                : [...prev, tag],
            );
          }}
        />
      ))}

      <hr className="border-gray-foreground my-3" />
      <p className="mb-2">Status</p>
      {statuses.map((status) => (
        <Checkbox
          key={status}
          className="py-1.5 font-medium"
          variant="primary-filled"
          label={status}
          checked={selectedStatuses.find(
            (selectedStatus) => selectedStatus === status,
          )}
          onChange={() => {
            setSelectedStatuses((prev) =>
              prev.includes(status)
                ? prev.filter((selectedStatus) => selectedStatus !== status)
                : [...prev, status],
            );
          }}
        />
      ))}

      <hr className="border-gray-foreground my-3" />
      <p className="mb-2">Insurance</p>
      <Checkbox
        key="Medicare"
        className="py-1.5 font-medium"
        variant="primary-filled"
        label="Medicare"
        checked={selectedInsurances.find(
          (selectedInsurance) => selectedInsurance === "Medicare",
        )}
        onChange={() => {
          setSelectedInsurances((prev) =>
            prev.includes("Medicare")
              ? prev.filter(
                  (selectedInsurance) => selectedInsurance !== "Medicare",
                )
              : [...prev, "Medicare"],
          );
        }}
      />
    </div>
  );
};

export default FilterForm;
