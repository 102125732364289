import Skeleton from "@mui/material/Skeleton";
import UserTable from "../Settings/Organization/UserTable";

const FacilitiesTableSkeleton = ({
  rows = 10,
  bgColor = "#EAEDF3",
  animation = "wave",
}) => {
  const columns = [
    { label: "Clinic Name", accessor: "name" },
    { label: "Number of providers", accessor: "providers" },
    { label: "Number of teams", accessor: "total_teams" },
    { label: "Number of patients", accessor: "total_patients" },
    { label: "Phone Number", accessor: "phone_number" },
  ];

  const getTableData = () => {
    const tableData = [];
    for (let i = 0; i < rows; i++) {
      tableData.push({
        name: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={110}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
        providers: (
          <div className="flex -space-x-2.5">
            {[...Array(4)].map((_, index) => (
              <div
                key={`avatar-${index}`}
                className="bg-white rounded-full w-fit h-fit border-2 border-white relative"
              >
                <Skeleton
                  animation={animation}
                  variant="circular"
                  width={32}
                  height={32}
                  sx={{ bgcolor: bgColor }}
                />
              </div>
            ))}
          </div>
        ),
        total_teams: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={70}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
        total_patients: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={70}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
        phone_number: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={110}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
      });
    }
    return tableData;
  };

  return (
    <div className="grid w-full">
      <UserTable
        columns={columns}
        data={getTableData()}
        className="hidden md:block"
      />

      <div className="block md:hidden space-y-3">
        {[...Array(rows)].map((_, index) => (
          <div
            key={`facility-${index}`}
            className="p-4 rounded-xl border-b border-gray-foreground space-y-4 bg-white"
          >
            <div className="space-y-0.5">
              <Skeleton
                animation={animation}
                variant="rounded"
                width={160}
                height={14}
                sx={{ bgcolor: bgColor, borderRadius: "14px" }}
              />
              <Skeleton
                animation={animation}
                variant="rounded"
                width={100}
                height={12}
                sx={{ bgcolor: bgColor, borderRadius: "12px" }}
              />
            </div>
            <div className="grid grid-cols-[auto_auto_auto] divide-x border-gray-foreground">
              <div className="flex flex-col space-y-1 pr-3">
                <Skeleton
                  animation={animation}
                  variant="rounded"
                  width={80}
                  height={12}
                  sx={{ bgcolor: bgColor, borderRadius: "12px" }}
                />
                <div className="flex -space-x-2.5">
                  {[...Array(4)].map((_, index) => (
                    <div
                      key={`avatar-${index}`}
                      className="bg-white rounded-full w-fit h-fit border-2 border-white relative"
                    >
                      <Skeleton
                        animation={animation}
                        variant="circular"
                        width={32}
                        height={32}
                        sx={{ bgcolor: bgColor }}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex flex-col space-y-3 font-medium px-3">
                <Skeleton
                  animation={animation}
                  variant="rounded"
                  width={52}
                  height={12}
                  sx={{ bgcolor: bgColor, borderRadius: "12px" }}
                />
                <Skeleton
                  animation={animation}
                  variant="rounded"
                  width={50}
                  height={24}
                  sx={{ bgcolor: bgColor, borderRadius: "24px" }}
                />
              </div>

              <div className="flex flex-col space-y-3 font-medium pl-3">
                <Skeleton
                  animation={animation}
                  variant="rounded"
                  width={52}
                  height={12}
                  sx={{ bgcolor: bgColor, borderRadius: "12px" }}
                />
                <Skeleton
                  animation={animation}
                  variant="rounded"
                  width={50}
                  height={24}
                  sx={{ bgcolor: bgColor, borderRadius: "24px" }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FacilitiesTableSkeleton;
