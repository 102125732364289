import { useState } from "react";
import Checkbox from "../../Basic/Checkbox";

const RafCard = ({ tag, title, data }) => {
  const [accept, setAccept] = useState(false);
  const [hold, setHold] = useState(false);
  const [reject, setReject] = useState(false);

  return (
    <div className="w-full p-3 rounded-lg border border-gray-300 bg-white text-sm font-medium">
      <div className="flex items-center justify-between space-x-3 mb-2.5">
        <div className="py-1 px-3 rounded-full bg-gray-foreground text-tertiary font-semibold">
          {tag}
        </div>
        <div className="flex items-center space-x-4">
          <Checkbox
            onChange={() => setAccept(!accept)}
            checked={accept}
            label="Accept"
          />
          <Checkbox
            onChange={() => setHold(!hold)}
            checked={hold}
            label="Hold"
          />
          <Checkbox
            onChange={() => setReject(!reject)}
            checked={reject}
            label="Reject"
          />
        </div>
      </div>
      <p>{title}</p>
      {data && (
        <div className="mt-2.5 flex justify-between">
          {Object.entries(data).map(([key, value], index) => (
            <div key={index} className="">
              <p className="text-tertiary text-xs">{key}:</p>
              <p>{value}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default RafCard;
