import { useMediaQuery } from "react-responsive";
import notEnoughTranscriptImage from "../../../assets/not-enough-transcript.svg";
import Warning from "./index";

const NotEnoughTranscript = ({
  forceHorizontal = false,
  onSubmit = () => {},
  onCancel = () => {},
  className = "",
}: {
  forceHorizontal?: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
  className?: string;
}) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return (
    <Warning
      image={notEnoughTranscriptImage}
      title="Short Visit"
      description="Continue speaking to complete note"
      submitText="Continue"
      horizontal={forceHorizontal || !isDesktop}
      onSubmit={onSubmit}
      onCancel={onCancel}
      className={className}
    />
  );
};

export default NotEnoughTranscript;
