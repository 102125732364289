import {
  SET_IS_DATE_PICKER_OPEN,
  SET_IS_MODAL_OPEN,
  SET_PATH_BEFORE_PROFILE,
} from "../types";

export function setIsModalOpen(isModalOpen) {
  return {
    type: SET_IS_MODAL_OPEN,
    payload: {
      isModalOpen,
    },
  };
}

export function setIsDatePickerOpen(isDatePickerOpen) {
  return {
    type: SET_IS_DATE_PICKER_OPEN,
    payload: {
      isDatePickerOpen,
    },
  };
}

export function setPathBeforeProfile(pathBeforeProfile) {
  return {
    type: SET_PATH_BEFORE_PROFILE,
    payload: {
      pathBeforeProfile,
    },
  };
}
