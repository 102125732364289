import { useCallback, useEffect, useRef, useState } from "react";
import { throttle } from "../../../helpers/throttle";
import type {
  AudioCleanupError,
  AudioContextState,
  AudioNodes,
  AudioSetupContext,
} from "../../../types/audio";
import {
  connectAudioNodes,
  determineMediaRecorderOptions,
  retryOperation,
  setupAudioNodes,
} from "./audioOperations";

export function useAudioContext(
  microphoneId: string,
  permissionGranted: boolean,
) {
  const audioContextRef = useRef<AudioContext | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioNodesRef = useRef<AudioNodes | null>(null);
  const audioStreamRef = useRef<MediaStream | null>(null);
  const [contextState, setContextState] = useState<AudioContextState>({
    isInitialized: false,
    hasError: false,
  });

  const cleanup = useCallback(async () => {
    const errors: AudioCleanupError[] = [];

    try {
      if (audioStreamRef.current) {
        const trackPromises = audioStreamRef.current
          .getTracks()
          .map(async (track) => {
            try {
              track.stop();
            } catch (error) {
              errors.push({
                name: "TrackStopError",
                message: `Failed to stop track: ${error.message}`,
                type: "TRACK_STOP",
                originalError: error,
              } as AudioCleanupError);
            }
          });
        await Promise.all(trackPromises);
      }

      if (
        audioContextRef.current &&
        audioContextRef.current.state !== "closed"
      ) {
        try {
          await audioContextRef.current.close();
        } catch (error) {
          errors.push({
            name: "ContextCloseError",
            message: `Failed to close audio context: ${error.message}`,
            type: "CONTEXT_CLOSE",
            originalError: error,
          } as AudioCleanupError);
        }
      }

      if (mediaRecorderRef.current?.state !== "inactive") {
        try {
          mediaRecorderRef.current?.stop();
        } catch (error) {
          errors.push({
            name: "RecorderStopError",
            message: `Failed to stop media recorder: ${error.message}`,
            type: "RECORDER_STOP",
            originalError: error,
          } as AudioCleanupError);
        }
      }

      // Reset refs
      audioContextRef.current = null;
      mediaRecorderRef.current = null;
      audioNodesRef.current = null;
      audioStreamRef.current = null;

      if (errors.length > 0) {
        setContextState({
          isInitialized: false,
          hasError: true,
          error: errors[0], // Store the first error for now
        });
        throw errors;
      }
    } catch (error) {
      console.error("Cleanup failed:", error);
      throw error;
    }
  }, []);

  const setupAudio = useCallback(async () => {
    if (!permissionGranted || !microphoneId) {
      return;
    }

    try {
      // Clean up existing audio setup
      await cleanup();

      // Get user media with retry logic
      const stream = await retryOperation(
        () =>
          navigator.mediaDevices.getUserMedia({
            audio: {
              deviceId: { ideal: microphoneId },
              echoCancellation: true,
              noiseSuppression: true,
              autoGainControl: true,
            },
          }),
        3,
        1000,
      );

      audioStreamRef.current = stream;

      // Initialize AudioContext with proper error handling
      const AudioContextClass =
        window.AudioContext || (window as any).webkitAudioContext;
      audioContextRef.current = new AudioContextClass();
      await audioContextRef.current.resume();

      // Setup audio nodes with error boundary
      try {
        audioNodesRef.current = setupAudioNodes(audioContextRef.current);
      } catch (error) {
        throw new Error(`Failed to setup audio nodes: ${error.message}`);
      }

      // Connect nodes with validation
      const source = audioContextRef.current.createMediaStreamSource(stream);
      const destination =
        audioContextRef.current.createMediaStreamDestination();

      connectAudioNodes(source, audioNodesRef.current, destination);

      // Setup MediaRecorder with proper mime type detection
      const options = await determineMediaRecorderOptions();
      mediaRecorderRef.current = new MediaRecorder(destination.stream, options);

      setContextState({
        isInitialized: true,
        hasError: false,
      });
    } catch (error) {
      setContextState({
        isInitialized: false,
        hasError: true,
        error: error as AudioCleanupError,
      });
      console.error("Error setting up audio capture:", error);
      throw error;
    }
  }, [microphoneId, permissionGranted, cleanup]);

  // Throttled state checker
  const checkAudioState = useCallback(
    throttle(() => {
      if (audioContextRef.current?.state === "suspended") {
        audioContextRef.current.resume().catch(console.error);
      }
    }, 1000),
    [],
  );

  useEffect(() => {
    setupAudio().catch((error) => {
      console.error("Failed to setup audio context:", error);
    });

    const stateCheckInterval = setInterval(checkAudioState, 1000);

    return () => {
      clearInterval(stateCheckInterval);
      cleanup().catch((error) => {
        console.error("Failed to cleanup audio context:", error);
      });
    };
  }, [setupAudio, cleanup, checkAudioState]);

  const setupContext: AudioSetupContext = {
    audioContextRef,
    audioNodesRef,
    streamRef: audioStreamRef,
    setupAudio,
    cleanupAudio: cleanup,
  };

  return {
    audioContextRef,
    mediaRecorderRef,
    audioNodesRef,
    audioStreamRef,
    contextState,
    setupContext,
  };
}

export default useAudioContext;
