const UserPlusRoundedIcon = ({ stroke = "#FFF" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0008 8.33463C11.8418 8.33463 13.3341 6.84225 13.3341 5.0013C13.3341 3.16035 11.8418 1.66797 10.0008 1.66797C8.15986 1.66797 6.66748 3.16035 6.66748 5.0013C6.66748 6.84225 8.15986 8.33463 10.0008 8.33463Z"
        stroke={stroke}
        strokeWidth="1.25"
      />
      <path
        d="M14.1659 18.3346C16.0068 18.3346 17.4992 16.8423 17.4992 15.0013C17.4992 13.1604 16.0068 11.668 14.1659 11.668C12.3249 11.668 10.8325 13.1604 10.8325 15.0013C10.8325 16.8423 12.3249 18.3346 14.1659 18.3346Z"
        stroke={stroke}
        strokeWidth="1.25"
      />
      <path
        d="M14.1675 13.8887V16.1108"
        stroke={stroke}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0557 15H15.2779"
        stroke={stroke}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.6"
        d="M11.6675 17.3646C11.1394 17.4544 10.58 17.5026 10.0008 17.5026C6.77916 17.5026 4.16748 16.0102 4.16748 14.1693C4.16748 12.3284 6.77916 10.8359 10.0008 10.8359C11.4287 10.8359 12.7368 11.1291 13.7508 11.6159"
        stroke={stroke}
        strokeWidth="1.25"
      />
    </svg>
  );
};

export default UserPlusRoundedIcon;
