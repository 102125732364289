import {
  LOGOUT,
  SET_IS_DATE_PICKER_OPEN,
  SET_IS_MODAL_OPEN,
  SET_PATH_BEFORE_PROFILE,
} from "../types";
import LocalStorageService from "../../services/LocalStorageService";

const storageService = new LocalStorageService();

const init = {
  isModalOpen: false,
  isDatePickerOpen: false,
  pathBeforeProfile: storageService.getItem("pathBeforeProfile") ?? "/",
};

export default function uiReducer(state = init, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGOUT:
      storageService.removeItem("pathBeforeProfile");
      return { ...init };
    case SET_IS_MODAL_OPEN:
      return { ...state, isModalOpen: payload.isModalOpen };
    case SET_IS_DATE_PICKER_OPEN:
      return { ...state, isDatePickerOpen: payload.isDatePickerOpen };
    case SET_PATH_BEFORE_PROFILE:
      storageService.setItem("pathBeforeProfile", payload.pathBeforeProfile);
      return { ...state, pathBeforeProfile: payload.pathBeforeProfile };
    default:
      return state;
  }
}
