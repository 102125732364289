import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

const timerSlice = createSlice({
  name: "timer",
  initialState: {
    currentTime: new Date(),
  },
  reducers: {
    setCurrentTime: (state, action: PayloadAction<Date>) => {
      state.currentTime = action.payload;
    },
  },
});

export const { setCurrentTime } = timerSlice.actions;

export default timerSlice.reducer;
