import ErrorRoundExclamation from "./ErrorRoundExclamation";

const Input = ({
  label = null,
  type = null,
  name = null,
  disabled = false,
  value,
  required = false,
  withoutAsterisk = false,
  placeholder = null,
  onChange,
  className,
  inputClassName = "h-10",
  register = () => {},
  validate = () => {},
  validationPattern = "",
  minLength = null,
  min = null,
  max = null,
  error = null,
  errorExclamation = true,
  ...props
}) => {
  return (
    <div className={`flex flex-col text-sm ${className}`}>
      {label && (
        <p className="font-semibold mb-1.5">
          {label}
          {required && !withoutAsterisk && (
            <span className="text-red-600">*</span>
          )}
        </p>
      )}
      <div className="relative">
        <input
          name={name}
          type={type}
          disabled={disabled}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          {...register(name, {
            required: required,
            pattern: validationPattern,
            minLength: minLength,
            validate: validate,
            min: min,
            max: max,
          })}
          {...props}
          className={`py-2.5 px-3 rounded-lg border outline-none w-full
            ${error ? "border-red-400" : "border-gray-300"}
            ${disabled ? "bg-gray-150" : "bg-white"} ${inputClassName}`}
        />
        {error && errorExclamation && (
          <ErrorRoundExclamation className="absolute top-1/2 -translate-y-1/2 right-4" />
        )}
      </div>
      {error?.message && (
        <p className="text-red-400 font-normal mt-1.5">{error.message}</p>
      )}
    </div>
  );
};

export default Input;
