import { getAge } from "../../helpers/helpers";

const PatientShortInfo = ({ patient, className, showBirthdate = true }) => {
  const info = [
    patient.birthdate ? `${getAge(patient.birthdate)} yo` : "",
    patient.gender
      ? patient.gender.charAt(0).toUpperCase()
      : patient.sex
        ? patient.sex.charAt(0).toUpperCase()
        : "",
    patient.pronouns,
    patient.birthdate && showBirthdate
      ? new Date(patient.birthdate.replace(/-/g, "/")).toLocaleDateString(
          "en-us",
          {
            day: "numeric",
            month: "short",
            year: "numeric",
          },
        )
      : "",
    patient.chart_number,
  ]
    .filter(Boolean)
    .join(" ・ ");

  return (
    <div className={`font-medium flex items-center leading-none ${className}`}>
      {info}
    </div>
  );
};

export default PatientShortInfo;
