import Skeleton from "@mui/material/Skeleton";

const ConversationListSkeleton = ({
  conversations = 15,
  bgColor = "#EAEDF3",
  animation = "wave",
}) => {
  return (
    <div className="px-4">
      {[...Array(conversations)].map((_, index) => (
        <div
          key={`conversation-${index}`}
          className="rounded-xl pr-3 pl-2 py-2.5"
        >
          <div className="flex space-x-2.5">
            <Skeleton
              animation={animation}
              variant="circular"
              width={32}
              height={32}
              sx={{ bgcolor: bgColor, flex: "none" }}
            />
            <div className="w-full space-y-2">
              <div className="flex w-full justify-between gap-1">
                <Skeleton
                  animation={animation}
                  variant="rounded"
                  width={110}
                  height={16}
                  sx={{ bgcolor: bgColor, borderRadius: "16px" }}
                />
                <Skeleton
                  animation={animation}
                  variant="rounded"
                  width={68}
                  height={16}
                  sx={{ bgcolor: bgColor, borderRadius: "16px" }}
                />
              </div>
              <Skeleton
                animation="wave"
                variant="rounded"
                width={174}
                height={10}
                sx={{ bgcolor: bgColor, borderRadius: "10px" }}
              />
              <Skeleton
                animation="wave"
                variant="rounded"
                width={209}
                height={10}
                sx={{ bgcolor: bgColor, borderRadius: "10px" }}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ConversationListSkeleton;
