import axios from "axios";
import Bugsnag from "@bugsnag/js";
import { BASE_URL } from "../../helpers/config";
import { clearStorage } from "../../helpers/helpers";
import { setNotificationAction } from "../user/actions";
import {
  setFacilitiesAction,
  setFacilitiesLoadingAction,
  setUserFacilitiesAction,
} from "./actions";

export const getFacilities =
  (searchValue = "") =>
  (dispatch) => {
    axios
      .get(`${BASE_URL}/facility/list?search=${searchValue}`)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to load facilities",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(setFacilitiesAction(response.data.data.facilities));
        }
      })
      .catch((error) => {
        console.error(error);
        Bugsnag.notify(error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to load facilities",
          }),
        );
      })
      .finally(() => {
        dispatch(setFacilitiesLoadingAction(false));
      });
  };

export const selectFacility = (customerId) => (dispatch) => {
  axios
    .post(`${BASE_URL}/facility/select/${customerId}`)
    .then((response) => {
      if (response.data.error) {
        console.error(response.data.error);
        Bugsnag.notify(response.data.error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to change clinic",
          }),
        );
      } else {
        dispatch(
          setNotificationAction({
            status: "success",
            title: "Clinic changed",
            desc: "Clinic changed successfully",
          }),
        );
        clearStorage();
        window.location.reload();
      }
    })
    .catch((error) => {
      console.error(error);
      Bugsnag.notify(error);
      dispatch(
        setNotificationAction({
          status: "error",
          title: "Something went wrong",
          desc: "Failed to change clinic",
        }),
      );
    });
};

export const getUserFacilities = (doctorId) => (dispatch) => {
  axios
    .get(`${BASE_URL}/facility/user-list/${doctorId}`)
    .then((response) => {
      if (response.data.error) {
        console.error(response.data.error);
        Bugsnag.notify(response.data.error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to load user clinics",
          }),
        );
      } else {
        dispatch(
          setUserFacilitiesAction(doctorId, response.data.data.facilities),
        );
      }
    })
    .catch((error) => {
      console.error(error);
      Bugsnag.notify(error);
      dispatch(
        setNotificationAction({
          status: "error",
          title: "Something went wrong",
          desc: "Failed to load user clinics",
        }),
      );
    });
};

export const updateUserFacilities = (doctorId, facilityIds) => (dispatch) => {
  axios
    .post(`${BASE_URL}/facility/user/update/${doctorId}`, {
      facilities: facilityIds,
    })
    .then((response) => {
      if (response.data.error) {
        console.error(response.data.error);
        Bugsnag.notify(response.data.error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to update user clinics",
          }),
        );
      } else {
        dispatch(getUserFacilities(doctorId));
      }
    })
    .catch((error) => {
      console.error(error);
      Bugsnag.notify(error);
      dispatch(
        setNotificationAction({
          status: "error",
          title: "Something went wrong",
          desc: "Failed to update user clinics",
        }),
      );
    });
};
