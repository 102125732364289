const fieldTitleClasses = "text-sm text-tertiary";
const fieldValueClasses = "text-sm lg:text-base";

const InsuranceBlock = ({ title, insurance, className = "" }) => {
  return (
    <div className={className}>
      <p className="text-sm font-medium mb-3 uppercase tracking-wider ">
        {title}
      </p>
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-5 font-medium">
        <div className="col-span-2 lg:col-span-1">
          <p className={fieldTitleClasses}>Policy Holder’s Name</p>
          <p className={fieldValueClasses}>
            {insurance?.policy_holder_name || "-"}
          </p>
        </div>
        <div>
          <p className={fieldTitleClasses}>Holder’s DOB</p>
          <p className={fieldValueClasses}>
            {insurance?.holder_dob
              ? `${new Date(
                  insurance.holder_dob.replace(/-/g, "/"),
                ).toLocaleDateString("en-us", {
                  day: "numeric",
                })} ${new Date(
                  insurance.holder_dob.replace(/-/g, "/"),
                ).toLocaleDateString("en-us", {
                  month: "long",
                  year: "numeric",
                })}`
              : "-"}
          </p>
        </div>
        <div>
          <p className={fieldTitleClasses}>Holder’s SSN</p>
          <p className={fieldValueClasses}>{insurance?.holder_ssn || "-"}</p>
        </div>
        <div>
          <p className={fieldTitleClasses}>Relation to Holder</p>
          <p className={fieldValueClasses}>
            {insurance?.relationship_to_holder || "-"}
          </p>
        </div>
        <div>
          <p className={fieldTitleClasses}>Group Number</p>
          <p className={fieldValueClasses}>{insurance?.group_number || "-"}</p>
        </div>
        <div>
          <p className={fieldTitleClasses}>Policy Number</p>
          <p className={fieldValueClasses}>{insurance?.policy_number || "-"}</p>
        </div>
        <div className="lg:col-span-3">
          <p className={fieldTitleClasses}>Insurance</p>
          <p className={fieldValueClasses}>
            {insurance?.insurance_name || "-"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default InsuranceBlock;
