import { SET_TEMPLATES, SET_TEMPLATES_LOADER } from "../types";

export function setTemplatesAction(templates) {
  return {
    type: SET_TEMPLATES,
    payload: {
      templates: templates,
    },
  };
}

export function setTemplatesLoaderAction(loader) {
  return {
    type: SET_TEMPLATES_LOADER,
    payload: {
      loader: loader,
    },
  };
}
