import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import ModalMobile from "../../../Basic/ModalMobile";
import Modal from "../../../Basic/Modal";
import AddUserForm from "./AddUserForm";
import AddMultipleUsersForm from "./AddMultipleUsersForm";

const AddUserModal = ({ open, onClose }) => {
  const [openMultipleModal, setOpenMultipleModal] = useState(false);
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const onCloseModal = () => {
    onClose();
    setOpenMultipleModal(false);
  };

  return isDesktop ? (
    open && (
      <Modal className="pt-5 rounded-2xl">
        <div className="flex flex-col grow md:grow-0 sm:w-128 overflow-hidden">
          <p className="text-base md:text-xl font-semibold mb-5 px-5">
            Add new user
          </p>

          {openMultipleModal ? (
            <AddMultipleUsersForm onClose={onCloseModal} />
          ) : (
            <AddUserForm
              onClose={onCloseModal}
              onAddMultiple={() => setOpenMultipleModal(true)}
            />
          )}
        </div>
      </Modal>
    )
  ) : (
    <ModalMobile
      open={open}
      header="Add new user"
      childHeight={800}
      onClickAway={onCloseModal}
      className=""
    >
      {open &&
        (openMultipleModal ? (
          <AddMultipleUsersForm className="pt-2" onClose={onCloseModal} />
        ) : (
          <AddUserForm
            className="pt-2"
            onClose={onCloseModal}
            onAddMultiple={() => setOpenMultipleModal(true)}
          />
        ))}
    </ModalMobile>
  );
};

export default AddUserModal;
