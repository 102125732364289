import { createSelector } from "reselect";
import type { RootState } from "..";

const selectUserState = (state: RootState) => state.user;

export type User = {
  doctor_id: number;
};

export const getUser = createSelector([selectUserState], (user) => user.user);
