import {
  SET_FACILITIES,
  SET_FACILITIES_LOADING,
  SET_FACILITY_SEARCH_VALUE,
  SET_USER_FACILITIES,
} from "../types";

export function setFacilitiesAction(facilities) {
  return {
    type: SET_FACILITIES,
    payload: {
      facilities: facilities,
    },
  };
}

export function setFacilitySearchValueAction(facilitySearchValue) {
  return {
    type: SET_FACILITY_SEARCH_VALUE,
    payload: {
      facilitySearchValue: facilitySearchValue,
    },
  };
}

export function setFacilitiesLoadingAction(facilitiesLoading) {
  return {
    type: SET_FACILITIES_LOADING,
    payload: {
      facilitiesLoading: facilitiesLoading,
    },
  };
}

export function setUserFacilitiesAction(id, facilities) {
  return {
    type: SET_USER_FACILITIES,
    payload: {
      id: id,
      facilities: facilities,
    },
  };
}
