import { sectionDetailLevels, sectionStyles } from "../../helpers/constants";
import SectionContentPreview from "../Settings/Notes/Templates/SectionContentPreview";

const TemplatePreview = ({ sections, className }) => {
  return (
    <div className={`space-y-6 ${className}`}>
      {sections?.map((section) => (
        <div
          key={section.note_template_section_id}
          className="w-full truncate space-y-2"
        >
          <p className="uppercase text-sm font-medium font-Inter tracking-wider leading-none truncate">
            {section.name}
          </p>

          {section.has_subsections === true &&
          section.subsections?.length > 0 ? (
            section.subsections.map((subsection, index) => (
              <div
                key={`${subsection}_${index}`}
                className="w-full space-y-2 rounded-xl p-3 bg-gray-background truncate"
              >
                <p className="text-sm font-medium truncate">{subsection}</p>

                <SectionContentPreview
                  detail={section.detail_level === sectionDetailLevels.HIGH}
                  bullet={section.section_style === sectionStyles.BULLET}
                />
              </div>
            ))
          ) : (
            <SectionContentPreview
              detail={section.detail_level === sectionDetailLevels.HIGH}
              bullet={section.section_style === sectionStyles.BULLET}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default TemplatePreview;
