import { useState } from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/trash-redesign.svg";
import { useAppDispatch } from "../../../../store";
import { getPatientScribes } from "../../../../store/patient/thunks";
import { deleteScribe } from "../../../../store/voiceRecorder/thunks";
import ActionsButton from "../../../Basic/ActionsButton";
import CircleDivider from "../../../Basic/CircleDivider";
import DeleteModal from "../../../Basic/DeleteModal";
import Loader from "../../../Basic/Loader";
import RecordingIndicator from "../../../IndependentScribe/RecordingIndicator";
import { setScribeDeleted } from "../../../IndependentScribe/store/scribeSlice";
import type { ScribeGroupItem } from "../../../IndependentScribe/store/selectors";
import NoteTypesTiles from "./NoteTypesTiles";

const NoteCard = ({
  note,
  onClick,
  onDelete,
}: {
  note: ScribeGroupItem;
  onClick: () => void;
  onDelete: () => void;
}) => {
  return (
    <>
      <div
        onClick={onClick}
        className="cursor-pointer w-full rounded-2.5 p-2.5 height-sm:p-4 relative group hover:bg-white
          border border-transparent md:hover:border-gray-foreground flex md:items-center space-x-4 justify-between"
      >
        <div className="w-full">
          <div className="flex justify-between items-center mb-1">
            <p className="text-base text-left font-semibold md:group-hover:text-primary-blue truncate">
              {note.title}
            </p>
            <div className="flex items-center justify-end">
              {!note.isPaused && (
                <ActionsButton
                  className="md:hidden"
                  actions={[
                    {
                      icon: (
                        <DeleteIcon
                          width="20"
                          height="20"
                          className="flex-none"
                        />
                      ),
                      label: "Delete",
                      onClick: onDelete,
                    },
                  ]}
                />
              )}

              <button
                type="button"
                className={`hidden w-6 h-6 items-center justify-center flex-none
                  ${
                    !note.isGenerating && !note.isRecording
                      ? "md:group-hover:flex"
                      : "md:hidden pointer-events-none"
                  }`}
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete();
                }}
                disabled={note.isGenerating || note.isRecording}
              >
                <DeleteIcon width="20" height="20" />
              </button>

              {((!note.isGenerating && note.isRecording) || note.isPaused) && (
                <RecordingIndicator
                  isRecording={!note.isPaused}
                  size={20}
                  className={note.isPaused ? "md:group-hover:hidden" : ""}
                />
              )}
              {note.isGenerating && (
                <Loader
                  size={4}
                  borderWidth={2}
                  borderColor="border-zinc-450"
                />
              )}
            </div>
          </div>
          <div
            className="text-sm font-medium text-tertiary flex flex-wrap
              justify-between items-center gap-2"
          >
            {note.type && <NoteTypesTiles noteType={note.type} />}
            <div
              className="flex items-center space-x-1 text-sm
              font-medium text-tertiary whitespace-nowrap ml-auto"
            >
              <span>{note.duration}</span>
              <CircleDivider />
              <span>{note.time}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoteCard;
