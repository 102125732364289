import { ClickAwayListener } from "@mui/base";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow-down-thin.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout.svg";
import { ReactComponent as LogoIcon } from "../../assets/logo-quadrant-new.svg";
import { ReactComponent as LogoTextIcon } from "../../assets/quadrant-health-text.svg";
import { Pages } from "../../helpers/constants";
import { useAppDispatch, useAppSelector } from "../../store";
import { selectFacility } from "../../store/facility/thunks";
import { logoutAction } from "../../store/user/actions";
import ProfilePicture from "../Basic/ProfilePicture";

const Navbar = ({
  navButtons,
  expandMenu,
  setExpandMenu,
  handleNavLinkClick,
}) => {
  const { user, onlyScribeFeature } = useAppSelector((state) => state.user);
  const { userFacilities } = useAppSelector((state) => state.facility);
  const [facilities, setFacilities] = useState(
    userFacilities[user.doctor_id] || [],
  );
  const [showLogoutMenu, setShowLogoutMenu] = useState(false);
  const [openFacilityList, setOpenFacilityList] = useState(false);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const dispatch = useAppDispatch();
  const logoLink = onlyScribeFeature ? Pages.SCRIBE : Pages.MESSAGING;

  useEffect(() => {
    setFacilities(userFacilities[user.doctor_id] || []);
  }, [userFacilities, user]);

  useEffect(() => {
    if (facilities?.length > 1) {
      setFacilityOptions(
        facilities.filter(
          (facility) => facility.customer_id !== user.customer_id,
        ),
      );
    }
  }, [facilities, user]);

  const onClickProfileImg = () => {
    setShowLogoutMenu(!showLogoutMenu);
  };

  const onClickFacility = () => {
    setOpenFacilityList(!openFacilityList);
  };

  const onSelectFacility = (customerId) => {
    dispatch(selectFacility(customerId));
  };

  const logout = () => {
    dispatch(logoutAction());
  };

  return (
    <div className="fixed top-0 left-0 h-screen-dynamic w-fit z-50">
      <nav
        className={`h-full flex flex-col justify-between pb-6 bg-white overflow-hidden transition-all
          ${expandMenu ? "w-64 shadow-menu" : "w-[72px]"}`}
        onMouseEnter={() => setExpandMenu(true)}
        onMouseLeave={() => setExpandMenu(false)}
      >
        <div className="flex flex-col w-full overflow-x-hidden overflow-y-auto scrollbar px-3.5 pt-6">
          <NavLink
            aria-label="Inbox"
            to={logoLink}
            className="pb-6 border-b mb-2"
            onClick={(e) => handleNavLinkClick(e, logoLink)}
          >
            <div
              className={`flex items-center overflow-hidden transition-all
                ${expandMenu ? "w-full" : "w-11"}`}
            >
              <LogoIcon width="44" height="40" className="flex-none" />
              <LogoTextIcon
                width="76"
                height="26"
                className={`flex-none
                  ${expandMenu ? "ml-2" : "transition-all opacity-0"}`}
              />
            </div>
          </NavLink>
          <div className="w-full flex flex-col text-tertiary font-semibold text-base">
            {navButtons}
          </div>
        </div>

        <div className="w-full">
          {/* <p
            className={`text-xs text-right p-3 ${expandMenu ? "" : "hidden"}`}
          >
            Version: {process.env.REACT_APP_VERSION}
          </p> */}
          {!onlyScribeFeature && facilityOptions.length > 0 && (
            <>
              <div
                className="cursor-pointer select-none grid grid-cols-conv-layout items-center font-medium truncate
                py-4 px-5 border-t bg-gray-background"
                onClick={onClickFacility}
              >
                <div className="grid grid-cols-2-right items-center gap-3 truncate flex-none">
                  <ProfilePicture
                    firstName={user.customer.display_name || user.customer.name}
                    flagPadding={false}
                    bgColor="primary-blue-light"
                  />
                  <p
                    className={`truncate transition-all ${expandMenu ? "w-full" : "w-0"}`}
                  >
                    {user.customer.display_name || user.customer.name}
                  </p>
                </div>
                {expandMenu && (
                  <ArrowIcon
                    width="12"
                    height="8"
                    className={`transition-all ml-3 ${openFacilityList ? "-rotate-180" : ""}`}
                  />
                )}
              </div>

              <div
                className={`px-3 transition-all overflow-hidden max-h-60 grid grid-rows-tab-layout
                ${expandMenu && openFacilityList ? "h-fit py-3" : "h-0 py-0"}
              `}
              >
                <p className="uppercase text-tertiary font-medium text-xs tracking-wide truncate my-2">
                  Select clinic
                </p>
                <div className="overflow-y-auto scrollbar min-h-0 h-full space-y-1">
                  {facilityOptions.map((facility) => (
                    <div
                      key={facility.customer_id}
                      className="grid grid-cols-2-right gap-3 py-2 cursor-pointer"
                      onClick={() => onSelectFacility(facility.customer_id)}
                    >
                      <ProfilePicture
                        firstName={facility.display_name || facility.name}
                        flagPadding={false}
                        bgColor="gray-110"
                        textColor="tertiary"
                      />
                      <p className="text-wrap pt-1">
                        {facility.display_name || facility.name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}

          <div
            className="cursor-pointer select-none flex items-center space-x-3 font-medium truncate pt-4 px-4 border-t"
            onClick={onClickProfileImg}
            data-testid="profile-button"
          >
            <ProfilePicture
              size={10}
              fontSize="base"
              firstName={user.first_name}
              lastName={user.last_name}
              flagPadding={false}
              src={user.profile_picture}
            />
            <p className="truncate">{user.display_name}</p>
          </div>
        </div>
      </nav>
      {showLogoutMenu && (
        <ClickAwayListener onClickAway={() => setShowLogoutMenu(false)}>
          <div className="absolute z-50 bottom-20 left-6 h-11 w-36 py-3 bg-white rounded-lg shadow-menu">
            <button
              type="button"
              className="flex items-center space-x-2 px-4 w-full"
              onClick={logout}
              data-testid="logout-button"
            >
              <LogoutIcon width="20" height="20" />
              <p className="text-sm text-zinc-700">Logout</p>
            </button>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default Navbar;
