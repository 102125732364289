import { useAppSelector } from "../../../store";

const ScribeListHeader = () => {
  const { user } = useAppSelector((state) => state.user);

  return (
    <header className="pt-5 md:pt-7 flex items-center gap-3 z-[1]">
      <p
        className="w-8 h-8 rounded-full bg-gray-foreground text-xs text-primary-blue uppercase
          flex items-center justify-center"
      >
        {user.first_name?.charAt(0)}
        {user.last_name?.charAt(0)}
      </p>
      <p className="font-semibold text-base text-primary">
        {user.display_name}
      </p>
    </header>
  );
};

export default ScribeListHeader;
