import { useMediaQuery } from "react-responsive";
import ModalMobile from "../../../Basic/ModalMobile";
import EditTeamForm from "./EditTeamForm";
import Modal from "../../../Basic/Modal";

const EditTeamModal = ({
  open,
  onClose,
  onCancel,
  selectedTeam,
  newTeam,
  onSave,
}) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return isDesktop ? (
    open && selectedTeam && (
      <Modal className="pt-5 rounded-2xl">
        <div className="grow md:grow-0 sm:w-128">
          <EditTeamForm
            selectedTeam={selectedTeam}
            onClose={onClose}
            onCancel={onCancel}
            newTeam={newTeam}
            onSave={onSave}
          />
        </div>
      </Modal>
    )
  ) : (
    <ModalMobile open={open} onClickAway={onCancel} className="">
      {selectedTeam && (
        <EditTeamForm
          selectedTeam={selectedTeam}
          onClose={onClose}
          onCancel={onCancel}
          newTeam={newTeam}
          onSave={onSave}
        />
      )}
    </ModalMobile>
  );
};

export default EditTeamModal;
