import SettingsContainer from "../../components/Settings/SettingsContainer";
import CareManagement from "../../components/Settings/CareManagement";

const CareManagementSettings = () => {
  return (
    <SettingsContainer>
      <CareManagement />
    </SettingsContainer>
  );
};

export default CareManagementSettings;
