import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedEnrollCampaignAction } from "../../store/campaign/actions";
import DetailsTab from "../Campaigns/DetailsTab";
import ControlsTab from "../Campaigns/ControlsTab";
import TabButtonRound from "../Basic/TabButtonRound";
import { ReactComponent as CheckIcon } from "../../assets/icons/check-circle-completed.svg";

const CareManagementTabs = {
  DETAILS: "Details",
  CONTROLS: "Controls",
};

const CareManagement = () => {
  const { DETAILS, CONTROLS } = CareManagementTabs;
  const { campaigns, selectedEnrollCampaign } = useSelector(
    (state) => state.campaign,
  );
  const [activeTab, setActiveTab] = useState(DETAILS);
  const dispatch = useDispatch();

  return (
    <div>
      <p className="text-xl font-semibold">Care Management</p>
      <div
        className="flex flex-wrap md:flex-nowrap gap-3 md:gap-5 pt-3 md:pb-3 px-0.5 mb-6
          md:overflow-x-auto scrollbar"
      >
        {campaigns?.length > 0 ? (
          campaigns.map((campaign) => (
            <button
              type="button"
              className={`w-52 md:w-48 md:h-48 flex flex-col items-start flex-none p-3 md:p-4 rounded-lg border
                ${
                  selectedEnrollCampaign?.campaign_id === campaign.campaign_id
                    ? "border-primary text-primary bg-white"
                    : "border-gray-300 bg-transparent text-tertiary"
                }`}
              onClick={() =>
                dispatch(setSelectedEnrollCampaignAction(campaign))
              }
              key={campaign.campaign_id}
            >
              <div className="w-full flex justify-between space-x-3">
                <p className="text-left text-base font-semibold">
                  {campaign.campaign}
                </p>
                <div className="w-5">
                  {selectedEnrollCampaign?.campaign_id ===
                    campaign.campaign_id && (
                    <CheckIcon
                      width="20"
                      height="20"
                      fill="#121212"
                      className="flex-none"
                    />
                  )}
                </div>
              </div>
              <p className="text-left text-xs text-tertiary font-medium md:pt-1 hidden md:flex">
                {campaign.description}
              </p>
            </button>
          ))
        ) : (
          <p>No campaigns</p>
        )}
      </div>

      <div className="flex space-x-2 mb-5">
        <TabButtonRound
          label={DETAILS}
          active={activeTab}
          onClick={() => setActiveTab(DETAILS)}
        />
        <TabButtonRound
          label={CONTROLS}
          active={activeTab}
          onClick={() => setActiveTab(CONTROLS)}
        />
      </div>

      {activeTab === DETAILS && <DetailsTab />}
      {activeTab === CONTROLS && <ControlsTab />}
    </div>
  );
};

export default CareManagement;
