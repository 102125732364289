import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import dayjs from "dayjs";
import {
  getBillingNumbers,
  getBillingPatients,
} from "../store/billingMetrics/thunks";
import {
  setBillingLoadingAction,
  setCurrentDateStampAction,
} from "../store/billingMetrics/actions";
import MetricsTable from "../components/BillingMetrics/MetricsTable";

const BillingMetrics = () => {
  const {
    billingPatients,
    rowsPerPage,
    currPage,
    currentDateStamp,
    selectedBillingType,
    selectedCptCode,
    sortedBy,
    sortingDirection,
    billingSearchValue,
  } = useSelector((state) => state.billingMetrics);
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isHeightSm = useMediaQuery({ minHeight: 640 });

  useEffect(() => {
    if (!billingPatients) {
      dispatch(setBillingLoadingAction(true));
    }
    if (!currentDateStamp) {
      dispatch(setBillingLoadingAction(true));
      const dateStamp = dayjs().startOf("month").format("YYYY-MM-DD");
      dispatch(setCurrentDateStampAction(dateStamp));
    }
  }, []);

  useEffect(() => {
    if (currentDateStamp) {
      dispatch(getBillingNumbers(selectedBillingType, currentDateStamp));
    }
  }, [selectedBillingType, currentDateStamp]);

  useEffect(() => {
    if (currentDateStamp) {
      dispatch(
        getBillingPatients(
          currentDateStamp,
          rowsPerPage * currPage,
          billingSearchValue,
          selectedBillingType,
          selectedCptCode,
          sortedBy,
          sortingDirection,
        ),
      );
    }
  }, [currentDateStamp]);

  return (
    <div
      className={`w-full overflow-hidden grid ${isDesktop ? "h-screen-dynamic" : ""}`}
    >
      <div
        className={`flex overflow-hidden bg-gray-background
          ${isDesktop && isHeightSm ? "p-4 height-md:p-6" : "p-4 md:p-2"}`}
      >
        <MetricsTable />
      </div>
    </div>
  );
};

export default BillingMetrics;
