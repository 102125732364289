import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ClickAwayListener } from "@mui/base";
import { getOptionValues } from "../../../helpers/constants";
import Checkbox from "../../Basic/Checkbox";
import { ReactComponent as ArrowDownIcon } from "../../../assets/icons/arrow-down.svg";

const mockCategoryOptions = [
  { value: "Scheduling", text: "Scheduling" },
  { value: "Medication Refill", text: "Medication Refill" },
  { value: "Billing/Insurance", text: "Billing/Insurance" },
  { value: "Sick Note", text: "Sick Note" },
  { value: "Test Results Inquiry", text: "Test Results Inquiry" },
  { value: "Referral Request", text: "Referral Request" },
  { value: "Medical Question", text: "Medical Question" },
  { value: "other", text: "Other" },
];
const mockTypeOptions = [
  { value: "Urgent", text: "Urgent" },
  { value: "Clinical", text: "Clinical" },
  { value: "Administrative", text: "Administrative" },
];
const initFilters = {
  category: getOptionValues(mockCategoryOptions),
  type: getOptionValues(mockTypeOptions),
};
const clearFilters = {
  category: [],
  type: [],
};

const MockFilterButton = ({
  filterName,
  icon,
  arrow,
  options,
  openedDropdown,
  setOpenedDropdown,
}) => {
  const [activeFilters, setActiveFilters] = useState(initFilters);
  const isHeightSm = useMediaQuery({ minHeight: 640 });

  const onApplyFilters = (filters) => {
    setActiveFilters(filters);
  };
  const isFilterApplied = () => {
    if (activeFilters[filterName]) {
      return (
        activeFilters[filterName].length !== initFilters[filterName].length
      );
    }
    return false;
  };

  const clearAll = () => {
    onApplyFilters({
      ...activeFilters,
      [filterName]: clearFilters[filterName],
    });
  };

  const selectAll = () => {
    onApplyFilters({
      ...activeFilters,
      [filterName]: initFilters[filterName],
    });
  };

  const onSelectOption = (option) => {
    if (activeFilters[filterName].includes(option)) {
      onApplyFilters({
        ...activeFilters,
        [filterName]: activeFilters[filterName].filter(
          (item) => item !== option,
        ),
      });
    } else {
      onApplyFilters({
        ...activeFilters,
        [filterName]: [...activeFilters[filterName], option],
      });
    }
  };

  const isActiveOption = (option) => {
    return activeFilters[filterName].includes(option.value);
  };

  const getOptions = () => {
    return options.map((option) => {
      const isActive = isActiveOption(option);

      return (
        <button
          type="button"
          key={option.value}
          onClick={() => onSelectOption(option.value)}
          className={`p-3 w-full text-left flex justify-between rounded-lg hover:bg-indigo-50
            ${isActive ? "text-primary-blue bg-indigo-50" : "text-zinc-700"}`}
        >
          <div className="flex items-center space-x-2">
            <Checkbox
              checked={isActive}
              onClick={(e) => e.stopPropagation()}
              variant="dark"
            />
            <p>{option.text}</p>
          </div>
        </button>
      );
    });
  };

  return (
    <div
      onClick={() => {
        if (openedDropdown !== filterName) {
          setOpenedDropdown(filterName);
        }
      }}
      className={`flex items-center justify-center rounded-full capitalize select-none cursor-pointer
        ${icon ? "p-1.5" : "py-2 px-3"}`}
    >
      <div
        className={`text-xs leading-none font-medium flex-none relative
          ${isFilterApplied() ? "text-blue-1000" : "text-primary"}`}
      >
        <div>{icon ? icon : filterName}</div>
        {isFilterApplied() && (
          <div className="absolute -top-0.5 -right-0.5 bg-primary-blue w-2.5 h-2.5 rounded-full" />
        )}
      </div>
      {arrow && (
        <ArrowDownIcon
          stroke={isFilterApplied() ? "#122C62" : "#121212"}
          className={`mt-0.5 ml-1 transition-all duration-300
            ${openedDropdown === filterName ? "-rotate-180" : ""}`}
          width="10"
          height="6"
        />
      )}

      {openedDropdown === filterName && (
        <ClickAwayListener
          onClickAway={(e) => {
            e.preventDefault();
            setOpenedDropdown("");
          }}
        >
          <div
            className="absolute left-0 top-9 w-full bg-white rounded-2.5 shadow-md overflow-hidden
              leading-tight text-sm font-medium"
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className={`p-2 flex flex-col items-start space-y-1 overflow-y-auto scrollbar
                ${isHeightSm ? "max-h-65vh-dynamic" : "max-h-50vh-dynamic"}`}
            >
              {getOptions()}
            </div>

            <div className="border-t flex w-full items-center">
              <button
                type="button"
                className="py-2.5 px-5 w-full text-zinc-450 hover:text-primary"
                onClick={clearAll}
              >
                Clear All
              </button>
              <button
                type="button"
                className="py-2.5 px-5 w-full text-zinc-450 hover:text-primary"
                onClick={selectAll}
              >
                Select All
              </button>
            </div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default MockFilterButton;
