import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import Button from "../Basic/Button";
import Modal from "../Basic/Modal";

const DeleteModal = ({
  title,
  description,
  onClose,
  onSubmit,
}: {
  title: string;
  description: string;
  onClose: () => void;
  onSubmit: () => void;
}) => {
  return (
    <Modal>
      <div className="w-88">
        <div className="flex justify-between mb-4">
          <div className="w-12 h-12 rounded-full flex items-center justify-center bg-red-100 border-8 border-red-50">
            <TrashIcon stroke="#D92D20" width="20" height="20" />
          </div>
        </div>

        <p className="text-base md:text-lg font-semibold text-gray-900 mb-1">
          {title}
        </p>
        <p className="text-xs md:text-sm font-normal text-slate-600 mb-8">
          {description}
        </p>

        <div className="grid grid-cols-2 gap-3">
          <Button
            variant="gray"
            className="w-ful font-semibold h-11"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="red-filled"
            className="w-full font-semibold h-11"
            onClick={() => {
              onSubmit();
              onClose();
            }}
          >
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
