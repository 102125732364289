import type { FixLater, scribeSlice } from "./scribeSlice";

export const LOGOUT = "LOGOUT";
export const SET_PATIENT_NOTES = "SET_PATIENT_NOTES";
export const SET_PATIENT_NOTE_DELETED = "SET_PATIENT_NOTE_DELETED";
export const SET_PROVIDER_NOTES = "SET_PROVIDER_NOTES";
export const SET_PROVIDER_NOTE = "SET_PROVIDER_NOTE";
export const SET_PROVIDER_NOTE_CONTENT = "SET_PROVIDER_NOTE_CONTENT";
export const SET_PROVIDER_NOTES_RECORDING = "SET_PROVIDER_NOTES_RECORDING";
export const SET_PROVIDER_NOTES_PAUSED = "SET_PROVIDER_NOTES_PAUSED";
export const SET_PROVIDER_NOTES_TOGGLE_PAUSE =
  "SET_PROVIDER_NOTES_TOGGLE_PAUSE";
export const SET_PROVIDER_NOTES_LOADING = "SET_PROVIDER_NOTES_LOADING";
export const SET_PATIENT_NOTES_LOADING = "SET_PATIENT_NOTES_LOADING";
export const SET_PROVIDER_NOTE_DELETED = "SET_PROVIDER_NOTE_DELETED";
export const SET_PROVIDER_NOTES_RECORDING_GENERATING =
  "SET_PROVIDER_NOTES_RECORDING_GENERATING";
export const SET_SELECTED_AUDIO_ID = "SET_SELECTED_AUDIO_ID";
export const SET_INTERRUPTED_RECORDING_ACTION =
  "SET_INTERRUPTED_RECORDING_ACTION";

export enum SetInterruptedRecordingActionType {
  NAVIGATION = "NAVIGATION",
  SETTINGS_NAVIGATION = "SETTINGS_NAVIGATION",
  PATIENT_PROFILE_CLOSE = "PATIENT_PROFILE_CLOSE",
  PATIENT_CHANGE_SELECTED_AUDIO_ID_WHILE_RECORDING = "PATIENT_CHANGE_SELECTED_AUDIO_ID_WHILE_RECORDING",
  CLOSE_PANEL_WHILE_RECORDING = "CLOSE_PANEL_WHILE_RECORDING",
  NEW_ENROLLMENT = "NEW_ENROLLMENT",
  NEW_ENCOUNTER = "NEW_ENCOUNTER",
  RESUME_ANOTHER_ENCOUNTER = "RESUME_ANOTHER_ENCOUNTER",
  SCRIBE_BACK = "SCRIBE_BACK",
  PATIENT_PROFILE_OPEN = "PATIENT_PROFILE_OPEN",
  PATIENT_CALL = "PATIENT_CALL",
}

export type ScribeAction =
  | ReturnType<(typeof scribeSlice.actions)[keyof typeof scribeSlice.actions]>
  | { type: string; payload: FixLater };
