import {
  SET_PRACTICE_INFO,
  SET_REGISTERED_EMAIL_ADDRESS,
  SET_VERIFICATION_CODE,
} from "../types";

const init = {
  signUp: {
    registeredEmailAddress: "",
    verificationCode: "",
  },
  onboarding: {
    practiceInfo: null,
  },
};

export default function authReducer(state = init, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_REGISTERED_EMAIL_ADDRESS:
      return {
        ...state,
        signUp: {
          ...state.signUp,
          registeredEmailAddress: payload.registeredEmailAddress,
        },
      };
    case SET_PRACTICE_INFO:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          practiceInfo: payload.practiceInfo,
        },
      };
    case SET_VERIFICATION_CODE:
      return {
        ...state,
        signUp: {
          ...state.signUp,
          verificationCode: payload.verificationCode,
        },
      };
    default:
      return state;
  }
}
