import { Suspense, lazy, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import Loader from "../components/Basic/Loader";
import { lazyRetry } from "../helpers/lazyRetry";
import { setSelectedEventAction } from "../store/campaign/actions";

const UpcomingTab = lazy(() =>
  lazyRetry(() => import("../components/Campaigns/UpcomingTab")),
);

const Campaigns = () => {
  const { selectedCampaign, selectedTab } = useSelector(
    (state) => state.campaign,
  );
  const tabContent = useRef(null);
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isHeightSm = useMediaQuery({ minHeight: 640 });
  const dispatch = useDispatch();

  useEffect(() => {
    tabContent.current.scrollTo(0, 0);
  }, [selectedCampaign]);

  useEffect(() => {
    dispatch(setSelectedEventAction(null));
  }, [selectedTab]);

  return (
    <div className="overflow-hidden flex border-neutral-200 w-full md:h-screen-dynamic">
      <div className="flex w-full overflow-y-auto scrollbar md:overflow-hidden">
        <div
          className={`md:min-h-0 w-full md:overflow-hidden md:grid md:bg-gray-background
            ${isDesktop && isHeightSm ? "p-4 height-md:p-6" : "p-4 md:p-2"}`}
        >
          <div
            ref={tabContent}
            className={`md:min-h-0 md:overflow-y-auto p-5 scrollbar bg-white rounded-lg border border-gray-200
              ${isDesktop ? "" : "h-full"}`}
          >
            <Suspense
              fallback={
                <div className="w-full h-full flex items-center justify-center">
                  <Loader />
                </div>
              }
            >
              <UpcomingTab />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Campaigns;
