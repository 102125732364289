import { NOTE_NOT_SELECTED, NOTE_RECORDING_ID } from "../consts";
import { initialTitle } from "../utils";
import type { Scribe } from "./interfaces";

export const getRecordingScribeTemplate = ({
  overrides,
}: { overrides?: Partial<Scribe> } = {}): Scribe => ({
  noteId: NOTE_RECORDING_ID,
  audioId: NOTE_NOT_SELECTED,
  title: initialTitle(new Date()),
  patient: null,
  createdAt: null,
  startedAt: null,
  finishedAt: null,
  accumulatedDuration: 0,
  isEditing: false,
  isRecording: false,
  isPaused: false,
  isGenerating: false,
  isGenerationDelayed: false,
  isVisible: false,
  hasError: false,
  freeText: "",
  note: "",
  isPersisted: false,
  type: "scribe",
  ...overrides,
});
