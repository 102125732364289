import { shortcutsItems } from "../../../helpers/constants";

export const getStyles = (borderColor) => {
  return {
    "& .MuiOutlinedInput-root": {
      height: "40px",
      backgroundColor: "white",
      input: {
        padding: "0 0 0 14px",
        fontSize: "14px",
        fontWeight: "500",
        fontFamily: "Mulish, sans-serif",
        "&::placeholder": {
          color: "#9ca3af",
          opacity: 100,
        },
      },
      fieldset: {
        borderRadius: "8px",
        borderColor: borderColor,
      },
      "&:hover fieldset": {
        borderColor: borderColor,
      },
      "&.Mui-focused": {
        fieldset: {
          borderColor: borderColor,
          borderWidth: "1px",
        },
      },
      "&.Mui-error": {
        fieldset: {
          borderColor: borderColor,
        },
      },
      "&.Mui-disabled": {
        backgroundColor: "#EDEDED",
        fieldset: {
          borderColor: borderColor,
        },
      },
    },
    input: {
      "&.Mui-disabled": {
        WebkitTextFillColor: "#121212",
        opacity: 1,
      },
    },
  };
};

const scrollbarStyles = {
  "&::-webkit-scrollbar": {
    width: "6px",
    height: "6px",
  },
  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#D9D9D9",
    borderRadius: "10px",
  },
};

export const getSlotProps = (withShortcuts) => {
  const shortcutsSx = {
    "& .MuiPickersCalendarHeader-root": {
      position: "absolute",
      width: "100%",
      top: "0",
    },
    "& .MuiDateCalendar-root": {
      maxHeight: "280px",
      borderRight: "1px solid #E5E5E5",
    },
    "& .MuiMultiSectionDigitalClock-root": {
      maxHeight: "280px",
      ...scrollbarStyles,
      "& > ul": {
        width: "50px",
        "&::after": {
          display: "block",
          content: '""',
          height: "calc(100% - 36px - 6px) !important",
        },
      },
      "& > li": {
        fontSize: "14px",
        borderRadius: "36px",
        width: "36px",
        minHeight: "36px",
        height: "36px",
      },
    },
    "& .MuiYearCalendar-root": {
      maxHeight: "280px",
      ...scrollbarStyles,
    },
    "& .MuiDivider-root": {
      display: "none",
    },
    "& .MuiDialogActions-root": {
      borderTop: "1px solid #E5E5E5",
      marginTop: "20px",
    },
  };

  const noShortcutsSx = {
    "& .MuiYearCalendar-root": {
      ...scrollbarStyles,
    },
  };

  const commonSx = {
    borderRadius: "8px",
    fontFamily: "Mulish, sans-serif",
    "& .MuiDayCalendar-header": {
      "& > span": {
        color: "#121212",
        fontSize: "16px",
      },
    },
    "& .MuiPickersCalendarHeader-labelContainer": {
      fontFamily: "Mulish, sans-serif",
      fontSize: "20px",
    },
    "& .MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition": {
      minHeight: "230px",
    },
    "& .MuiPickersDay-root": {
      fontSize: "14px",
    },
    "& .MuiPickersDay-today": {
      border: "none !important",
      backgroundColor: "rgba(41, 112, 255, 0.10)",
      color: "#2970FF",
      "&:hover, &:focus, &:active": {
        border: "none !important",
        backgroundColor: "rgba(41, 112, 255, 0.10)",
        color: "#2970FF",
      },
    },
    "& .Mui-selected": {
      backgroundColor: "#2970FF !important",
      color: "white",
      "&:hover, &:focus, &:active": {
        backgroundColor: "#2970FF !important",
        color: "white",
      },
    },
    "& .MuiButtonBase-root.MuiButton-root": {
      fontFamily: "Mulish, sans-serif",
      textTransform: "capitalize",
      fontSize: "16px",
      fontWeight: "600",
      color: "#888888",
    },
    "& .MuiDialogActions-root": {
      paddingTop: "0",
    },
    "& .MuiDialogActions-root>:not(:first-of-type)": {
      color: "#2970FF",
    },
  };

  return {
    mobilePaper: {
      sx: {
        "& .MuiPickersLayout-root": {
          display: "block",
        },
        "& .MuiGrid-root.MuiPickersToolbar-content": {
          alignItems: "end",
        },
        "& .MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition": {
          minHeight: "230px",
        },
        "& .MuiDialogActions-root": {
          paddingTop: "0",
        },
      },
    },
    desktopPaper: {
      sx: withShortcuts
        ? { ...commonSx, ...shortcutsSx }
        : { ...commonSx, ...noShortcutsSx },
    },
    openPickerButton: {
      sx: {
        marginRight: "-5px",
      },
    },
    actionBar: {
      actions: ["cancel", "accept"],
    },
    shortcuts: {
      items: withShortcuts ? shortcutsItems : [],
    },
  };
};
