const LoaderDots = ({ color = "primary-blue" }) => {
  const dotStyle = `h-2.5 w-2.5 rounded-full bg-${color} scale-50 animate-scaling`;

  return (
    <div className="space-x-1.5 flex items-center">
      <div className={dotStyle} />
      <div className={dotStyle} style={{ animationDelay: "200ms" }} />
      <div className={dotStyle} style={{ animationDelay: "400ms" }} />
      <div className={dotStyle} style={{ animationDelay: "600ms" }} />
      <div className={dotStyle} style={{ animationDelay: "800ms" }} />
    </div>
  );
};

export default LoaderDots;
