import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { NavLink, useNavigate } from "react-router-dom";
import { ReactComponent as CareManagementIcon } from "../../assets/icons/care-management.svg";
import { ReactComponent as InboxIcon } from "../../assets/icons/inbox-redesign.svg";
import { ReactComponent as ScribeIcon } from "../../assets/icons/microphone-scribe.svg";
import { ReactComponent as PatientIcon } from "../../assets/icons/patients-redesign.svg";
import { ReactComponent as CampaignsIcon } from "../../assets/icons/schedule-gray-redesign.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/settings-redesign.svg";
import { ReactComponent as TrackingIcon } from "../../assets/icons/tracking.svg";
import {
  Pages,
  careManagementTabs,
  settingsTabs,
} from "../../helpers/constants";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  setSelectedCareManagementTabAction,
  setSelectedSettingsTabAction,
} from "../../store/user/actions";
import { setInterruptedRecordingAction } from "../IndependentScribe/store/scribeSlice";
import { getRecordingScribe } from "../IndependentScribe/store/selectors";
import { SetInterruptedRecordingActionType } from "../IndependentScribe/store/types";
import MobileMenu from "./MobileMenu";
import Navbar from "./Navbar";

//styles
const navBtn =
  "h-10 md:h-fit flex items-center whitespace-nowrap cursor-pointer md:py-4 ";
const activeNavBtn = "text-primary ";
const iconStroke = "#667085";
const activeIconStroke = "#2970FF";
const iconSize = 28;
const iconClasses = "flex-none transition-all ";
const labelClasses = "ml-3";
const labelHiddenClasses = "md:transition-all md:w-0 md:opacity-0";

const Navigation = () => {
  const recordingNote = useAppSelector(getRecordingScribe);

  const { currentConversation } = useAppSelector((state) => state.conversation);
  const { selectedPatientInfo } = useAppSelector((state) => state.patient);
  const { selectedSettingsTab, onlyScribeFeature } = useAppSelector(
    (state) => state.user,
  );
  const [isSettingsActive, setIsSettingsActive] = useState(false);
  const [expandMenu, setExpandMenu] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const isIndependentScribe = window.location.pathname.match(/\/scribe$/);

  const handleNavLinkClick = (e, url) => {
    if (recordingNote) {
      e.preventDefault();
      dispatch(
        setInterruptedRecordingAction({
          type: SetInterruptedRecordingActionType.NAVIGATION,
          value: url,
        }),
      );
    }
  };

  const handleClickSettings = () => {
    const navigateLink = !selectedSettingsTab
      ? Pages.SETTINGS_PROFILE
      : settingsTabs.find((tab) => tab.text === selectedSettingsTab).link;

    if (recordingNote) {
      dispatch(
        setInterruptedRecordingAction({
          type: SetInterruptedRecordingActionType.SETTINGS_NAVIGATION,
          value: navigateLink,
        }),
      );
      return;
    }

    if (!selectedSettingsTab) {
      dispatch(setSelectedSettingsTabAction("Profile"));
    }

    navigate(navigateLink);
  };

  useEffect(() => {
    if (
      careManagementTabs.some((tab) => window.location.pathname === tab.link)
    ) {
      dispatch(
        setSelectedCareManagementTabAction(
          careManagementTabs.find(
            (tab) => tab.link === window.location.pathname,
          ).text,
        ),
      );
    }

    if (settingsTabs.some((tab) => window.location.pathname === tab.link)) {
      dispatch(
        setSelectedSettingsTabAction(
          settingsTabs.find((tab) => tab.link === window.location.pathname)
            .text,
        ),
      );
    }
  }, []);

  useEffect(() => {
    if (settingsTabs.some((tab) => window.location.pathname === tab.link)) {
      setIsSettingsActive(true);
    } else {
      setIsSettingsActive(false);
    }
  }, [window.location.pathname]);

  const navButtons = (
    <>
      {!onlyScribeFeature && (
        <>
          <NavLink
            aria-label="Inbox"
            to={Pages.MESSAGING}
            className={({ isActive }) =>
              isActive ? navBtn + activeNavBtn : navBtn
            }
            onClick={(e) => handleNavLinkClick(e, Pages.MESSAGING)}
          >
            {({ isActive }) => (
              <>
                <InboxIcon
                  stroke={isActive ? activeIconStroke : iconStroke}
                  width={iconSize}
                  height={iconSize}
                  className={`${iconClasses} ${expandMenu ? "" : "ml-2"}`}
                />
                <p
                  className={
                    expandMenu || !isDesktop ? labelClasses : labelHiddenClasses
                  }
                >
                  Inbox
                </p>
              </>
            )}
          </NavLink>

          <NavLink
            aria-label="Patients"
            to={Pages.PATIENTS}
            className={({ isActive }) =>
              isActive ? navBtn + activeNavBtn : navBtn
            }
            onClick={(e) => handleNavLinkClick(e, Pages.PATIENTS)}
          >
            {({ isActive }) => (
              <>
                <PatientIcon
                  stroke={isActive ? activeIconStroke : iconStroke}
                  width={iconSize}
                  height={iconSize}
                  className={`${iconClasses} ${expandMenu ? "" : "ml-2"}`}
                />
                <p
                  className={
                    expandMenu || !isDesktop ? labelClasses : labelHiddenClasses
                  }
                >
                  Patients
                </p>
              </>
            )}
          </NavLink>
        </>
      )}

      <NavLink
        aria-label="Scribe"
        to={Pages.SCRIBE}
        className={({ isActive }) =>
          isActive ? navBtn + activeNavBtn : navBtn
        }
        onClick={(e) => handleNavLinkClick(e, Pages.SCRIBE)}
        data-testid="nav-button-scribe"
      >
        {({ isActive }) => (
          <>
            <ScribeIcon
              stroke={isActive ? activeIconStroke : iconStroke}
              width={iconSize}
              height={iconSize}
              className={`${iconClasses} ${expandMenu ? "" : "ml-2"}`}
            />
            <p
              className={
                expandMenu || !isDesktop ? labelClasses : labelHiddenClasses
              }
            >
              Scribe
            </p>
          </>
        )}
      </NavLink>

      {!onlyScribeFeature && (
        <>
          <NavLink
            aria-label="Campaigns"
            to={Pages.CAMPAIGNS}
            className={({ isActive }) =>
              isActive ? navBtn + activeNavBtn : navBtn
            }
            onClick={(e) => handleNavLinkClick(e, Pages.CAMPAIGNS)}
          >
            {({ isActive }) => (
              <>
                <CampaignsIcon
                  stroke={isActive ? activeIconStroke : iconStroke}
                  fill={isActive ? activeIconStroke : iconStroke}
                  width={iconSize}
                  height={iconSize}
                  className={`${iconClasses} ${expandMenu ? "" : "ml-2"}`}
                />
                <p
                  className={
                    expandMenu || !isDesktop ? labelClasses : labelHiddenClasses
                  }
                >
                  Schedule
                </p>
              </>
            )}
          </NavLink>

          <NavLink
            aria-label="Enroll"
            to={Pages.ENROLL}
            className={({ isActive }) =>
              isActive ? navBtn + activeNavBtn : navBtn
            }
            onClick={(e) => handleNavLinkClick(e, Pages.ENROLL)}
          >
            {({ isActive }) => (
              <>
                <CareManagementIcon
                  fill={isActive ? activeIconStroke : iconStroke}
                  width={iconSize}
                  height={iconSize}
                  className={`${iconClasses} ${expandMenu ? "" : "ml-2"}`}
                />
                <p
                  className={
                    expandMenu || !isDesktop ? labelClasses : labelHiddenClasses
                  }
                >
                  Enroll
                </p>
              </>
            )}
          </NavLink>

          <NavLink
            aria-label="Time Tracking"
            to={Pages.BILLING_METRICS}
            className={({ isActive }) =>
              isActive ? navBtn + activeNavBtn : navBtn
            }
            onClick={(e) => handleNavLinkClick(e, Pages.BILLING_METRICS)}
          >
            {({ isActive }) => (
              <>
                <TrackingIcon
                  stroke={isActive ? activeIconStroke : iconStroke}
                  width={iconSize}
                  height={iconSize}
                  className={`${iconClasses} ${expandMenu ? "" : "ml-2"}`}
                />
                <p
                  className={
                    expandMenu || !isDesktop ? labelClasses : labelHiddenClasses
                  }
                >
                  Time tracking
                </p>
              </>
            )}
          </NavLink>
        </>
      )}

      <div className="w-full">
        <button
          type="button"
          className={`w-full ${navBtn}
            ${isSettingsActive ? activeNavBtn : ""}`}
          onClick={handleClickSettings}
        >
          <SettingsIcon
            stroke={isSettingsActive ? activeIconStroke : iconStroke}
            width={iconSize}
            height={iconSize}
            className={`${iconClasses} ${expandMenu ? "" : "ml-2"}`}
          />
          <p
            className={
              expandMenu || !isDesktop ? labelClasses : labelHiddenClasses
            }
          >
            Settings
          </p>
        </button>
      </div>
    </>
  );

  return !isDesktop &&
    !isIndependentScribe &&
    (currentConversation || selectedPatientInfo) ? (
    <></>
  ) : isDesktop ? (
    <Navbar
      navButtons={navButtons}
      expandMenu={expandMenu}
      setExpandMenu={setExpandMenu}
      handleNavLinkClick={handleNavLinkClick}
    />
  ) : (
    <MobileMenu
      navButtons={navButtons}
      handleNavLinkClick={handleNavLinkClick}
    />
  );
};

export default Navigation;
