import { useEffect, useState } from "react";

interface Microphone {
  text: string;
  value: string;
}

const useMicrophone = () => {
  const [microphones, setMicrophones] = useState<Microphone[]>([
    {
      text: "Please select a microphone",
      value: null,
    },
  ]);
  const [microphoneId, setMicrophoneId] = useState<string>("default");
  const [permissionGranted, setPermissionGranted] = useState<boolean>(false);

  const requestMicrophonePermissions = async () => {
    try {
      if (navigator.permissions) {
        const permissionStatus = await navigator.permissions.query({
          name: "microphone" as PermissionName,
        });

        if (permissionStatus.state === "granted") {
          setPermissionGranted(true);
        } else if (permissionStatus.state === "prompt") {
          // Request permission
          await navigator.mediaDevices.getUserMedia({ audio: true });
          setPermissionGranted(true);
        } else {
          // Permission denied
          console.error("Microphone permission denied");
          setPermissionGranted(false);
        }

        // Optionally listen for changes in permission status
        permissionStatus.onchange = () => {
          console.log("permissionStatus", permissionStatus);
          if (permissionStatus.state === "granted") {
            setPermissionGranted(true);
            return;
          }

          setPermissionGranted(false);
        };
      } else {
        // Fallback for iOS and other browsers without navigator.permissions
        await navigator.mediaDevices.getUserMedia({ audio: true });
        setPermissionGranted(true);
      }
    } catch (err) {
      console.error("Error getting microphone permissions:", err);
      setPermissionGranted(false);
    }
  };

  const listAndSelectMicrophone = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();

      const audioDevices = devices
        .filter((device) => device.kind === "audioinput")
        .map((device) => ({
          text: device.label || `Microphone ${device.deviceId}`,
          value: device.deviceId,
        }));

      setMicrophones(audioDevices);

      const isPreSelectedDeviceAvailable = audioDevices.some(
        (device) => device.value === microphoneId,
      );

      if (!isPreSelectedDeviceAvailable && audioDevices.length > 0) {
        setMicrophoneId(audioDevices[0].value);
      }
    } catch (err) {
      console.error("Error enumerating devices:", err);
    }
  };

  useEffect(() => {
    requestMicrophonePermissions();
  }, []);

  useEffect(() => {
    if (permissionGranted) {
      listAndSelectMicrophone();
    }
  }, [permissionGranted]);

  return {
    microphones,
    microphoneId,
    permissionGranted,
    setMicrophoneId,
    requestMicrophonePermissions,
  };
};

export default useMicrophone;
