import { useCallback, useEffect, useRef, useState } from "react";
import { throttle } from "../../../helpers/throttle";
import type { AudioNodes, AudioSetupContext } from "../../../types/audio";
import { useAudioRecoveryStrategy } from "./useAudioRecoveryStrategy";

export interface StallDetectorConfig {
  stallThresholdFrames: number;
  timeDifferenceThreshold: number;
  checkIntervalMs: number;
  maxConsecutiveStalls: number;
  maxConsecutiveFailures: number;
}

export interface StallDetectorState {
  isStalled: boolean;
  consecutiveStalls: number;
  lastStallTime: number | null;
  averageTimeDelta: number;
}

export interface StallDetectorMetrics {
  stallCount: number;
  averageStallDuration: number;
  lastStallTime: number | null;
  isCurrentlyStalled: boolean;
}

function calculateNewAverage(
  currentAverage: number,
  newValue: number,
  count: number,
): number {
  return (currentAverage * (count - 1) + newValue) / count;
}

const DEFAULT_CONFIG: StallDetectorConfig = {
  stallThresholdFrames: 60, // Number of frames to wait before considering audio stalled
  timeDifferenceThreshold: 0.0001, // Minimum time difference to consider as movement
  checkIntervalMs: 16, // Roughly 60fps
  maxConsecutiveStalls: 3, // Maximum number of consecutive stalls before triggering recovery
  maxConsecutiveFailures: 0, // TODO, disabled - needs more work. Maximum number of consecutive recovery failures before giving up
};

export function useAudioStallDetector(
  setupContext: AudioSetupContext,
  config: Partial<StallDetectorConfig> = {},
) {
  const { audioContextRef } = setupContext;

  const mergedConfig = { ...DEFAULT_CONFIG, ...config };

  const previousAudioContextTimeRef = useRef<number>(-1);
  const stallFrameCountRef = useRef<number>(0);
  const animationFrameRef = useRef<number | null>(null);
  const metricsRef = useRef<StallDetectorMetrics>({
    stallCount: 0,
    averageStallDuration: 0,
    lastStallTime: null,
    isCurrentlyStalled: false,
  });

  const { recover, recoveryState } = useAudioRecoveryStrategy(
    setupContext,
    (successful) => {
      if (successful) {
        console.log("Audio recovery successful");
      } else {
        console.error("Audio recovery failed");
      }
    },
  );

  const [metrics, setMetrics] = useState<StallDetectorMetrics>(
    metricsRef.current,
  );

  const handleStall = useCallback(() => {
    if (
      recoveryState.consecutiveFailures < mergedConfig.maxConsecutiveFailures
    ) {
      recover();
    } else {
      metricsRef.current = {
        ...metricsRef.current,
        stallCount: metricsRef.current.stallCount + 1,
        lastStallTime: Date.now(),
        isCurrentlyStalled: true,
      };
      setMetrics(metricsRef.current);
      console.debug(metricsRef.current, recoveryState, config);
    }
  }, [
    recover,
    recoveryState.consecutiveFailures,
    mergedConfig.maxConsecutiveFailures,
  ]);

  // Performance optimized stall check using throttle
  const checkStalledAudioContext = useCallback(
    throttle(() => {
      const context = audioContextRef.current;
      if (!context) {
        return;
      }

      const currentTime = context.currentTime;
      const timeDifference = Math.abs(
        currentTime - previousAudioContextTimeRef.current,
      );

      if (timeDifference < mergedConfig.timeDifferenceThreshold) {
        stallFrameCountRef.current += 1;

        if (stallFrameCountRef.current === mergedConfig.stallThresholdFrames) {
          // Trigger stall callback
          handleStall();
        }
      } else {
        // Reset stall counter if audio is moving
        if (stallFrameCountRef.current > 0) {
          if (metricsRef.current.isCurrentlyStalled) {
            const stallDuration =
              Date.now() - (metricsRef.current.lastStallTime || 0);
            metricsRef.current = {
              ...metricsRef.current,
              averageStallDuration: calculateNewAverage(
                metricsRef.current.averageStallDuration,
                stallDuration,
                metricsRef.current.stallCount,
              ),
              isCurrentlyStalled: false,
            };
            setMetrics(metricsRef.current);
          }
          stallFrameCountRef.current = 0;
        }
      }

      previousAudioContextTimeRef.current = currentTime;
    }, mergedConfig.checkIntervalMs),
    [audioContextRef, mergedConfig],
  );

  // Main monitoring loop using requestAnimationFrame
  const monitorAudioContext = useCallback(() => {
    checkStalledAudioContext();
    animationFrameRef.current = requestAnimationFrame(monitorAudioContext);
  }, [checkStalledAudioContext]);

  // Setup and cleanup
  useEffect(() => {
    let isActive = true;

    const startMonitoring = () => {
      if (!isActive) {
        return;
      }

      // Reset state
      stallFrameCountRef.current = 0;
      previousAudioContextTimeRef.current =
        audioContextRef.current?.currentTime ?? -1;

      // Start monitoring loop
      monitorAudioContext();
    };

    const cleanup = () => {
      isActive = false;
      if (animationFrameRef.current !== null) {
        cancelAnimationFrame(animationFrameRef.current);
        animationFrameRef.current = null;
      }
      stallFrameCountRef.current = 0;
      previousAudioContextTimeRef.current = -1;
    };

    startMonitoring();
    return cleanup;
  }, [monitorAudioContext, audioContextRef]);

  return {
    metrics,
    isStalled: metrics.isCurrentlyStalled,
    recoveryState,
    reset: useCallback(() => {
      stallFrameCountRef.current = 0;
      previousAudioContextTimeRef.current =
        audioContextRef.current?.currentTime ?? -1;
      metricsRef.current = {
        stallCount: 0,
        averageStallDuration: 0,
        lastStallTime: null,
        isCurrentlyStalled: false,
      };
      setMetrics(metricsRef.current);
    }, []),
  };
}
