const ListItem = ({
  item,
  onClick,
  hoveredIcon,
  onClickHovered,
  className,
}) => {
  return (
    <div
      className={`p-2 pr-3 rounded-lg w-full cursor-pointer group hover:bg-gray-110 truncate
        flex items-center justify-between
        ${className}`}
      onClick={onClick}
    >
      <p>{item.name}</p>
      {onClickHovered && hoveredIcon && (
        <button
          type="button"
          className="h-5 w-5 flex items-center justify-center opacity-0 group-hover:opacity-100"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClickHovered(item);
          }}
        >
          {hoveredIcon}
        </button>
      )}
    </div>
  );
};

export default ListItem;
