const BeamLine = ({ rotate }) => {
  return (
    <span
      className="content-[''] absolute w-[1px] h-10 top-1/2 left-1/2 animate-complete-beam"
      style={{
        background:
          "linear-gradient(#21AF8D, #21AF8D) top, linear-gradient(#21AF8D, #21AF8D) bottom",
        backgroundSize: "100% 5px",
        backgroundRepeat: "no-repeat",
        transform: `translateX(-50%) translateY(-50%) rotate(${rotate}deg)`,
      }}
    />
  );
};

export default BeamLine;
