import { useEffect, useState } from "react";
import type { AudioContextState } from "../../../types/audio";

interface RecordingReadyState {
  isReady: boolean;
  errors: string[];
}

const useRecordingReadyState = (
  permissionGranted: boolean,
  microphoneId: string,
  contextState: AudioContextState,
  mediaRecorderRef: React.RefObject<MediaRecorder | null>,
): RecordingReadyState => {
  const [isReady, setIsReady] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    const newErrors = [];

    if (!permissionGranted) {
      newErrors.push("Microphone permissions not granted");
    }
    if (!microphoneId) {
      newErrors.push("No microphone selected");
    }
    if (!contextState.isInitialized) {
      newErrors.push("Audio context not initialized");
    }
    if (contextState.hasError) {
      newErrors.push(
        `Audio context has an error: ${contextState.error.message}`,
      );
    }
    if (!mediaRecorderRef.current) {
      newErrors.push("MediaRecorder not available");
    }

    setErrors(newErrors);
    setIsReady(newErrors.length === 0);
  }, [
    permissionGranted,
    microphoneId,
    contextState.isInitialized,
    contextState.hasError,
    mediaRecorderRef.current,
  ]);

  return { isReady, errors };
};

export default useRecordingReadyState;
