import { useEffect, useRef, useState } from "react";
import automaticProfileSrc from "../../../assets/quadrant-ai.svg";
import { Pages, convPerPage } from "../../../helpers/constants";
import { getDateDivider, isSameDay, isToday } from "../../../helpers/helpers";
import { useActivityTracker } from "../../../hooks/useActivityTracker";
import { useAppDispatch, useAppSelector } from "../../../store";
import { openConversationEvent } from "../../../store/billingMetrics/thunks";
import {
  searchConversationsWithFilters,
  sendMessage,
} from "../../../store/conversation/thunks";
import ChatSkeleton from "../../Skeletons/ChatSkeleton/ChatSkeleton";
import MessageCloud from "./MessageCloud";

const Chat = ({
  scrolledUp,
  setScrolledUp,
  showResponse,
  currentConversation,
  currentConversationId,
}) => {
  const [conversationChanged, setConversationChanged] = useState(true);
  const [dateIndicator, setDateIndicator] = useState("");
  const [showDateIndicator, setShowDateIndicator] = useState(false);
  const [lastMessage, setLastMessage] = useState(null);
  const [failedMessageCurrent, setFailedMessageCurrent] = useState([]);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [conversationMessagesLength, setConversationMessagesLength] = useState(
    {},
  );
  const [
    currentConversationLengthChanged,
    setCurrentConversationLengthChanged,
  ] = useState(false);
  const {
    conversationSearchInput,
    activeInbox,
    activeFilters,
    sentMessages,
    failedMessages,
    convCurrPage,
    conversationsUpdated,
    selectedDoctorInbox,
  } = useAppSelector((state) => state.conversation);
  const { loader } = useAppSelector((state) => state.patient);
  const { user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const messageContainer = useRef(null);
  const dateIndicatorRef = useRef(null);
  const dateRefs = useRef([]);

  const patientPicture = currentConversation
    ? currentConversation.patient.profile_picture
    : "";
  const doTracking =
    currentConversation && window.location.pathname === Pages.MESSAGING
      ? currentConversation.trackable
      : false;
  const { restartTimer } = useActivityTracker(doTracking);

  const scrollChat = () => {
    if (
      messageContainer.current?.scrollTop /
        (messageContainer.current?.scrollHeight -
          messageContainer.current?.clientHeight) <
      0.99
    ) {
      setScrolledUp(true);
    } else {
      setScrolledUp(false);
      setUnreadMessages(0);
    }
  };

  const scrollToBottom = (smooth) => {
    messageContainer.current?.scrollTo({
      top: messageContainer.current?.scrollHeight,
      left: 0,
      behavior: smooth ? "smooth" : "auto",
    });
    setUnreadMessages(0);
  };

  useEffect(() => {
    if (scrolledUp && !conversationsUpdated) {
      setUnreadMessages(unreadMessages + 1);
    }
  }, [conversationsUpdated]);

  useEffect(() => {
    if (showResponse && !scrolledUp) {
      scrollToBottom(true);
    }
  }, [showResponse]);

  useEffect(() => {
    if (
      sentMessages[currentConversationId] &&
      sentMessages[currentConversationId].length > 0
    ) {
      scrollToBottom(true);
    }
  }, [sentMessages]);

  useEffect(() => {
    if (failedMessageCurrent.length > 0) {
      scrollToBottom(false);
    }
  }, [failedMessageCurrent]);

  useEffect(() => {
    messageContainer.current.addEventListener("scroll", scrollChat);

    return () =>
      messageContainer.current?.removeEventListener("scroll", scrollChat);
  }, [messageContainer.current]);

  useEffect(() => {
    if (currentConversation) {
      setCurrentConversationLengthChanged(
        currentConversation.messages.length >
          conversationMessagesLength[currentConversationId],
      );
      setConversationMessagesLength({
        ...conversationMessagesLength,
        [currentConversationId]: currentConversation.messages.length,
      });

      if (currentConversation.messages.length > 0) {
        setLastMessage(
          currentConversation.messages[currentConversation.messages.length - 1],
        );
      }

      setFailedMessageCurrent(failedMessages[currentConversationId] || []);

      dispatch(
        searchConversationsWithFilters(
          conversationSearchInput,
          activeFilters,
          1,
          convPerPage * convCurrPage,
          activeInbox,
          selectedDoctorInbox,
        ),
      );

      if (conversationChanged) {
        scrollToBottom(false);

        (async () => {
          if (doTracking) {
            restartTimer();
            await openConversationEvent(
              currentConversation.patient.patient_id,
              currentConversation.campaign_type,
            );
          }
        })();
        setConversationChanged(false);
      } else {
        if (!scrolledUp) {
          scrollToBottom(true);
        }
      }
    }
  }, [currentConversation]);

  useEffect(() => {
    setFailedMessageCurrent(failedMessages[currentConversationId] || []);
  }, [failedMessages]);

  useEffect(() => {
    setConversationChanged(true);
  }, [currentConversationId]);

  useEffect(() => {
    messageContainer.current.addEventListener("scroll", updateDateIndicator);

    return () =>
      messageContainer.current?.removeEventListener(
        "scroll",
        updateDateIndicator,
      );
  }, [dateRefs.current, dateRefs.current.length]);

  let timeoutId: NodeJS.Timeout;
  const updateDateIndicator = () => {
    clearTimeout(timeoutId);
    if (messageContainer.current && messageContainer.current.scrollTop > 30) {
      setShowDateIndicator(true);
      timeoutId = setTimeout(() => {
        setShowDateIndicator(false);
      }, 4000);
    } else {
      setShowDateIndicator(false);
    }

    dateRefs.current.forEach((dateRef) => {
      if (dateRef && dateIndicatorRef.current) {
        if (
          dateIndicatorRef.current.getBoundingClientRect().top >=
          dateRef.getBoundingClientRect().top
        ) {
          setDateIndicator(dateRef.textContent);
        }
      }
    });
  };

  const resendFailedMessage = (_messageId, message) => {
    dispatch(
      sendMessage(
        message,
        currentConversationId,
        conversationSearchInput,
        activeFilters,
        1,
        convPerPage * convCurrPage,
        activeInbox,
        selectedDoctorInbox,
      ),
    );
  };

  const getMessageClouds = () => {
    return currentConversation.messages.map((message, index) => {
      const isDoctor = message.from === "doctor";
      const isAutomatic = message.is_automatic === 1;

      let combineWithPrevious = false;
      let dateDivider = true;
      if (index > 0) {
        const previousMessage = currentConversation.messages[index - 1];
        const isDoctorPrevious = previousMessage.from === "doctor";
        const isAutomaticPrevious = previousMessage.is_automatic === 1;

        if (isSameDay(message.send_at, previousMessage.send_at)) {
          dateDivider = false;
        }

        if (
          (isDoctor &&
            isAutomatic &&
            isDoctorPrevious &&
            isAutomaticPrevious &&
            isSameDay(message.send_at, previousMessage.send_at)) ||
          (isDoctor &&
            !isAutomatic &&
            isDoctorPrevious &&
            !isAutomaticPrevious &&
            message.sender.doctor_id === previousMessage.sender.doctor_id &&
            isSameDay(message.send_at, previousMessage.send_at)) ||
          (!isDoctor &&
            !isDoctorPrevious &&
            isSameDay(message.send_at, previousMessage.send_at))
        ) {
          combineWithPrevious = true;
        }
      }

      return (
        <div key={index}>
          {dateDivider && (
            <div
              ref={(el) => {
                dateRefs.current[index] = el;
              }}
              className="mx-auto flex items-center justify-center bg-gray-200 rounded-full w-fit text-xxs
                px-2 py-1 mt-4"
            >
              {getDateDivider(
                new Date(`${message.send_at.replace(/-/g, "/")} GMT+0`),
              )}
            </div>
          )}
          <MessageCloud
            combineWithPrevious={combineWithPrevious}
            type={isDoctor ? "outgoing" : "incoming"}
            firstName={
              isAutomatic
                ? "Quadrant"
                : message.sender.preferred_name
                  ? message.sender.preferred_name
                  : message.sender.first_name
            }
            lastName={isAutomatic ? "AI" : message.sender.last_name}
            displayName={isAutomatic ? "" : message.sender.display_name}
            profilePicture={
              isAutomatic
                ? automaticProfileSrc
                : isDoctor
                  ? message.sender.profile_picture
                  : patientPicture
            }
            message={message.message}
            time={message.send_at}
          />
        </div>
      );
    });
  };

  return (
    <div className="overflow-hidden relative flex">
      <div
        ref={messageContainer}
        className="pl-4 pb-4 pr-2 mr-2 overflow-y-auto scrollbar relative h-full w-full"
      >
        {loader ? (
          <ChatSkeleton />
        ) : (
          <>
            <div className="absolute top-0 left-1/2 h-0 w-fit overflow-visible pl-1">
              <div
                ref={dateIndicatorRef}
                className={`fixed z-10 -translate-x-1/2 flex flex-none whitespace-nowrap items-center justify-center
                  shadow-md transition-all duration-500 bg-gray-200 rounded-full w-fit text-xxs px-2 py-1 mt-4
                  ${showDateIndicator ? "translate-y-0 opacity-1" : "-translate-y-4 opacity-0"}`}
              >
                {dateIndicator}
              </div>
            </div>

            {currentConversation &&
              currentConversation.messages.length > 0 &&
              getMessageClouds()}
            {sentMessages[currentConversationId] &&
              sentMessages[currentConversationId].length > 0 &&
              sentMessages[currentConversationId].map((message, index) => {
                let combineWithPrevious = false;
                if (
                  lastMessage &&
                  lastMessage.from === "doctor" &&
                  lastMessage.is_automatic === 0 &&
                  lastMessage.sender.doctor_id === user.doctor_id &&
                  isToday(`${lastMessage.send_at.replace(/-/g, "/")} GMT+0`)
                ) {
                  combineWithPrevious = true;
                }
                return (
                  <div key={index}>
                    <MessageCloud
                      combineWithPrevious={
                        index === 0 ? combineWithPrevious : true
                      }
                      type="outgoing"
                      firstName={user.first_name}
                      lastName={user.last_name}
                      displayName={user.display_name}
                      profilePicture={user.profile_picture}
                      message={message}
                      time=""
                    />
                  </div>
                );
              })}
            {failedMessageCurrent.length > 0 &&
              failedMessageCurrent.map((message, index) => {
                let combineWithPrevious = false;
                if (
                  (lastMessage.from === "doctor" &&
                    lastMessage.is_automatic === 0 &&
                    lastMessage.sender.doctor_id === user.doctor_id &&
                    isToday(
                      `${lastMessage.send_at.replace(/-/g, "/")} GMT+0`,
                    )) ||
                  (sentMessages[currentConversationId] &&
                    sentMessages[currentConversationId].length > 0)
                ) {
                  combineWithPrevious = true;
                }
                return (
                  <div key={message.id}>
                    <MessageCloud
                      combineWithPrevious={
                        index === 0 ? combineWithPrevious : true
                      }
                      type="outgoing"
                      firstName={user.first_name}
                      lastName={user.last_name}
                      displayName={user.display_name}
                      profilePicture={user.profile_picture}
                      message={message.message}
                      time=""
                      failed
                      onResend={() =>
                        resendFailedMessage(message.id, message.message)
                      }
                    />
                  </div>
                );
              })}

            <div className="absolute bottom-8 left-1/2 h-0 w-fit overflow-visible">
              {unreadMessages > 0 &&
                scrolledUp &&
                currentConversationLengthChanged && (
                  <button
                    type="button"
                    className="fixed -translate-x-1/2 w-fit flex items-center justify-center text-xs text-white
              px-3 py-1 rounded-full bg-indigo-350"
                    onClick={() => scrollToBottom(true)}
                  >
                    View new messages
                  </button>
                )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Chat;
