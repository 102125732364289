import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../../store";

export interface NoteTemplate {
  note_template_id: number;
  title: string;
  content: string;
  sections: string[];
  is_custom: boolean;
  is_hidden: boolean;
}

export interface NoteTemplateState {
  isNoteTemplateLoading: boolean;
  noteTemplates: NoteTemplate[];
  defaultNoteTemplate: NoteTemplate | null;
  selectedNoteTemplate: NoteTemplate | null;
  selectedScribeNoteTemplate: NoteTemplate | null;
  showScribeNoteTemplate: boolean;
}

const initialState: NoteTemplateState = {
  isNoteTemplateLoading: false,
  noteTemplates: [],
  defaultNoteTemplate: null,
  selectedNoteTemplate: null,
  selectedScribeNoteTemplate: null,
  showScribeNoteTemplate: false,
};

export const noteTemplateSlice = createSlice({
  name: "noteTemplate",
  initialState,
  reducers: {
    setNoteTemplatesLoading: (state, action: PayloadAction<boolean>) => {
      state.isNoteTemplateLoading = action.payload;
    },
    setNoteTemplates: (state, action: PayloadAction<NoteTemplate[]>) => {
      state.noteTemplates = action.payload;
    },
    setDefaultNoteTemplate: (state, action: PayloadAction<NoteTemplate>) => {
      state.defaultNoteTemplate = action.payload;
    },
    setSelectedNoteTemplate: (state, action: PayloadAction<NoteTemplate>) => {
      state.selectedNoteTemplate = action.payload;
    },
    setShowScribeNoteTemplate: (state, action: PayloadAction<boolean>) => {
      state.showScribeNoteTemplate = action.payload;
    },
    setSelectedScribeNoteTemplate: (
      state,
      action: PayloadAction<NoteTemplate>,
    ) => {
      state.selectedScribeNoteTemplate = action.payload;
    },
  },
});

export const {
  setNoteTemplates,
  setDefaultNoteTemplate,
  setSelectedNoteTemplate,
  setShowScribeNoteTemplate,
  setSelectedScribeNoteTemplate,
} = noteTemplateSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.noteTemplate.value;
