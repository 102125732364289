const tagVariants = {
  green: "text-emerald-450 bg-emerald-450/12",
  yellow: "text-amber-450 bg-amber-400/12",
  violet: "text-violet-700 bg-purple-50",
  blue: "text-white bg-primary-blue border border-transparent",
  "dark-blue": "text-white bg-slate-900",
  gray: "text-tertiary bg-gray-foreground",
  "gray-light": "text-zinc-700 border bg-white",
  slate: "text-slate-700 bg-gray-100",
  red: "text-red-700 bg-red-50",
  amber: "text-amber-700 bg-amber-50",
  emerald: "text-emerald-700 bg-emerald-50",
  sky: "text-sky-700 bg-sky-50",
};

const Tag = ({
  variant,
  text,
  className = "py-1 px-2",
  withDot = false,
  ...props
}) => {
  return (
    <button
      {...props}
      type="button"
      className={`text-xs rounded-full w-fit flex items-center ${tagVariants[variant]} ${className}`}
    >
      {withDot && (
        <div className="mr-1.5 h-1.25 w-1.25 rounded-md bg-primary-blue" />
      )}
      {text}
    </button>
  );
};

export default Tag;
