import Modal from "./Modal";
import Button from "./Button";

const DeleteModalWithoutWarning = ({
  onClose,
  onDelete,
  title,
  description,
}) => {
  return (
    <Modal className="pt-5 rounded-2xl">
      <div className="w-106">
        <p className="text-base md:text-xl font-semibold mb-5 px-5">{title}</p>

        <p className="text-sm md:text-base font-medium px-5">{description}</p>

        <div className="flex justify-end gap-4 font-semibold text-sm bg-gray-background py-4 px-5 mt-5">
          <Button variant="gray" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onDelete}>Delete</Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModalWithoutWarning;
