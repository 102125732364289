import {
  SET_PRACTICE_INFO,
  SET_REGISTERED_EMAIL_ADDRESS,
  SET_VERIFICATION_CODE,
} from "../types";

export function setRegisteredEmailAddress(registeredEmailAddress) {
  return {
    type: SET_REGISTERED_EMAIL_ADDRESS,
    payload: { registeredEmailAddress },
  };
}

export function setVerificationCode(verificationCode) {
  return {
    type: SET_VERIFICATION_CODE,
    payload: { verificationCode },
  };
}

export function setPracticeInfo(practiceInfo) {
  return {
    type: SET_PRACTICE_INFO,
    payload: { practiceInfo },
  };
}
