import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow-down-thin.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout-simple.svg";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu.svg";
import { ReactComponent as LogoIcon } from "../../assets/logo-quadrant-text.svg";
import { Pages } from "../../helpers/constants";
import { selectFacility } from "../../store/facility/thunks";
import { logoutAction } from "../../store/user/actions";
import ProfilePicture from "../Basic/ProfilePicture";

const MobileMenu = ({ navButtons, handleNavLinkClick }) => {
  const { user, onlyScribeFeature } = useSelector((state) => state.user);
  const { userFacilities } = useSelector((state) => state.facility);
  const [facilities, setFacilities] = useState(
    userFacilities[user.doctor_id] || [],
  );
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [openFacilityList, setOpenFacilityList] = useState(false);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const dispatch = useDispatch();
  const logoLink = onlyScribeFeature ? Pages.SCRIBE : Pages.MESSAGING;

  useEffect(() => {
    setFacilities(userFacilities[user.doctor_id] || []);
  }, [userFacilities, user]);

  useEffect(() => {
    if (facilities?.length > 1) {
      setFacilityOptions(
        facilities.filter(
          (facility) => facility.customer_id !== user.customer_id,
        ),
      );
    }
  }, [facilities, user]);

  useEffect(() => {
    if (showMobileMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showMobileMenu]);

  const onClickFacility = () => {
    setOpenFacilityList(!openFacilityList);
  };

  const onSelectFacility = (customerId) => {
    dispatch(selectFacility(customerId));
  };

  const logout = () => {
    dispatch(logoutAction());
  };

  return (
    <nav className="fixed top-0 left-0 flex items-center justify-between w-screen h-16 py-3 px-5 flex-none bg-white z-50">
      <NavLink
        aria-label="Inbox"
        to={logoLink}
        className="z-50"
        onClick={(e) => handleNavLinkClick(e, logoLink)}
      >
        <LogoIcon width="128" height="40" className="flex-none" />
      </NavLink>

      <button
        type="button"
        aria-label="Menu"
        onClick={() => setShowMobileMenu(!showMobileMenu)}
        className="z-50"
      >
        {showMobileMenu ? (
          <CloseIcon stroke="#121212" width="24" height="24" />
        ) : (
          <MenuIcon stroke="#121212" width="24" height="24" />
        )}
      </button>

      <div
        className={`fixed top-0 left-0 h-screen-dynamic pt-16 z-10 text-tertiary transition-all duration-300
          ${showMobileMenu ? "w-screen" : "w-0"}`}
      >
        <div className="h-full bg-white overflow-x-hidden flex flex-col space-y-4 justify-between pb-8">
          <div
            className="flex flex-col space-y-4 border-t border-white/20 pt-7 px-5"
            onClick={() => setShowMobileMenu(false)}
          >
            {navButtons}
          </div>

          <div>
            {/* <p
              className={`text-xs text-right p-3 ${showMobileMenu ? "" : "hidden"}`}
            >
              Version: {process.env.REACT_APP_VERSION}
            </p> */}
            {!onlyScribeFeature && facilityOptions.length > 0 && (
              <>
                <div
                  className="cursor-pointer select-none grid grid-cols-conv-layout items-center font-medium truncate
                  py-4 px-6 border-t bg-gray-background"
                  onClick={onClickFacility}
                >
                  <div className="grid grid-cols-2-right items-center gap-3 truncate flex-none">
                    <ProfilePicture
                      firstName={
                        user.customer.display_name || user.customer.name
                      }
                      flagPadding={false}
                      bgColor="primary-blue-light"
                    />
                    <p className="truncate">
                      {user.customer.display_name || user.customer.name}
                    </p>
                  </div>
                  <ArrowIcon
                    width="12"
                    height="8"
                    className={`transition-all ml-3 ${openFacilityList ? "-rotate-180" : ""}`}
                  />
                </div>

                <div
                  className={`px-5 transition-all overflow-hidden grid grid-rows-tab-layout border-b
                  ${openFacilityList ? "h-fit py-3" : "h-0 py-0"}
                `}
                >
                  <p className="uppercase text-tertiary font-medium text-xs tracking-wide truncate my-2">
                    Select clinic
                  </p>
                  <div className="h-full space-y-1 overflow-x-hidden">
                    {facilityOptions.map((facility) => (
                      <div
                        key={facility.customer_id}
                        className="grid grid-cols-2-right gap-3 flex-none py-2 cursor-pointer"
                        onClick={() => onSelectFacility(facility.customer_id)}
                      >
                        <ProfilePicture
                          firstName={facility.display_name || facility.name}
                          flagPadding={false}
                          bgColor="gray-110"
                          textColor="tertiary"
                        />
                        <p className="text-wrap pt-1">
                          {facility.display_name || facility.name}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}

            <div className="flex items-center px-5 mt-4">
              <ProfilePicture
                size={11}
                fontSize="base"
                className="cursor-pointer"
                firstName={user.first_name}
                lastName={user.last_name}
                src={user.profile_picture}
                flagPadding={false}
              />
              <div className="ml-3 space-y-1">
                <p>{user.display_name}</p>
                <p className="text-zinc-500 text-xs">{user.email}</p>
              </div>
              <button
                type="button"
                aria-label="Logout"
                className="ml-auto"
                onClick={logout}
              >
                <LogoutIcon width="24" height="24" stroke="#667085" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default MobileMenu;
