import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as NoteIcon } from "../../assets/icons/billing-note.svg";
import { ReactComponent as ExclamationIcon } from "../../assets/icons/exclamation-circle.svg";
import { ReactComponent as GenerateIcon } from "../../assets/icons/file-check.svg";
import {
  generateNote,
  getBillingPatients,
} from "../../store/billingMetrics/thunks";
import ActionsButton from "../Basic/ActionsButton";
import Loader from "../Basic/Loader";
import TimeButton from "./TimeButton";

const PatientListItem = ({ patient, onClick, setSelectedNote }) => {
  const dispatch = useDispatch();
  const {
    currentDateStamp,
    rowsPerPage,
    currPage,
    selectedBillingType,
    selectedCptCode,
    sortedBy,
    sortingDirection,
    billingSearchValue,
  } = useSelector((state) => state.billingMetrics);

  return (
    <div
      className="bg-white p-4 rounded-xl border-b border-gray-foreground space-y-3"
      onClick={onClick}
    >
      <div className="flex items-center justify-between space-x-2 truncate">
        <p className="truncate text-base font-semibold">
          {patient.preferred_name ? patient.preferred_name : patient.first_name}{" "}
          {patient.last_name}
        </p>

        <ActionsButton
          actions={[
            {
              icon: (
                <GenerateIcon
                  width="21"
                  height="20"
                  className="flex-none"
                  fill="#667085"
                  stroke="#667085"
                />
              ),
              label: "Generate Note",
              onClick: () => {
                dispatch(
                  generateNote(patient.monthlyBillId, currentDateStamp, () =>
                    dispatch(
                      getBillingPatients(
                        currentDateStamp,
                        rowsPerPage * currPage,
                        billingSearchValue,
                        selectedBillingType,
                        selectedCptCode,
                        sortedBy,
                        sortingDirection,
                      ),
                    ),
                  ),
                );
              },
            },
          ]}
        />
      </div>
      <div className="text-sm font-medium">
        {patient.conditions.replace(/\d+\.\s*/g, "").replace(/\n/g, ", ")}
      </div>
      <div className="flex items-center justify-between">
        <TimeButton patient={patient} className="" />
        <button
          type="button"
          className="rounded-full border border-grey-foreground bg-slate-100 w-[58px] h-7
            flex items-center justify-center text-tertiary text-sm font-semibold relative"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (patient.note) {
              setSelectedNote({
                monthlyBillId: patient.monthlyBillId,
                note: patient.note,
                noteTitle: patient.note_title,
                name: patient.preferred_name
                  ? patient.preferred_name
                  : `${patient.first_name} ${patient.last_name}`,
              });
            }
          }}
          disabled={!patient.note}
        >
          {patient.billingType}
          {patient.note && (
            <div className="w-5 h-5 absolute -top-1.5 -right-1.5 flex items-center justify-center flex-none">
              {patient.note_status === "generating" ? (
                <Loader size={4} borderWidth={2} />
              ) : patient.note && patient.note_status === "not_enough" ? (
                <ExclamationIcon width="20" height="20" className="flex-none" />
              ) : (
                patient.note && (
                  <NoteIcon width="20" height="20" className="flex-none" />
                )
              )}
            </div>
          )}
        </button>
      </div>
    </div>
  );
};

export default PatientListItem;
