import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as PauseIcon } from "../../assets/icons/pause-circle.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/pen.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/trash-redesign.svg";
import { getAge } from "../../helpers/helpers";
import { setSelectedPatientsAction } from "../../store/patient/actions";
import ActionsButton from "../Basic/ActionsButton";
import CircleDivider from "../Basic/CircleDivider";
import Tag from "../Basic/Tag";
import IndeterminateCheckbox from "./IndeterminateCheckbox";

const PatientItem = ({ patient, onClick, onEdit, onPause, onDelete }) => {
  const { selectedPatients } = useSelector((state) => state.patient);
  const [selected, setSelected] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setSelected(
      selectedPatients.some(
        (selectedPatient) => selectedPatient.patient_id === patient.patient_id,
      ),
    );
  }, [selectedPatients]);

  return (
    <div
      className={`p-4 rounded-xl border-b border-gray-foreground space-y-2
        ${selected ? "bg-gray-140" : "bg-white"}`}
      onClick={onClick}
    >
      <div className="flex items-center justify-between space-x-2 truncate">
        <div className="flex items-center space-x-2">
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <IndeterminateCheckbox
              checked={selected}
              onChange={() => {
                if (selected) {
                  dispatch(
                    setSelectedPatientsAction(
                      selectedPatients.filter(
                        (selectedPatient) =>
                          selectedPatient.patient_id !== patient.patient_id,
                      ),
                    ),
                  );
                } else {
                  dispatch(
                    setSelectedPatientsAction([...selectedPatients, patient]),
                  );
                }
              }}
            />
          </div>

          <p className="truncate text-base font-semibold">
            {patient.preferred_name
              ? patient.preferred_name
              : patient.first_name}{" "}
            {patient.last_name}
          </p>
        </div>

        <ActionsButton
          actions={[
            {
              icon: (
                <EditIcon
                  width="20"
                  height="20"
                  stroke="#667085"
                  className="flex-none"
                />
              ),
              label: "Edit",
              onClick: () => onEdit(patient),
            },
            {
              icon: (
                <PauseIcon
                  width="20"
                  height="20"
                  stroke="#667085"
                  className="flex-none"
                />
              ),
              label: patient.is_paused ? "Unpause" : "Pause",
              onClick: () =>
                onPause(patient.patient_id, patient.is_paused === 1),
            },
            {
              icon: (
                <DeleteIcon
                  width="20"
                  height="20"
                  stroke="#667085"
                  className="flex-none"
                />
              ),
              label: "Delete",
              onClick: () => onDelete(patient),
            },
          ]}
        />
      </div>
      <div className="flex items-center justify-between space-x-2 truncate">
        <div className="truncate text-sm font-medium text-tertiary flex items-center space-x-1">
          {patient.birthdate && (
            <>
              <span>{getAge(patient.birthdate)}</span>
              {(patient.gender || patient.sex || patient.birthdate) && (
                <CircleDivider className="flex-none" />
              )}
            </>
          )}
          {(patient.gender || patient.sex) && (
            <>
              <span className="capitalize">
                {patient.gender
                  ? patient.gender.charAt(0)
                  : patient.sex.charAt(0)}
              </span>
              {patient.birthdate && <CircleDivider className="flex-none" />}
            </>
          )}
          {patient.birthdate && (
            <>
              <span>
                {new Date(
                  patient.birthdate.replace(/-/g, "/"),
                ).toLocaleDateString("en-us", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}
              </span>
              {patient.chart_number && <CircleDivider className="flex-none" />}
            </>
          )}
          {patient.chart_number && <span>{patient.chart_number}</span>}
        </div>
        <Tag text={patient.care_coordination} variant="gray" />
      </div>
    </div>
  );
};

export default PatientItem;
