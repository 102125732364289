export enum RecoveryStrategy {
  RESUME_CONTEXT = "resumeContext",
  RECREATE_NODES = "recreateNodes",
  RECONNECT_STREAM = "reconnectStream",
  RESTART_CONTEXT = "restartContext",
  FULL_RESET = "fullReset",
}

export interface RecoveryAttempt {
  strategy: RecoveryStrategy;
  timestamp: number;
  successful: boolean;
  error?: Error;
}

export interface RecoveryState {
  attempts: RecoveryAttempt[];
  currentStrategy: RecoveryStrategy;
  isRecovering: boolean;
  lastSuccessfulRecovery: number | null;
  consecutiveFailures: number;
}
