import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { timeOptions } from "../../../helpers/constants";
import FilterDropdownButton from "./FilterDropdownButton";
import FilterOther from "./FilterOther";
import FilterFlags from "./FilterFlags";
import MockFilterButton from "./MockFilterButton";
import { ReactComponent as StatusIcon } from "../../../assets/icons/status.svg";
import { ReactComponent as CampaignIcon } from "../../../assets/icons/campaigns.svg";
import { ReactComponent as TimeIcon } from "../../../assets/icons/clock.svg";

const mockCategoryOptions = [
  { value: "Scheduling", text: "Scheduling" },
  { value: "Medication Refill", text: "Medication Refill" },
  { value: "Billing/Insurance", text: "Billing/Insurance" },
  { value: "Sick Note", text: "Sick Note" },
  { value: "Test Results Inquiry", text: "Test Results Inquiry" },
  { value: "Referral Request", text: "Referral Request" },
  { value: "Medical Question", text: "Medical Question" },
  { value: "other", text: "Other" },
];
const mockTypeOptions = [
  { value: "Urgent", text: "Urgent" },
  { value: "Clinical", text: "Clinical" },
  { value: "Administrative", text: "Administrative" },
];

const FilterDropdownButtons = ({ className }) => {
  const { activeInbox } = useSelector((state) => state.conversation);
  const [openedDropdown, setOpenedDropdown] = useState("");

  useEffect(() => {
    setOpenedDropdown("");
  }, [activeInbox]);

  return (
    <div className={`flex w-full justify-between relative z-10 ${className}`}>
      <MockFilterButton
        filterName="category"
        icon={<StatusIcon width="20" height="20" fill="#3C3C47" />}
        arrow
        options={mockCategoryOptions}
        openedDropdown={openedDropdown}
        setOpenedDropdown={setOpenedDropdown}
      />
      <MockFilterButton
        filterName="type"
        icon={<CampaignIcon width="25" height="20" stroke="#3C3C47" />}
        arrow
        options={mockTypeOptions}
        openedDropdown={openedDropdown}
        setOpenedDropdown={setOpenedDropdown}
      />

      <FilterDropdownButton
        filterName="time"
        icon={<TimeIcon width="20" height="20" stroke="#3C3C47" />}
        arrow
        options={timeOptions}
        openedDropdown={openedDropdown}
        setOpenedDropdown={setOpenedDropdown}
      />
      <FilterFlags
        filterName="flag"
        arrow
        openedDropdown={openedDropdown}
        setOpenedDropdown={setOpenedDropdown}
      />
      <FilterOther
        arrow
        openedDropdown={openedDropdown}
        setOpenedDropdown={setOpenedDropdown}
      />
    </div>
  );
};

export default FilterDropdownButtons;
