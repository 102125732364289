// useIosAudioContextResume.ts
import { useCallback } from "react";
import useEventListener from "./useEventListener";

const useIosAudioContextResume = (
  audioContextRef: React.RefObject<AudioContext | null>,
) => {
  const handleTouchStart = useCallback(() => {
    const isiOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if (
      isiOS &&
      audioContextRef.current &&
      audioContextRef.current.state === "suspended"
    ) {
      audioContextRef.current.resume();
    }
  }, [audioContextRef]);

  useEventListener("touchstart", handleTouchStart, document.body);
};

export default useIosAudioContextResume;
