import axios from "axios";
import { BASE_URL } from "../../helpers/config";
import { setNotificationAction } from "../user/actions";
import { setTemplatesAction, setTemplatesLoaderAction } from "./actions";
import Bugsnag from "@bugsnag/js";

export const getTemplates = () => (dispatch) => {
  dispatch(setTemplatesLoaderAction(true));
  axios.get(`${BASE_URL}/campaign-template/list`).then((response) => {
    if (response.data.error) {
      dispatch(
        setNotificationAction({
          status: "error",
          title: "Something went wrong",
          desc: "Failed to load templates",
        }),
      );
      Bugsnag.notify(response.data.error);
    } else {
      dispatch(setTemplatesAction(response.data.data.templates));
    }
    dispatch(setTemplatesLoaderAction(false));
  });
};

export const getTemplate = (id) => (dispatch) => {
  axios.get(`${BASE_URL}/campaign-template/describe/${id}`).then((response) => {
    if (response.data.error) {
      dispatch(
        setNotificationAction({
          status: "error",
          title: "Something went wrong",
          desc: "Failed to load template",
        }),
      );
      Bugsnag.notify(response.data.error);
    } else {
      console.log(response.data.data.template);
    }
  });
};

export const createTemplate =
  (name, conditions, goals, onSuccess = () => {}) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/campaign-template/create`, {
        template: {
          name: name,
          conditions: conditions,
          goals: goals,
        },
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to create template",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(getTemplates());
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Template created successfully",
              desc: "New template has been successfully created",
            }),
          );
          onSuccess();
        }
      });
  };

export const updateTemplate =
  (id, conditions, goals, onSuccess = () => {}) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/campaign-template/update/${id}`, {
        template: {
          conditions: conditions,
          goals: goals,
        },
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to update template",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(getTemplates());
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Template updated successfully",
              desc: "The template has been successfully updated",
            }),
          );
          onSuccess();
        }
      });
  };

export const renameTemplate =
  (id, name, onSuccess = () => {}) =>
  (dispatch) => {
    dispatch(setTemplatesLoaderAction(true));
    axios
      .post(`${BASE_URL}/campaign-template/update/${id}`, {
        template: {
          name: name,
        },
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to rename template",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(getTemplates());
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Template renamed successfully",
              desc: "The template has been successfully renamed",
            }),
          );
          onSuccess();
        }
        dispatch(setTemplatesLoaderAction(false));
      });
  };

export const deleteTemplate =
  (id, onSuccess = () => {}) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/campaign-template/delete/${id}`)
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to delete template",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(getTemplates());
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Template deleted successfully",
              desc: "The template has been successfully deleted",
            }),
          );
          onSuccess();
        }
      });
  };
