import { isEqual } from "lodash";

import { NotesTabs, OrganizationTabs } from "../../helpers/constants";
import LocalStorageService from "../../services/LocalStorageService";
import {
  LOGOUT,
  OPEN_SUCCESS_MODAL,
  SET_ASSISTANTS,
  SET_DOCTOR_OPTIONS,
  SET_ERROR,
  SET_NOTIFICATION,
  SET_ONLY_SCRIBE_FEATURE,
  SET_ROLES,
  SET_ROLE_OPTIONS,
  SET_SELECTED_CARE_MANAGEMENT_TAB,
  SET_SELECTED_NOTES_TAB,
  SET_SELECTED_ORGANIZATION_TAB,
  SET_SELECTED_SETTINGS_TAB,
  SET_SIGNUP_INFO,
  SET_TEAMS,
  SET_TEAMS_LOADING,
  SET_TEAM_SEARCH_VALUE,
  SET_USER,
  SET_USERS,
  SET_USERS_LOADING,
  SET_USERS_WITHOUT_TEAM,
  SET_USER_SEARCH_VALUE,
} from "../types";

const storageService = new LocalStorageService();

const init = {
  user: storageService.getItem("user") ?? null,
  users: [],
  roles: {
    1: "Admin",
    2: "User",
  },
  roleOptions: [
    { value: "1", text: "Admin" },
    { value: "2", text: "User" },
  ],
  errorMessage: "",
  notification: null,
  successModal: false,
  assistants: [],
  signupInfo: {},
  doctorOptions: [],
  selectedCareManagementTab: null,
  selectedSettingsTab: null,
  selectedOrganizationTab:
    storageService.getItem("selectedOrganizationTab") ?? OrganizationTabs.USERS,
  selectedNotesTab:
    storageService.getItem("selectedNotesTab") ?? NotesTabs.GENERAL,
  usersLoading: true,
  teamsLoading: true,
  userSearchValue: "",
  teamSearchValue: "",
  usersWithoutTeam: [],
  teams: [],
  onlyScribeFeature: false,
};

export default function userReducer(state = init, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGOUT:
      storageService.removeItem("token");
      storageService.removeItem("user");
      storageService.removeItem("onlyScribeFeature");
      storageService.removeItem("token");
      storageService.removeItem("user");
      storageService.removeItem("selectedOrganizationTab");
      storageService.removeItem("selectedNotesTab");
      return { ...init, user: null };
    case SET_USER:
      if (isEqual(state.user, payload.user)) {
        return state;
      }

      return { ...state, user: payload.user };
    case SET_USERS:
      return { ...state, users: payload.users };
    case SET_ROLES:
      return { ...state, roles: payload.roles };
    case SET_ROLE_OPTIONS:
      return { ...state, roleOptions: payload.roleOptions };
    case SET_ERROR:
      return { ...state, errorMessage: payload.errorMessage };
    case SET_NOTIFICATION:
      return { ...state, notification: payload.notification };
    case OPEN_SUCCESS_MODAL:
      return { ...state, successModal: payload.successModal };
    case SET_ASSISTANTS:
      return { ...state, assistants: payload.assistants };
    case SET_SIGNUP_INFO:
      return { ...state, signupInfo: payload.signupInfo };
    case SET_DOCTOR_OPTIONS:
      if (isEqual(state.doctorOptions, payload.doctorOptions)) {
        return state;
      }

      return { ...state, doctorOptions: payload.doctorOptions };
    case SET_SELECTED_CARE_MANAGEMENT_TAB:
      return {
        ...state,
        selectedCareManagementTab: payload.selectedCareManagementTab,
      };
    case SET_SELECTED_SETTINGS_TAB:
      return {
        ...state,
        selectedSettingsTab: payload.selectedSettingsTab,
      };
    case SET_SELECTED_ORGANIZATION_TAB:
      storageService.setItem(
        "selectedOrganizationTab",
        payload.selectedOrganizationTab,
      );
      return {
        ...state,
        selectedOrganizationTab: payload.selectedOrganizationTab,
      };
    case SET_SELECTED_NOTES_TAB:
      storageService.setItem("selectedNotesTab", payload.selectedNotesTab);
      return { ...state, selectedNotesTab: payload.selectedNotesTab };
    case SET_USERS_LOADING:
      return { ...state, usersLoading: payload.loading };
    case SET_TEAMS_LOADING:
      return { ...state, teamsLoading: payload.loading };
    case SET_USER_SEARCH_VALUE:
      return { ...state, userSearchValue: payload.userSearchValue };
    case SET_TEAM_SEARCH_VALUE:
      return { ...state, teamSearchValue: payload.teamSearchValue };
    case SET_USERS_WITHOUT_TEAM:
      return { ...state, usersWithoutTeam: payload.usersWithoutTeam };
    case SET_TEAMS:
      return { ...state, teams: payload.teams };
    case SET_ONLY_SCRIBE_FEATURE:
      storageService.setItem("onlyScribeFeature", payload.onlyScribeFeature);
      return { ...state, onlyScribeFeature: payload.onlyScribeFeature };
    default:
      return state;
  }
}
