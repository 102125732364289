import { useAppSelector } from "../../../store";
import ScribeListSkeleton from "../../Skeletons/ScribeListSkeleton";
import ScribeListContent from "../ScribeListContent";
import ScribeListEmpty from "../ScribeListEmpty";
import ScribeListFooter from "../ScribeListFooter";
import ScribeListHeader from "../ScribeListHeader";
import { getGroupedScribes } from "../store/selectors";

const ScribeList = ({
  handleSelectScribe,
  setIsNewScribe,
}: {
  handleSelectScribe: (scribe: { audioId: string }) => void;
  setIsNewScribe: () => void;
}) => {
  const groupedScribes = useAppSelector(getGroupedScribes);
  const { isLoading } = useAppSelector((state) => state.scribe);

  return groupedScribes.length > 0 ? (
    <div className="flex flex-col space-y-4 h-full w-full overflow-hidden">
      <ScribeListHeader />
      <div className="h-full flex flex-col overflow-hidden pb-20 md:pb-18">
        <ScribeListContent
          handleSelectScribe={handleSelectScribe}
          className="z-[1]"
        />
        <ScribeListFooter setIsNewScribe={setIsNewScribe} />
        <div
          className="fixed top-0 md:left-[unset] -ml-4 md:w-[300px]
            bg-gray-background w-full h-full"
        />
      </div>
    </div>
  ) : isLoading ? (
    <div className="flex flex-col space-y-4 h-full w-full overflow-hidden">
      <ScribeListHeader />
      <div className="flex flex-col overflow-hidden pb-20 md:pb-18">
        <ScribeListSkeleton className="pb-18 relative z-[1]" />
        <ScribeListFooter setIsNewScribe={setIsNewScribe} />
        <div
          className="fixed top-0 md:left-[unset] -ml-4 md:w-[300px]
            bg-gray-background w-full h-full"
        />
      </div>
    </div>
  ) : (
    <ScribeListEmpty setIsNewScribe={setIsNewScribe} />
  );
};

export default ScribeList;
