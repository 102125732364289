import { useEffect, useState } from "react";
import { useAppSelector } from "../../../store";
import { BANNER_CONFIGS } from "../consts";
import { getRecordingScribe, getSelectedScribe } from "../store/selectors";
import type { Banner } from "../types";

interface BannerWarningsReturn {
  banner: Banner | null;
  isBannerWarningVisible: boolean;
  showBannerWarning: () => void;
  hideBannerWarning: () => void;
}

export const useBannerWarnings = (
  isStalled: boolean,
  isDesktop: boolean,
  notEnoughTranscript?: boolean,
  onNotEnoughTranscriptSubmit?: () => void,
  isNoteGenerationDelayed?: boolean,
  onGenerationDelayedSubmit?: () => void,
): BannerWarningsReturn => {
  const selectedScribe = useAppSelector(getSelectedScribe);
  const recordingScribe = useAppSelector(getRecordingScribe);
  const [banner, setBanner] = useState<Banner | null>(null);
  const [isBannerWarningVisible, setIsBannerWarningVisible] = useState(false);

  const showBannerWarning = () => setIsBannerWarningVisible(true);
  const hideBannerWarning = () => setIsBannerWarningVisible(false);

  useEffect(() => {
    if (!isDesktop) {
      return;
    }

    const shouldShowMicrophoneBanner =
      isStalled &&
      (recordingScribe?.isRecording || !recordingScribe?.isRecording);

    if (shouldShowMicrophoneBanner) {
      setBanner({
        ...BANNER_CONFIGS.microphoneAccessLost,
        onSubmit: () => window.location.reload(),
      });
    } else if (!isStalled && banner?.id === "microphoneAccessLost") {
      hideBannerWarning();
      setBanner(null);
    }
  }, [isStalled, recordingScribe, isDesktop, banner?.id]);

  useEffect(() => {
    if (!isDesktop) {
      return;
    }

    if (notEnoughTranscript && onNotEnoughTranscriptSubmit) {
      setBanner({
        ...BANNER_CONFIGS.notEnoughTranscript,
        onSubmit: onNotEnoughTranscriptSubmit,
      });
      showBannerWarning();
    } else if (!notEnoughTranscript && banner?.id === "notEnoughTranscript") {
      hideBannerWarning();
      setBanner(null);
    }
  }, [notEnoughTranscript, isDesktop, onNotEnoughTranscriptSubmit, banner?.id]);

  useEffect(() => {
    if (!banner) {
      return;
    }

    if (selectedScribe?.isPaused) {
      showBannerWarning();
    } else if (selectedScribe?.isRecording) {
      hideBannerWarning();
    }
  }, [selectedScribe?.isPaused, selectedScribe?.isRecording, banner]);

  useEffect(() => {
    if (!isDesktop) {
      return;
    }

    if (isNoteGenerationDelayed) {
      setBanner({
        ...BANNER_CONFIGS.noteGenerationDelayed,
        onSubmit: onGenerationDelayedSubmit || hideBannerWarning,
      });
      showBannerWarning();
    } else if (banner?.id === "noteGenerationDelayed") {
      hideBannerWarning();
      setBanner(null);
    }
  }, [isNoteGenerationDelayed, isDesktop, banner?.id]);

  return {
    banner,
    isBannerWarningVisible,
    showBannerWarning,
    hideBannerWarning,
  };
};
