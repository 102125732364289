import { useEffect, useState } from "react";

const LoadingProgressButton = ({
  label,
  duration = 20000,
}: {
  label: string;
  duration?: number;
}) => {
  const [progress, setProgress] = useState(0);
  const [dotLoading, setDotLoading] = useState(0);

  useEffect(() => {
    const startTime = Date.now();

    const interval = setInterval(() => {
      const elapsedTime = Date.now() - startTime;

      if (elapsedTime < duration) {
        setProgress(elapsedTime / duration);
      } else {
        clearInterval(interval);
        setProgress(1);
      }
    }, 50);

    return () => clearInterval(interval);
  }, [duration]);

  useEffect(() => {
    const interval = setTimeout(() => {
      if (dotLoading === 3) {
        setDotLoading(0);
      } else {
        setDotLoading(dotLoading + 1);
      }
    }, 800);

    return () => clearInterval(interval);
  }, [dotLoading]);

  const buttonClass = `
    relative
    w-full
    text-left
    py-3 px-4
    sm:rounded
    overflow-hidden
    font-semibold
    bg-gray-foreground
    text-primary
    text-center
  `;

  const progressStyle = {
    transform: `scaleX(${1 - progress})`,
    transformOrigin: "right",
    transition: progress === 1 ? "transform 0.3s linear" : "none",
  };

  return (
    <button type="button" disabled className={buttonClass}>
      <span className="z-10 lg:max-w-max max-w-[95px]">
        {`${label}`}
        <span className="inline-block min-w-[11px] lg:w-auto text-left">
          {".".repeat(dotLoading)}
        </span>
      </span>
      <div className="absolute inset-0 bg-[#ffffff99]" style={progressStyle} />
    </button>
  );
};

export default LoadingProgressButton;
