import React, { useMemo } from "react";

const TOTAL_BARS = 8;
const ACTIVE_BAR_CLASS = "bg-primary-blue";
const INACTIVE_BAR_CLASS = "bg-gray-300";

// You can adjust the base to change the scaling sensitivity
const LOG_BASE = 10;

const MicrophoneTestVolumeBars = React.memo(
  ({ volume }: { volume: number }) => {
    // Clamp the volume between 0 and 1 to handle edge cases
    const clampedVolume = useMemo(
      () => Math.min(Math.max(volume, 0), 1),
      [volume],
    );

    // Calculate the normalized volume using logarithmic scaling
    const normalizedVolume = useMemo(() => {
      // Avoid log(0) by ensuring the input to log10 is >= 1
      const scaledVolume =
        Math.log10(clampedVolume * (LOG_BASE - 1) + 1) / Math.log10(LOG_BASE);
      return scaledVolume;
    }, [clampedVolume]);

    // Determine which bars should be active based on the normalized volume
    const barStates = useMemo(() => {
      return Array.from({ length: TOTAL_BARS }, (_, index) => {
        const normalizedIndex = (index + 1) / TOTAL_BARS;
        return normalizedVolume >= normalizedIndex;
      });
    }, [normalizedVolume]);

    return (
      <div className="flex h-5">
        {barStates.map((isActive, index) => (
          <div
            key={index}
            className={`grow h-full w-1 my-0 ml-1.5 rounded-full ${
              isActive ? ACTIVE_BAR_CLASS : INACTIVE_BAR_CLASS
            }`}
          />
        ))}
      </div>
    );
  },
);

export default MicrophoneTestVolumeBars;
