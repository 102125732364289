import { ReactComponent as CloseIcon } from "../../../assets/icons/x-mark.svg";
import Button from "../Button";
import ModalMobile from "../ModalMobile";

const defaultCancelText = "Cancel";

const Warning = ({
  image,
  title,
  description,
  submitText,
  cancelText = defaultCancelText,
  horizontal,
  onCancel,
  onSubmit = () => {},
  className = "",
  noButtonsOnMobile = false,
}: {
  image: string;
  title: string;
  description: string;
  submitText: string;
  cancelText?: string;
  horizontal?: boolean;
  onCancel: () => void;
  onSubmit?: () => void;
  className?: string;
  noButtonsOnMobile?: boolean;
}) => {
  return (
    <>
      <div
        className={`hidden md:block
          ${horizontal ? "absolute top-0 right-0 w-full p-3" : "fixed bottom-24 right-6 w-75"} ${className}`}
      >
        <div
          className={`flex w-full p-4 bg-gray-background shadow-sm z-20
          ${horizontal ? "flex-row items-start gap-6" : "flex-col items-center"}
        `}
        >
          {!horizontal && (
            <button type="button" onClick={onCancel} className="ml-auto">
              <CloseIcon width="24" height="24" stroke="#75A4FE" />
            </button>
          )}
          <img
            src={image}
            alt="warning"
            className={`${horizontal ? "my-auto" : "my-6"}`}
            width={horizontal ? 124 : 172}
            height={horizontal ? 50 : 70}
          />
          <div
            className={`flex flex-col ${horizontal ? "items-start" : "items-center pb-2"} ${!submitText ? "self-center p-0" : ""}`}
          >
            <p
              className={`text-lg md:text-base text-primary font-semibold
                ${horizontal ? "mb-1" : "text-center mb-2"}`}
            >
              {title}
            </p>
            <p
              className={`text-sm text-tertiary font-normal ${horizontal ? "mb-1" : "text-center mb-6"}`}
            >
              {description}
            </p>
            <div className="flex gap-4">
              {cancelText && cancelText !== defaultCancelText && (
                <button
                  type="button"
                  className="text-sm text-primary-blue font-semibold"
                  onClick={onCancel}
                >
                  {cancelText}
                </button>
              )}
              {submitText && (
                <button
                  type="button"
                  className="text-sm text-primary-blue font-semibold"
                  onClick={onSubmit}
                >
                  {submitText}
                </button>
              )}
            </div>
          </div>
          {horizontal && (
            <button type="button" onClick={onCancel} className="ml-auto">
              <CloseIcon width="20" height="20" stroke="#C1D6FF" />
            </button>
          )}
        </div>
      </div>

      <ModalMobile
        open={true}
        childHeight={noButtonsOnMobile ? 220 : 270}
        onClickAway={onCancel}
        wrapperClassName="md:hidden"
        className=""
      >
        <div className="w-full h-full grid grid-rows-note-layout">
          <div className="flex flex-col items-center pt-9 px-4">
            <img src={image} alt="warning" />
            <p className="text-lg md:text-base text-primary font-semibold mt-6">
              {title}
            </p>
            <p className="text-sm text-tertiary font-normal text-center mt-3">
              {description}
            </p>
          </div>

          {!noButtonsOnMobile && (
            <div className="flex justify-end gap-4 font-semibold text-sm bg-gray-background py-4 px-5">
              <Button
                className={`w-full md:w-fit ${
                  submitText && cancelText === defaultCancelText
                    ? "hidden"
                    : "sm:block"
                }`}
                variant="gray"
                onClick={onCancel}
              >
                {cancelText}
              </Button>
              {submitText && (
                <Button
                  className="w-full md:w-fit"
                  form="edit-organization"
                  onClick={onSubmit}
                >
                  {submitText}
                </Button>
              )}
            </div>
          )}
        </div>
      </ModalMobile>
    </>
  );
};

export default Warning;
