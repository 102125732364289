import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ArrowDownIcon } from "../../../assets/icons/arrow-down.svg";
import { ReactComponent as FilterIcon } from "../../../assets/icons/filter-new.svg";
import { setActiveFiltersAction } from "../../../store/conversation/actions";
import Checkbox from "../../Basic/Checkbox";

const optionStyles =
  "p-3 w-full text-left flex items-center justify-between space-x-2 rounded-lg hover:bg-indigo-50";

const FilterOther = ({
  openedDropdown,
  setOpenedDropdown,
  filterName = "other",
  arrow,
}) => {
  const { activeFilters, activeInbox, conversationsCounts } = useSelector(
    (state) => state.conversation,
  );
  const dispatch = useDispatch();

  const onApplyFilters = (filters) => {
    dispatch(setActiveFiltersAction(filters));
  };

  const isFilterApplied = () => {
    return activeFilters.other.unread || activeFilters.other.staffEngaging;
  };

  const onClickUnread = () => {
    onApplyFilters({
      ...activeFilters,
      other: {
        ...activeFilters.other,
        unread: !activeFilters.other.unread,
      },
    });
  };

  const onClickStaffEngaging = () => {
    onApplyFilters({
      ...activeFilters,
      other: {
        ...activeFilters.other,
        staffEngaging: !activeFilters.other.staffEngaging,
      },
    });
  };

  const getUnreadCount = () => {
    if (conversationsCounts?.[activeInbox]) {
      return conversationsCounts[activeInbox].UNREAD;
    }
    return null;
  };

  const getStaffEngagingCount = () => {
    if (conversationsCounts?.[activeInbox]) {
      return conversationsCounts[activeInbox].staffEngaging || 0;
    }
    return null;
  };

  return (
    <div
      onClick={() => {
        if (openedDropdown === filterName) {
          setOpenedDropdown("");
        } else {
          setOpenedDropdown(filterName);
        }
      }}
      className="flex items-center justify-center p-1.5 select-none cursor-pointer"
    >
      <div className="text-xs leading-none font-semibold flex items-center space-x-1.5">
        <div className="relative">
          <FilterIcon width="20" height="20" className="flex-none" />
          {isFilterApplied() && (
            <div className="absolute -top-0.5 -right-0.5 bg-primary-blue w-2.5 h-2.5 rounded-full" />
          )}
        </div>
        {arrow && (
          <ArrowDownIcon
            stroke={isFilterApplied() ? "#122C62" : "#121212"}
            className={`mt-0.5 ml-1 transition-all duration-300
              ${openedDropdown === filterName ? "-rotate-180" : ""}`}
            width="10"
            height="6"
          />
        )}
      </div>

      {openedDropdown === filterName && (
        <div
          className="absolute left-0 top-9 w-full bg-white rounded-2.5 shadow-md overflow-hidden
              leading-tight text-sm font-medium"
        >
          <div className="p-2 flex flex-col items-start space-y-1">
            <button
              type="button"
              onClick={onClickUnread}
              className={`${optionStyles}
                ${activeFilters.other.unread ? "text-primary-blue bg-indigo-50" : "text-zinc-700"}`}
            >
              <div className="flex items-center space-x-2">
                <Checkbox
                  checked={activeFilters.other.unread}
                  onClick={(e) => e.stopPropagation()}
                  variant="dark"
                />
                <p>Unread</p>
              </div>

              {getUnreadCount() !== null && (
                <div
                  className={`h-5 min-w-[28px] rounded-full text-white text-xs font-medium px-1.5
                    flex items-center justify-center
                    ${activeFilters.other.unread ? "bg-primary-blue" : "bg-slate-400"}`}
                >
                  {getUnreadCount()}
                </div>
              )}
            </button>
            <button
              type="button"
              onClick={onClickStaffEngaging}
              className={`${optionStyles}
                ${activeFilters.other.staffEngaging ? "text-primary-blue bg-indigo-50" : "text-zinc-700"}`}
            >
              <div className="flex items-center space-x-2">
                <Checkbox
                  checked={activeFilters.other.staffEngaging}
                  onClick={(e) => e.stopPropagation()}
                  variant="dark"
                />
                <p>Staff Engaging</p>
              </div>

              {getStaffEngagingCount() !== null && (
                <div
                  className={`h-5 min-w-[28px] rounded-full text-white text-xs font-medium px-1.5
                    flex items-center justify-center
                    ${activeFilters.other.staffEngaging ? "bg-primary-blue" : "bg-slate-400"}`}
                >
                  {getStaffEngagingCount()}
                </div>
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterOther;
