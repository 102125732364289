import Skeleton from "@mui/material/Skeleton";
import UserTable from "../Settings/Organization/UserTable";
import { ReactComponent as DotsIcon } from "../../assets/icons/ellipsis-horizontal.svg";

const TeamTableSkeleton = ({
  rows = 15,
  bgColor = "#EAEDF3",
  animation = "wave",
}) => {
  const columns = [
    { label: "Name", accessor: "name" },
    { label: "Clinical Lead", accessor: "lead" },
    { label: "Members", accessor: "users" },
    {
      label: "Number of patients",
      accessor: "number",
      className: "justify-center",
    },
    { label: "", accessor: "actions" },
  ];

  const getTableData = () => {
    const tableData = [];
    for (let i = 0; i < rows; i++) {
      tableData.push({
        name: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={110}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
        lead: (
          <div className="flex items-center space-x-2">
            <Skeleton
              animation={animation}
              variant="circular"
              width={32}
              height={32}
              sx={{ bgcolor: bgColor }}
            />
            <Skeleton
              animation={animation}
              variant="rounded"
              width={80}
              height={16}
              sx={{ bgcolor: bgColor, borderRadius: "16px" }}
            />
          </div>
        ),
        users: (
          <div className="flex -space-x-2.5">
            {[...Array(4)].map((_, index) => (
              <div
                key={`avatar-${index}`}
                className="bg-white rounded-full w-fit h-fit border-2 border-white relative"
              >
                <Skeleton
                  animation={animation}
                  variant="circular"
                  width={32}
                  height={32}
                  sx={{ bgcolor: bgColor }}
                />
              </div>
            ))}
          </div>
        ),
        number: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={70}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px", marginX: "auto" }}
          />
        ),
        actions: (
          <div className="flex items-center justify-center w-8 h-8 rounded-md">
            <DotsIcon width="20" height="20" />
          </div>
        ),
      });
    }
    return tableData;
  };

  return (
    <div className="grid w-full">
      <UserTable
        columns={columns}
        data={getTableData()}
        className="hidden md:block"
      />

      <div className="block md:hidden space-y-3">
        {[...Array(rows)].map((_, index) => (
          <div
            key={`team-${index}`}
            className="p-4 rounded-xl border-b border-gray-foreground space-y-4 bg-white"
          >
            <div className="flex items-center justify-between mb-1">
              <Skeleton
                animation={animation}
                variant="rounded"
                width={80}
                height={16}
                sx={{ bgcolor: bgColor, borderRadius: "16px" }}
              />
              <div className="flex items-center space-x-2">
                <Skeleton
                  animation={animation}
                  variant="rounded"
                  width={45}
                  height={23}
                  sx={{ bgcolor: bgColor, borderRadius: "23px" }}
                />
                <div className="flex items-center justify-center w-8 h-8 rounded-md">
                  <DotsIcon width="20" height="20" />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 divide-x border-gray-foreground">
              <div className="flex flex-col space-y-2 font-medium pr-3">
                <Skeleton
                  animation={animation}
                  variant="rounded"
                  width={52}
                  height={12}
                  sx={{ bgcolor: bgColor, borderRadius: "12px" }}
                />
                <div className="flex items-center space-x-2">
                  <Skeleton
                    animation={animation}
                    variant="circular"
                    width={32}
                    height={32}
                    sx={{ bgcolor: bgColor }}
                  />
                  <Skeleton
                    animation={animation}
                    variant="rounded"
                    width={80}
                    height={16}
                    sx={{ bgcolor: bgColor, borderRadius: "16px" }}
                  />
                </div>
              </div>
              <div className="flex flex-col space-y-2 font-medium truncate pl-3">
                <Skeleton
                  animation={animation}
                  variant="rounded"
                  width={52}
                  height={12}
                  sx={{ bgcolor: bgColor, borderRadius: "12px" }}
                />
                <div className="flex -space-x-2.5">
                  {[...Array(4)].map((_, index) => (
                    <div
                      key={`avatar-${index}`}
                      className="bg-white rounded-full w-fit h-fit border-2 border-white relative"
                    >
                      <Skeleton
                        animation={animation}
                        variant="circular"
                        width={32}
                        height={32}
                        sx={{ bgcolor: bgColor }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamTableSkeleton;
