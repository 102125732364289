import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setOpenEditInfoModalAction,
  setSelectedPatientInfoAction,
} from "../../../store/patient/actions";
import { getPatientInfo } from "../../../store/patient/thunks";
import {
  ethnicityOptions,
  raceOptions,
  sexualOrientationOptions,
} from "../../../helpers/constants";
import AddEditPatientModal from "../../Basic/AddEditPatientModal";
import CustomAccordion from "../../Basic/CustomAccordion";
import ProfilePicture from "../../Basic/ProfilePicture";
import Button from "../../Basic/Button";
import InsuranceBlock from "./InsuranceBlock";
import { ReactComponent as EditIcon } from "../../../assets/icons/pen.svg";

const containerClasses = "bg-white p-4 border rounded-lg";
const fieldTitleClasses = "text-sm text-tertiary";
const fieldValueClasses = "text-sm lg:text-base";

const InformationTab = ({ patientInfo }) => {
  const { user, users } = useSelector((state) => state.user);
  const { openEditInfoModal } = useSelector((state) => state.patient);
  const { facilities } = useSelector((state) => state.facility);
  const [patientProvider, setPatientProvider] = useState(null);
  const dispatch = useDispatch();

  const patientAddress = [
    patientInfo?.address_line_1,
    patientInfo?.address_line_2,
    patientInfo?.city,
    patientInfo?.state,
    patientInfo?.country,
    patientInfo?.zip_code,
  ]
    .filter(Boolean)
    .join(", ");

  useEffect(() => {
    return () => {
      dispatch(setOpenEditInfoModalAction(false));
    };
  }, [dispatch]);

  useEffect(() => {
    if (users?.length > 0 && patientInfo?.doctor_id) {
      setPatientProvider(
        users.find((doctor) => doctor?.doctor_id === patientInfo?.doctor_id),
      );
    }
  }, [patientInfo, users]);

  const getDateOfBirth = () => {
    return new Date(patientInfo.birthdate.replace(/-/g, "/"));
  };

  return (
    <>
      <div className="space-y-3 text-base pb-24 md:pb-0">
        <div className={containerClasses}>
          <div className="w-full flex items-center justify-between font-semibold mb-3">
            <p>Main</p>
            {!user.customer.has_ehr_data && (
              <Button
                variant="text-primary"
                size="unset"
                className="p-1.5 h-fit space-x-2"
                onClick={() => dispatch(setOpenEditInfoModalAction(true))}
              >
                <EditIcon width="16" height="16" />
                <p className="text-sm font-semibold">Edit</p>
              </Button>
            )}
          </div>
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-5 font-medium">
            <div className="col-span-2 lg:col-span-1">
              <p className={fieldTitleClasses}>Full Name</p>
              <p className={fieldValueClasses}>
                {patientInfo?.first_name || "-"} {patientInfo?.last_name || "-"}
              </p>
            </div>
            <div>
              <p className={fieldTitleClasses}>DOB</p>
              <p className={fieldValueClasses}>
                {patientInfo?.birthdate
                  ? `${getDateOfBirth().toLocaleDateString("en-us", {
                      day: "numeric",
                    })} ${getDateOfBirth().toLocaleDateString("en-us", {
                      month: "long",
                      year: "numeric",
                    })}`
                  : "-"}
              </p>
            </div>
            <div>
              <p className={fieldTitleClasses}>Sex</p>
              <p className={`${fieldValueClasses} capitalize`}>
                {patientInfo?.sex || "-"}
              </p>
            </div>
            <div>
              <p className={fieldTitleClasses}>Phone Number</p>
              <p className={fieldValueClasses}>
                {patientInfo?.phone_number || "-"}
              </p>
            </div>
            <div>
              <p className={fieldTitleClasses}>Primary Language</p>
              <p className={fieldValueClasses}>
                {patientInfo?.primary_language || "-"}
              </p>
            </div>
          </div>
          <div className="w-full border-t mt-5 pt-5">
            <p className={`${fieldTitleClasses} mb-2.5`}>Provider</p>
            {patientProvider ? (
              <div className="flex items-center space-x-3">
                <ProfilePicture
                  firstName={patientProvider.first_name}
                  lastName={patientProvider.last_name}
                  src={patientProvider.profile_picture}
                />
                <div>
                  <p className="text-sm font-bold">
                    {patientProvider.display_name}
                  </p>
                  <p className="text-xs font-medium text-tertiary">
                    {patientProvider.occupation}
                  </p>
                </div>
              </div>
            ) : (
              <p>-</p>
            )}
          </div>
        </div>

        <CustomAccordion title="Demographics" className={containerClasses}>
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-5 font-medium">
            <div className="col-span-2 lg:col-span-1">
              <p className={fieldTitleClasses}>Preferred First Name</p>
              <p className={fieldValueClasses}>
                {patientInfo?.preferred_name || "-"}
              </p>
            </div>
            <div>
              <p className={fieldTitleClasses}>Ethnicity</p>
              <p className={fieldValueClasses}>
                {patientInfo?.ethnicity
                  ? ethnicityOptions.find(
                      (option) => option.value === patientInfo.ethnicity,
                    )?.text || "-"
                  : "-"}
              </p>
            </div>
            <div>
              <p className={fieldTitleClasses}>Race</p>
              <p className={fieldValueClasses}>
                {patientInfo?.race
                  ? raceOptions.find(
                      (option) => option.value === patientInfo.race,
                    )?.text || "-"
                  : "-"}
              </p>
            </div>
            <div>
              <p className={fieldTitleClasses}>Gender</p>
              <p className={`${fieldValueClasses} capitalize`}>
                {patientInfo?.gender || "-"}
              </p>
            </div>
            <div>
              <p className={fieldTitleClasses}>Pronouns</p>
              <p className={fieldValueClasses}>
                {patientInfo?.pronouns || "-"}
              </p>
            </div>
            <div>
              <p className={fieldTitleClasses}>Sexual Orientation</p>
              <p className={fieldValueClasses}>
                {patientInfo?.sexual_orientation
                  ? sexualOrientationOptions.find(
                      (option) =>
                        option.value === patientInfo.sexual_orientation,
                    )?.text || "-"
                  : "-"}
              </p>
            </div>
            <div>
              <p className={fieldTitleClasses}>Social Security</p>
              <p className={fieldValueClasses}>
                {patientInfo?.social_security_number || "-"}
              </p>
            </div>
            <div className="lg:col-span-2">
              <p className={fieldTitleClasses}>Address</p>
              <p className={fieldValueClasses}>{patientAddress || "-"}</p>
            </div>
          </div>
        </CustomAccordion>

        <CustomAccordion title="Contact" className={containerClasses}>
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-5 font-medium">
            <div>
              <p className={fieldTitleClasses}>Email</p>
              <p className={fieldValueClasses}>{patientInfo?.email || "-"}</p>
            </div>
            <div className="lg:col-span-2">
              <p className={fieldTitleClasses}>Secondary Phone Number</p>
              <p className={fieldValueClasses}>
                {patientInfo?.secondary_phone_number || "-"}
              </p>
            </div>

            <div
              className="col-span-2 lg:col-span-3 w-full border-t border-gray-foreground text-sm uppercase
                tracking-wider pt-5 -mb-2"
            >
              Emergency Contact
            </div>
            <div>
              <p className={fieldTitleClasses}>Contact Name</p>
              <p className={fieldValueClasses}>
                {patientInfo?.emergency_contact?.length > 0
                  ? [
                      patientInfo.emergency_contact[0].emergency_first_name,
                      patientInfo.emergency_contact[0].emergency_last_name,
                    ]
                      .filter(Boolean)
                      .join(" ") || "-"
                  : "-"}
              </p>
            </div>
            <div>
              <p className={fieldTitleClasses}>Relationship</p>
              <p className={fieldValueClasses}>
                {patientInfo?.emergency_contact?.length > 0
                  ? patientInfo.emergency_contact[0].emergency_relationship ||
                    "-"
                  : "-"}
              </p>
            </div>
            <div>
              <p className={fieldTitleClasses}>Phone Number</p>
              <p className={fieldValueClasses}>
                {patientInfo?.emergency_contact?.length > 0
                  ? patientInfo.emergency_contact[0].emergency_phone_number ||
                    "-"
                  : "-"}
              </p>
            </div>
          </div>
        </CustomAccordion>

        <CustomAccordion title="Insurance" className={containerClasses}>
          <InsuranceBlock
            title="Primary Insurance"
            insurance={
              patientInfo?.insurance?.length > 0
                ? patientInfo.insurance[0]
                : null
            }
          />
          <InsuranceBlock
            title="Secondary Insurance"
            insurance={
              patientInfo?.insurance?.length > 1
                ? patientInfo.insurance[1]
                : null
            }
            className="border-t border-gray-foreground mt-5 pt-5"
          />
        </CustomAccordion>

        <CustomAccordion title="Care Locations" className={containerClasses}>
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-5 font-medium">
            <div>
              <p className={fieldTitleClasses}>Clinic Name</p>
              <p className={fieldValueClasses}>
                {patientInfo?.customer_id
                  ? facilities?.find(
                      (facility) =>
                        Number(facility.customer_id) ===
                        Number(patientInfo.customer_id),
                    )?.name || "-"
                  : "-"}
              </p>
            </div>
            <div className="lg:col-span-2">
              <p className={fieldTitleClasses}>Chart Number</p>
              <p className={fieldValueClasses}>
                {patientInfo?.chart_number || "-"}
              </p>
            </div>
            <div>
              <p className={fieldTitleClasses}>Pharmacy Name</p>
              <p className={fieldValueClasses}>
                {patientInfo?.pharmacy_name || "-"}
              </p>
            </div>
            <div>
              <p className={fieldTitleClasses}>Pharmacy Address</p>
              <p className={fieldValueClasses}>
                {patientInfo?.pharmacy_address || "-"}
              </p>
            </div>
            <div>
              <p className={fieldTitleClasses}>Pharmacy Phone</p>
              <p className={fieldValueClasses}>
                {patientInfo?.pharmacy_phone || "-"}
              </p>
            </div>
          </div>
        </CustomAccordion>
      </div>

      <AddEditPatientModal
        open={openEditInfoModal}
        patientInfo={patientInfo}
        onClose={() => dispatch(setOpenEditInfoModalAction(false))}
        onUpdate={() =>
          dispatch(
            getPatientInfo(patientInfo.patient_id, (patientInfo) => {
              dispatch(setSelectedPatientInfoAction(patientInfo));
            }),
          )
        }
      />
    </>
  );
};

export default InformationTab;
