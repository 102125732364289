import { useMediaQuery } from "react-responsive";
import Modal from "../../../Basic/Modal";
import ModalMobile from "../../../Basic/ModalMobile";
import AddTeamForm from "./AddTeamForm";

const AddTeamModal = ({ open, onClose, onNext }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return isDesktop ? (
    open && (
      <Modal className="pt-5 rounded-2xl">
        <div className="grow md:grow-0 sm:w-128 overflow-hidden flex flex-col">
          <p className="text-base md:text-xl font-semibold mb-5 px-5">
            Add New Team
          </p>
          <AddTeamForm onClose={onClose} onNext={onNext} />
        </div>
      </Modal>
    )
  ) : (
    <ModalMobile
      open={open}
      header="Add New Team"
      childHeight={350}
      onClickAway={onClose}
      className=""
    >
      <AddTeamForm onClose={onClose} onNext={onNext} className="pt-2" />
    </ModalMobile>
  );
};

export default AddTeamModal;
