import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "@mui/material/utils";
import {
  setSelectedOrganizationTabAction,
  setTeamSearchValueAction,
  setUserSearchValueAction,
} from "../../../store/user/actions";
import { getTeams, getUsers } from "../../../store/user/thunks";
import { OrganizationTabs } from "../../../helpers/constants";
import { selectInputText } from "../../../helpers/helpers";
import TabsBg from "../../Basic/TabsBg";
import Button from "../../Basic/Button";
import Users from "./Users/Users";
import Teams from "./Teams/Teams";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";

const OrganizationUsers = () => {
  const { USERS, TEAMS } = OrganizationTabs;
  const { selectedOrganizationTab, userSearchValue, teamSearchValue } =
    useSelector((state) => state.user);
  const [openUserModal, setOpenUserModal] = useState(false);
  const [openTeamModal, setOpenTeamModal] = useState(false);
  const dispatch = useDispatch();

  const handleSearchInput = (e) => {
    if (selectedOrganizationTab === USERS) {
      dispatch(setUserSearchValueAction(e.target.value));
    } else {
      dispatch(setTeamSearchValueAction(e.target.value));
    }
    debouncedSearch(e.target.value);
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((inputValue) => {
        if (selectedOrganizationTab === USERS) {
          dispatch(getUsers(inputValue));
        } else {
          dispatch(getTeams(inputValue));
        }
      }, 200),
    [selectedOrganizationTab],
  );

  return (
    <div className="h-full md:flex md:flex-col md:overflow-hidden">
      <div className="mb-4 flex flex-col md:flex-row w-full md:items-center justify-between gap-4 md:gap-8">
        <TabsBg
          tabs={Object.values(OrganizationTabs)}
          activeTab={selectedOrganizationTab}
          onChange={(tab) => dispatch(setSelectedOrganizationTabAction(tab))}
          className="w-full md:w-fit bg-white text-sm"
          tabClassName="py-2 px-8 text-center"
        />

        <div className="flex items-center space-x-3 w-full md:max-w-md">
          <div className="relative w-full">
            <input
              value={
                selectedOrganizationTab === USERS
                  ? userSearchValue
                  : teamSearchValue
              }
              onClick={selectInputText}
              onChange={handleSearchInput}
              className="rounded-lg h-10 text-sm outline-none w-full pl-11 placeholder:text-gray-400"
              placeholder={
                selectedOrganizationTab === USERS
                  ? "Search user..."
                  : "Search teams..."
              }
            />
            <SearchIcon
              className="absolute top-2.5 left-4"
              width="20"
              height="20"
            />
          </div>

          <Button
            variant="blue-light"
            onClick={() => {
              if (selectedOrganizationTab === USERS) {
                setOpenUserModal(true);
              }
              if (selectedOrganizationTab === TEAMS) {
                setOpenTeamModal(true);
              }
            }}
            className="font-semibold text-sm sm:text-base space-x-2 w-10 md:w-[131px] flex-none"
          >
            <PlusIcon
              width="20"
              height="20"
              stroke="#2970FF"
              className="flex-none"
            />
            <p className="hidden md:flex whitespace-nowrap">
              Add {selectedOrganizationTab === USERS ? "User" : "Team"}
            </p>
          </Button>
        </div>
      </div>

      {selectedOrganizationTab === USERS && (
        <Users openModal={openUserModal} setOpenModal={setOpenUserModal} />
      )}
      {selectedOrganizationTab === TEAMS && (
        <Teams openModal={openTeamModal} setOpenModal={setOpenTeamModal} />
      )}
    </div>
  );
};

export default OrganizationUsers;
