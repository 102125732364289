import { ReactComponent as IndependentScribeEmptyIcon } from "../../../assets/icons/independent-scribe-empty.svg";
import { ReactComponent as PlusSmallIcon } from "../../../assets/icons/plus-small.svg";

const ScribeListEmpty = ({
  setIsNewScribe,
}: {
  setIsNewScribe: () => void;
}) => {
  return (
    <div className="flex h-[calc(100svh-64px)] md:h-screen flex-col gap-4 items-center justify-center">
      <div className="flex flex-col items-center gap-5">
        <IndependentScribeEmptyIcon height="100" width="100" />
        <div className="flex flex-col items-center justify-center">
          <p className="text-xl font-semibold">No Encounter</p>
          <p className="text-sm font-medium text-tertiary">
            Start the encounter first
          </p>
        </div>
      </div>
      <button
        type="button"
        onClick={setIsNewScribe}
        className="bg-primary-blue md:hidden flex p-2 px-12 gap-3 text-white rounded-md font-semibold items-center"
      >
        <PlusSmallIcon width="12" height="12" stroke="#FFF" />
        New Encounter
      </button>
    </div>
  );
};

export default ScribeListEmpty;
