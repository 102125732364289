import { isEqual } from "lodash";

import { v4 as uuid } from "uuid";
import {
  filtersClear,
  inboxes,
  patientPanelTabs,
} from "../../helpers/constants";
import LocalStorageService from "../../services/LocalStorageService";
import {
  ADD_FAILED_MESSAGE,
  ADD_SENT_MESSAGE,
  COLLAPSE_SIDEBAR,
  EXPAND_PANEL,
  LOGOUT,
  REMOVE_SENT_MESSAGE,
  SELECT_CONVERSATION,
  SET_ACTIVE_FILTERS,
  SET_ACTIVE_INBOX,
  SET_CAMPAIGN_OPTIONS,
  SET_CLOSED_RESPONSES,
  SET_COLLAPSE_LIST,
  SET_CONVERSATION,
  SET_CONVERSATIONS,
  SET_CONVERSATIONS_COMPLETED,
  SET_CONVERSATIONS_COMPLETED_TEMP,
  SET_CONVERSATIONS_COUNTS,
  SET_CONVERSATIONS_REVIEW,
  SET_CONVERSATIONS_SCROLL_POSITION,
  SET_CONVERSATIONS_UPDATED,
  SET_CONV_CURR_PAGE,
  SET_ENCOUNTERS,
  SET_ENCOUNTER_ACTIVE_TAG,
  SET_ENCOUNTER_SEARCH_INPUT,
  SET_FILTERS_UPDATED,
  SET_INIT_FILTERS,
  SET_LOADER,
  SET_MESSAGES,
  SET_NEXT_CONVERSATION_ID,
  SET_NOTE_MARKUP,
  SET_NUM_CONV_BY_DOCTOR,
  SET_ON_MESSAGE_CONVERSATION_ID,
  SET_OPEN_PATIENT_DETAILS,
  SET_SEARCH_INPUT,
  SET_SELECTED_CONVERSATION_TAB,
  SET_SELECTED_DOCTOR_INBOX,
} from "../types";

const storageService = new LocalStorageService();

const init = {
  panelExpanded: false,
  sidebarCollapsed: false,
  currentConversationId: storageService.getItem("currentConversationId") ?? "",
  nextConversationId: "",
  currentConversation: storageService.getItem("currentConversation") ?? null,
  conversationsReview: null,
  conversations: null,
  conversationsCompleted: null,
  activeInbox: storageService.getItem("inboxTab") ?? inboxes.REVIEW,
  conversationsCompletedTemp: [],
  convCurrPage: 1,
  loader: true,
  collapsePatientList: storageService.getItem("collapsePatientList") ?? false,
  activeFilters: storageService.getItem("inboxFilters") ?? filtersClear,
  initFilters: null,
  campaignFilterOptions: [],
  conversationSearchInput:
    storageService.getItem("conversationSearchInput") ?? "",
  conversationsScrollPosition: 0,
  sentMessages: {},
  failedMessages: storageService.getItem("failedMessages") ?? {},
  closedResponses: storageService.getItem("closedResponses") ?? [],
  noteHtmlMarkups: {},
  encounters: [],
  encounterSearchInput: "",
  encounterActiveTag: "All",
  conversationsUpdated: true,
  onMessageConversationId: null,
  conversationsCounts: null,
  filtersUpdated: true,
  openPatientDetails: false,
  selectedDoctorInbox: storageService.getItem("selectedDoctorInbox") ?? null,
  numOfConvByDoctorId: {},
  selectedConversationTab:
    storageService.getItem("selectedConversationTab") ??
    patientPanelTabs.MESSAGE,
  messages: {},
};

export default function conversationReducer(state = init, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGOUT:
      storageService.removeItem("failedMessages");
      storageService.removeItem("closedResponses");
      storageService.removeItem("collapsePatientList");
      storageService.removeItem("inboxFilters");
      storageService.removeItem("conversationSearchInput");
      storageService.removeItem("currentConversation");
      storageService.removeItem("currentConversationId");
      storageService.removeItem("inboxTab");
      storageService.removeItem("insightPanelTab");
      storageService.removeItem("selectedDoctorInbox");
      storageService.removeItem("selectedConversationTab");
      return init;
    case SET_CONVERSATIONS_REVIEW:
      return { ...state, conversationsReview: payload.conversationsReview };
    case SET_CONVERSATIONS:
      return { ...state, conversations: payload.conversations };
    case SET_CONVERSATIONS_COMPLETED:
      return {
        ...state,
        conversationsCompleted: payload.conversationsCompleted,
      };
    case SET_CONVERSATIONS_SCROLL_POSITION:
      return {
        ...state,
        conversationsScrollPosition: payload.conversationsScrollPosition,
      };
    case SET_ACTIVE_INBOX:
      storageService.setItem("inboxTab", payload.activeInbox);
      return { ...state, activeInbox: payload.activeInbox };
    case SET_CONV_CURR_PAGE:
      return { ...state, convCurrPage: payload.convCurrPage };
    case SET_CONVERSATIONS_COMPLETED_TEMP:
      return {
        ...state,
        conversationsCompletedTemp: payload.conversationsCompletedTemp,
      };
    case SET_CONVERSATION:
      if (isEqual(state.currentConversation, payload.conversation)) {
        return state;
      }

      storageService.setItem("currentConversation", payload.conversation);
      return { ...state, currentConversation: payload.conversation };
    case SET_ENCOUNTERS:
      return { ...state, encounters: payload.encounters };
    case SELECT_CONVERSATION:
      storageService.setItem("currentConversationId", payload.conversationId);
      return { ...state, currentConversationId: payload.conversationId };
    case SET_NEXT_CONVERSATION_ID:
      return { ...state, nextConversationId: payload.conversationId };
    case EXPAND_PANEL:
      return { ...state, panelExpanded: payload.state };
    case COLLAPSE_SIDEBAR:
      return { ...state, sidebarCollapsed: payload.state };
    case SET_LOADER:
      return { ...state, loader: payload.loader };
    case SET_COLLAPSE_LIST:
      storageService.setItem("collapsePatientList", !state.collapsePatientList);
      return { ...state, collapsePatientList: payload.state };
    case SET_ACTIVE_FILTERS:
      if (isEqual(state.activeFilters, payload.activeFilters)) {
        return state;
      }

      storageService.setItem("inboxFilters", payload.activeFilters);
      return { ...state, activeFilters: payload.activeFilters };
    case SET_INIT_FILTERS:
      if (isEqual(state.initFilters, payload.initFilters)) {
        return state;
      }

      return { ...state, initFilters: payload.initFilters };
    case SET_CAMPAIGN_OPTIONS:
      if (isEqual(state.campaignFilterOptions, payload.campaignFilterOptions)) {
        return state;
      }

      return { ...state, campaignFilterOptions: payload.campaignFilterOptions };
    case SET_ENCOUNTER_ACTIVE_TAG:
      return { ...state, encounterActiveTag: payload.encounterActiveTag };
    case SET_SEARCH_INPUT:
      storageService.setItem(
        "conversationSearchInput",
        payload.conversationSearchInput,
      );
      return {
        ...state,
        conversationSearchInput: payload.conversationSearchInput,
      };
    case SET_ENCOUNTER_SEARCH_INPUT:
      return {
        ...state,
        encounterSearchInput: payload.encounterSearchInput,
      };
    case SET_NOTE_MARKUP:
      return {
        ...state,
        noteHtmlMarkups: {
          ...state.noteHtmlMarkups,
          [payload.conversationId]: payload.noteMarkup,
        },
      };
    case ADD_SENT_MESSAGE:
      return addSentMessage(state, payload);
    case REMOVE_SENT_MESSAGE:
      return removeSentMessage(state, payload);
    case ADD_FAILED_MESSAGE:
      return addFailedMessage(state, payload);
    case SET_CLOSED_RESPONSES:
      return setClosedResponses(state, payload);
    case SET_CONVERSATIONS_UPDATED:
      return { ...state, conversationsUpdated: payload.conversationsUpdated };
    case SET_ON_MESSAGE_CONVERSATION_ID:
      return {
        ...state,
        onMessageConversationId: payload.onMessageConversationId,
      };
    case SET_CONVERSATIONS_COUNTS:
      return {
        ...state,
        conversationsCounts: {
          ...state.conversationsCounts,
          [payload.inbox]: payload.conversationsCounts,
        },
      };
    case SET_FILTERS_UPDATED:
      return { ...state, filtersUpdated: payload.filtersUpdated };
    case SET_OPEN_PATIENT_DETAILS:
      return { ...state, openPatientDetails: payload.openPatientDetails };
    case SET_SELECTED_DOCTOR_INBOX:
      storageService.setItem(
        "selectedDoctorInbox",
        payload.selectedDoctorInbox,
      );
      return { ...state, selectedDoctorInbox: payload.selectedDoctorInbox };
    case SET_NUM_CONV_BY_DOCTOR:
      return {
        ...state,
        numOfConvByDoctorId: {
          ...state.numOfConvByDoctorId,
          ...payload.numOfConvByDoctorId,
        },
      };
    case SET_SELECTED_CONVERSATION_TAB:
      storageService.setItem(
        "selectedConversationTab",
        payload.selectedConversationTab,
      );
      return {
        ...state,
        selectedConversationTab: payload.selectedConversationTab,
      };
    case SET_MESSAGES:
      return {
        ...state,
        messages: payload.messages,
      };
    default:
      return state;
  }
}

const addSentMessage = (state, payload) => {
  return state.sentMessages[payload.conversationId]
    ? {
        ...state,
        sentMessages: {
          ...state.sentMessages,
          [payload.conversationId]: [
            ...state.sentMessages[payload.conversationId],
            payload.message,
          ],
        },
      }
    : {
        ...state,
        sentMessages: {
          ...state.sentMessages,
          [payload.conversationId]: [payload.message],
        },
      };
};

const removeSentMessage = (state, payload) => {
  return state.sentMessages[payload.conversationId] &&
    state.sentMessages[payload.conversationId].length > 0
    ? {
        ...state,
        sentMessages: {
          ...state.sentMessages,
          [payload.conversationId]:
            state.sentMessages[payload.conversationId].slice(1),
        },
      }
    : state;
};

const addFailedMessage = (state, payload) => {
  const newState = state.failedMessages[payload.conversationId]
    ? {
        ...state,
        failedMessages: {
          ...state.failedMessages,
          [payload.conversationId]: [
            ...state.failedMessages[payload.conversationId],
            { id: uuid(), message: payload.message },
          ],
        },
      }
    : {
        ...state,
        failedMessages: {
          ...state.failedMessages,
          [payload.conversationId]: [{ id: uuid(), message: payload.message }],
        },
      };
  storageService.setItem("failedMessages", newState.failedMessages);
  return newState;
};

const setClosedResponses = (state, payload) => {
  const newState = {
    ...state,
    closedResponses: [...state.closedResponses, payload.conversationId],
  };
  storageService.setItem("closedResponses", newState.closedResponses);
  return newState;
};
