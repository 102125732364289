import Skeleton from "@mui/material/Skeleton";

const InsightPanelSkeleton = ({ bgColor = "#EAEDF3", animation = "wave" }) => {
  return (
    <div className="px-5 space-y-5">
      <div className="space-y-4">
        <Skeleton
          animation={animation}
          variant="rounded"
          width={180}
          height={16}
          sx={{ bgcolor: bgColor, borderRadius: "16px" }}
        />
        <Skeleton
          animation={animation}
          variant="rounded"
          height={188}
          sx={{ bgcolor: bgColor, borderRadius: "8px" }}
        />
      </div>
      <div className="space-y-4">
        <Skeleton
          animation={animation}
          variant="rounded"
          width={180}
          height={16}
          sx={{ bgcolor: bgColor, borderRadius: "16px" }}
        />
        <Skeleton
          animation={animation}
          variant="rounded"
          height={188}
          sx={{ bgcolor: bgColor, borderRadius: "8px" }}
        />
      </div>
    </div>
  );
};

export default InsightPanelSkeleton;
